import React from "react";

export default function IconFlagEn() {
  return (
    <svg
      width="32.000000"
      height="32.000000"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="clip2002_22">
          <rect
            id="openmoji:flag-australia"
            width="32.000000"
            height="32.000000"
            fill="white"
            fillOpacity="0"
          />
        </clipPath>
      </defs>
      <rect
        id="openmoji:flag-australia"
        width="32.000000"
        height="32.000000"
        fill="#FFFFFF"
        fillOpacity="0"
      />
      <g clipPath="url(#clip2002_22)">
        <path
          id="Vector"
          d="M2.22 7.55L29.77 7.55L29.77 24.44L2.22 24.44L2.22 7.55Z"
          fill="#1E50A0"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M5.62 8L2 8L2 10.07L26.37 24L30 24L30 21.92L5.62 8Z"
          fill="#FFFFFF"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M30 10.07L30 8L26.37 8L2 21.92L2 24L5.62 24L30 10.07Z"
          fill="#FFFFFF"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M2 13L30 13L30 18.5L2 18.5L2 13Z"
          fill="#FFFFFF"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M12 8L20 8L20 24L12 24L12 8Z"
          fill="#FFFFFF"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M14 8L18 8L18 24L14 24L14 8Z"
          fill="#D22F27"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M2 15.11L30 15.11L30 16.88L2 16.88L2 15.11ZM30 21.92L24.29 18.66L20.66 18.66L30 24L30 21.92ZM30 8L26.37 8L18.79 12.32L18.79 13.33L20.66 13.33L30 8ZM2 10.07L7.7 13.33L11.33 13.33L2 8L2 10.07ZM2 24L5.62 24L13.2 19.67L13.2 18.66L11.33 18.66L2 24Z"
          fill="#D22F27"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M29.77 7.55L29.77 24.44L2.22 24.44L2.22 7.55L29.77 7.55Z"
          stroke="#000000"
          strokeOpacity="1.000000"
          strokeWidth="0.666667"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

import React from "react";

export default function IconTg() {
  return (
    <svg
      width="16.679688"
      height="13.988708"
      viewBox="0 0 16.6797 13.9887"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <path
        id="Vector"
        d="M15.55 0.09L0.77 5.78C-0.24 6.19 -0.23 6.75 0.59 7L4.38 8.19L13.16 2.65C13.57 2.4 13.95 2.53 13.64 2.81L6.53 9.23L6.53 9.23L6.53 9.23L6.27 13.14C6.65 13.14 6.82 12.96 7.04 12.75L8.88 10.96L12.71 13.79C13.42 14.18 13.92 13.98 14.1 13.14L16.62 1.28C16.87 0.25 16.22 -0.22 15.55 0.09Z"
        fill="#6287A2"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
    </svg>
  );
}

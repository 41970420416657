import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import "./game.scss";
import { useDispatch, useSelector } from "react-redux";
import LoginModal from "../../modal/login_modal/LoginModal";
import { ENUM_BOT_THEME } from "../../../store/enums";
import ErrorGameModal from "../../modal/error_game_modal/ErrorGameModal";
import ShowContainer from "../../mui/ShowContainer";
import useRunGame from "../../../hooks/useRunGame";
import ConfirmCloseModal from "../../modal/error_game_modal/ConfirmCloseModal";
import { setRunningGame } from "../../../store/siteSlice";

export default function GameContainer({ uid = "", gameId = "", currentGame, isBonus }) {
  const theme = useSelector((state) => state.common.botTheme);
  const dispatch = useDispatch();
  const [openLogin, setOpenLogin] = useState(false);
  const [isOpenConfirmClose, setIsOpenConfirmClose] = useState(false);
  const [errorMes, setErrorMes] = useState("");
  const [justCame, setJustCame] = useState(false);

  const { onCloseGame, hideCross, iframeSrc } = useRunGame({
    setOpenLogin,
    setJustCame,
    setErrorMes,
    currentGame,
    uid,
    isBonus,
    gameId,
    justCame
  });

  useEffect(() => {
    dispatch(setRunningGame(true));
    return () => {
      dispatch(setRunningGame(false));
    };
  }, []);

  const closeLogin = () => {
    if (theme === ENUM_BOT_THEME.telegram) return;
    if (!justCame) onCloseGame();
    setOpenLogin(false);
  };

  const closeError = () => {
    onCloseGame();
    setErrorMes("");
  };

  return (
    <div className="game">
      <ShowContainer condition={iframeSrc}>
        <Iframe
          display="block"
          className="game-iframe"
          scrolling="no"
          allow="fullscreen; geolocation; microphone; camera; display-capture;"
          allowFullScreen={true}
          src={iframeSrc}
        />

        <button className="game__close-btn" onClick={() => setIsOpenConfirmClose(true)}>
          x
        </button>
      </ShowContainer>
      <LoginModal
        isOpen={openLogin}
        closeModal={(e) => {
          if (e === true) {
            console.log("aut");
            setJustCame(true);
            setOpenLogin(false);
            return;
          }
          closeLogin();
        }}
        hideCross={hideCross}
      />
      <ErrorGameModal
        isOpen={!!errorMes}
        message={errorMes}
        closeModal={closeError}
        currentGame={currentGame}
      />
      <ConfirmCloseModal
        isOpen={isOpenConfirmClose}
        closeModal={() => setIsOpenConfirmClose(false)}
        onConfirm={onCloseGame}
      />
    </div>
  );
}

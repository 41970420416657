import React from "react";

export default function IconStarSimpleThin() {
  return (
    <svg
      className="icon-start"
      width="12.564453"
      height="12.564423"
      viewBox="0 0 12.5645 12.5644"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <path
        id="star/simple/thinsmooth"
        d="M7.94 0C7.16 3.45 9.17 6.92 12.56 7.95C9.11 7.16 5.64 9.17 4.61 12.56C5.39 9.11 3.38 5.64 0 4.61C3.44 5.39 6.91 3.38 7.94 0Z"
        fill="#FCFEFF"
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
    </svg>
  );
}

import React, { useEffect, useState } from "react";
import imgCardCards from "./type_image/cards.png";
import imgCarRub from "./type_image/rub.png";
import imgCardSber from "./type_image/sberPay.png";
import imgCardSbp from "./type_image/sbp.png";
import imgCardUsdt from "./type_image/usdt.png";
import imgCardHumo from "./type_image/humo.png";
import imgCardUzcard from "./type_image/uzcard.png";
import "./top-up-cards.scss";
import { API_USER } from "../../../../api/user";
import { errorToast } from "../../../mui/Toaster";
import { DEBUG } from "../../../../store/enums";
import { messageByCode } from "../../../../utils/utils";
import { useTranslation } from "react-i18next";
import LoaderContainer from "../../../mui/loader_container/LoaderContainer";
import ModalPaymentPhone from "../payment_details/ModalPaymentPhone";
import ModalPaymentCard from "../payment_details/ModalPaymentCard";

export default function TopUpCards({ amount, currency, closeModal }) {
  const { t } = useTranslation();
  const [card, setCard] = useState([]);
  const [loader, setLoader] = useState(false);
  const [openPaymentPhone, setOpenPaymentPhone] = useState(false);
  const [openPaymentCard, setOpenPaymentCard] = useState(false);

  /**
   * @description - получение списка методов
   */
  useEffect(() => {
    setLoader(true);
    API_USER.balance
      .method({ amount, currency })
      .then((e) => {
        if (DEBUG) console.log(e);
        const cards = e.data.list?.map((el) => cardsById[el] || undefined);
        if (e?.dataError) return errorToast(messageByCode(e.dataError));
        setCard(cards || []);
      })
      .catch(console.error)
      .finally(() => {
        setLoader(false);
      });
  }, [amount, currency]);

  /**
   * @description - выбор нужного метода
   */
  const onCardClick = (method) => {
    API_USER.balance
      .replenish({ amount, currency, method })
      .then((e) => {
        if (DEBUG) console.log("make deposit res", e);
        const data = e.data;

        if (data?.dataError) return errorToast(messageByCode(data.dataError));
        if (data?.error) return console.error(data.error);
        // if (data?.paymentUrl) setUrl(data.paymentUrl);
        if (data?.paymentUrl) {
          window.open(data.paymentUrl, "_blank");
          closeModal();
        }

        if (data.phonePayment) setOpenPaymentPhone({ ...data.phonePayment, sum: amount, currency });
        if (data.cardPayment) setOpenPaymentCard({ ...data.cardPayment, sum: amount, currency });

        // closeModal();
      })
      .catch((e) => {
        if (DEBUG) console.log("make deposit cath", e);
      });
  };

  const cardsById = {
    card: { id: "card", img: imgCardCards, title: "Transfer to bank card" },
    sber: { id: "sber", img: imgCardSber, title: "To Sberbank account" },
    sbp: { id: "sbp", img: imgCardSbp, title: "SBP by phone number" },
    rub: { id: "rub", img: imgCarRub, title: "Transfer to account" },
    humo: { id: "humo", img: imgCardHumo, title: "Humo" },
    uzcard: { id: "uzcard", img: imgCardUzcard, title: "Uzcard" },
    usdt: { id: "usdt", img: imgCardUsdt, title: "USDT" }
  };
  return (
    <LoaderContainer loader={loader} center>
      {card?.length ? (
        <div className="top-up-balance__cards">
          {card.map((card, indx) => (
            <div key={indx} className="card" onClick={() => onCardClick(card.id)}>
              <img src={card.img} />
              <span>{t(card.title)}</span>
            </div>
          ))}
        </div>
      ) : (
        <div className="top-up-balance__cards__empty">
          <h3>{t("Тo methods available")}</h3>
        </div>
      )}
      {/* <ModalFrameUrl
        isOpen={!!url}
        url={url}
        closeModal={() => {
          setUrl("");
          closeModal();
        }}
      /> */}
      <ModalPaymentPhone
        isOpen={!!openPaymentPhone}
        closeModal={() => {
          setOpenPaymentPhone(false);
          closeModal();
        }}
        data={openPaymentPhone}
      />

      <ModalPaymentCard
        isOpen={!!openPaymentCard}
        data={openPaymentCard}
        closeModal={() => {
          setOpenPaymentCard(false);
          closeModal();
        }}
      />
    </LoaderContainer>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import GameItem from "../mui/game_tem/GameItem";

export default function Providers() {
  const providers = useSelector((state) => state.games.providers);

  return (
    <div className="games__games">
      {providers?.map((el, indx) => (
        <GameItem
          isChoose={false}
          className="provider"
          url="/games/provider/"
          urlParam={{ to: window.location.pathname }}
          currentGame={{ id: el.name }}
          key={el.game_id || indx}
          image={el.image}
          imageHover={el.imageHover}
        />
      ))}
    </div>
  );
}

import React from "react";

export default function IconBonus() {
  return (
    <svg
      width="16.666016"
      height="16.666260"
      viewBox="0 0 16.666 16.6663"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <path
        id="Vector"
        d="M7.7 0C4.19 0 2.37 0.06 1.22 1.22C0.06 2.37 0 4.19 0 7.7L4.09 7.7C3.8 7.34 3.58 6.91 3.46 6.43C3.01 4.64 4.64 3.01 6.43 3.46C6.91 3.58 7.34 3.8 7.7 4.09L7.7 0ZM15.44 1.22C16.59 2.37 16.66 4.19 16.66 7.7L12.57 7.7C12.86 7.34 13.08 6.91 13.2 6.43C13.65 4.64 12.02 3.01 10.23 3.46C9.75 3.58 9.32 3.8 8.95 4.09L8.95 0C12.47 0 14.29 0.06 15.44 1.22ZM6.13 4.67C7.05 4.9 7.7 5.74 7.7 6.69L7.7 7.7L6.69 7.7C5.74 7.7 4.9 7.05 4.67 6.13C4.45 5.25 5.25 4.45 6.13 4.67ZM8.95 7.7L8.95 6.69C8.95 5.74 9.6 4.9 10.53 4.67C11.41 4.45 12.2 5.25 11.98 6.13C11.75 7.05 10.92 7.7 9.97 7.7L8.95 7.7ZM1.22 15.44C0.06 14.29 0 12.47 0 8.95L6.8 8.95C6.51 10.15 5.44 11.04 4.16 11.04C3.82 11.04 3.54 11.32 3.54 11.66C3.54 12.01 3.82 12.29 4.16 12.29C5.71 12.29 7.05 11.4 7.7 10.1L7.7 16.66C4.19 16.66 2.37 16.59 1.22 15.44ZM8.95 16.66C12.47 16.66 14.29 16.59 15.44 15.44C16.59 14.29 16.66 12.47 16.66 8.95L9.86 8.95C10.14 10.15 11.21 11.04 12.5 11.04C12.84 11.04 13.12 11.32 13.12 11.66C13.12 12.01 12.84 12.29 12.5 12.29C10.94 12.29 9.6 11.4 8.95 10.1L8.95 16.66Z"
        fill="#6287A2"
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
    </svg>
  );
}

import React from "react";
import Logo from "../header/logo/Logo";
import LinksFooter from "./LinksFooter";
import ChoseLang from "../../mui/chose_lang/ChoseLang";
import ButtonUp from "../../mui/button_up/ButtonUp";
import TgLinksFooter from "./TgLinksFooter";
import CopyrightFooter from "./CopyrightFooter";
import { useTranslation } from "react-i18next";

export default function MobileFooter() {
  const { t } = useTranslation();

  return (
    <footer className="footer mobile">
      <div className="footer__content">
        <div className="footer__content__one">
          <Logo />
          <div className="footer__content__controls">
            <ChoseLang />
            <ButtonUp />
          </div>
        </div>

        <div className="footer__content__one">
          <div className="footer__content__contents">
            <span className="footer__content__contents__title">{t("Contents")}</span>
            <LinksFooter />
          </div>
        </div>

        {/* <FooterInside /> */}

        <div className="footer__content__three">
          {/* <SocialIcons /> */}
          <TgLinksFooter />
          <CopyrightFooter />
        </div>
      </div>
    </footer>
  );
}

import React from "react";
import { ReactComponent as IconCheck } from "./Check.svg";
import "./check-info.scss";

export default function CheckInfo({ className, children }) {
  return (
    <div className={`check-info ${className}`}>
      <IconCheck />
      {children}
    </div>
  );
}

import React from "react";
import ShowContainer from "../ShowContainer";
import { useTranslation } from "react-i18next";
import "./withdraw-info-p.scss";

export default function WithdrawInfoP({ condition, text, accent }) {
  const { t } = useTranslation();
  return (
    <ShowContainer condition={condition}>
      <p className="withdraw__into-p">
        {t(text)} <span>{t(accent)}</span>
      </p>
    </ShowContainer>
  );
}

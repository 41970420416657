import React from "react";
import GameItem from "../mui/game_tem/GameItem";
import { useSelector } from "react-redux";

export default function HitGames() {
  const games = useSelector((state) => state.games.hitGames);

  return (
    <div className="games__games">
      {games.map((el) => (
        <GameItem currentGame={el} key={el.game_id} name={el.name} image={el.image} />
      ))}
    </div>
  );
}

import React from "react";
import { useSelector } from "react-redux";

export default function TermsRu() {
  const siteName = useSelector((state) => state.common.casinoName);

  return (
    <div className="page-content">
      <div className="wrapper">
        <div className="page-content-terms-of-use">
          <h1>Пользовательское соглашение</h1>
          <h3>1. Введение</h3>
          <p>
            1.1. Посещая какой-либо из разделов казино {siteName} («Бот», «Сайт», «Приложение»),
            пользователь автоматически соглашается со всеми пунктами Пользовательского соглашения
            («Соглашение»), Политикой конфиденциальности, условиями рекламной деятельности,
            правилами игр, бонусами и специальными предложениями, которые время от времени
            появляются в {siteName}. Если игрок не хочет или не может согласиться с данными
            условиями и соблюдать их, то он не должен пользоваться услугами {siteName}.
            Использование бота, сайта или игрового приложения {siteName} будет расцениваться как
            полное принятие игроком всех пунктов данного Соглашения.
          </p>
          <p>&nbsp;</p>
          <h2>Общие положения и условия</h2>
          <h3>2. Стороны</h3>
          <p>
            2.1. Все пункты Соглашения, включающие местоимения «мы», «нас», «наш» или «{siteName}», «компания», «бот», «сайт», относятся к компании TCS Casino Games LTD. | Registration no. J23/4554/2019 | Greentube Malta Limited, W Business Centre, Level 4, Dun Karm Street, Birkirkara BKR 9033, Malta | License # L1203425W001180 (01.03.2020- 28.02.2030) | D. authorization: 306/28.02.2023 (01.03.2023-29.02.2024), с которой игрок заключает договор, в соответствии с приведенным выше пунктом.
          </p>
          <p>&nbsp;</p>
          <h3>3. Изменение условий</h3>
          <p>
            3.1. У нас есть право вносить любые изменения, редактировать, обновлять и изменять
            Соглашение по ряду причин: коммерческим, юридическим, а также по причинам, связанным с
            обслуживанием клиентов. Актуальные пункты Соглашения и даты их вступления в силу
            присутствуют в боте, сайте, приложениях. Мы информируем игроков обо всех изменениях,
            поправках и дополнениях путем размещения текста измененного Соглашения в боте, сайте,
            приложениях (далее - сервис {siteName}). Игрок несет личную ответственность за
            ознакомление с действующим Соглашением. У Компании есть право вносить изменения в
            Соглашение и свои сервисы {siteName} без предварительного информирования игроков.
          </p>
          <p>&nbsp;</p>
          <p>
            3.2. В случае несогласия с измененными пунктами Соглашения игрок вправе прекратить
            использование сервисов {siteName}. Дальнейшее использование сервиса {siteName} после
            вступления в силу измененных пунктов Соглашения, будет расцениваться как полное его
            принятие, независимо от того, получил ли игрок соответствующее уведомление или узнал про
            изменения из обновленного Соглашения.
          </p>
          <p>&nbsp;</p>
          <h3>4. Правовые требования</h3>
          <p>
            4.1. Лицам, не достигшим 18 летнего возраста или возраста («Допустимый возраст»),
            который является обязательным для законного участия в азартных играх по законодательству
            конкретной юрисдикции, запрещено пользоваться сервисом {siteName}. Использование сервиса
            {siteName} лицами, не достигшими Допустимого возраста, является прямым нарушением
            Соглашения. В связи с этим, у нас есть право запрашивать документы, которые могут
            подтвердить возраст игрока. Игроку может быть отказано в предоставлении услуг, и
            действие его аккаунта может быть временно приостановлено, если по нашему требованию не
            будут предъявлены доказательства того, что возраст игрока соответствует Допустимому.
          </p>
          <p>&nbsp;</p>
          <p>
            4.2. Азартные игры онлайн запрещены законом в некоторых юрисдикциях. Принимая
            Соглашение, игрок осознает, что Компания не может предоставить гарантии или юридическую
            консультацию относительно легитимности использования сервиса {siteName} на территории
            юрисдикции, на которой находится игрок. Мы не можем утверждать, что услуги компании не
            нарушают законов юрисдикции игрока. Игрок пользуется услугами компании по собственному
            желанию и берет на себя всю полноту ответственности, хорошо осознавая все возможные
            риски.
          </p>
          <p>&nbsp;</p>
          <p>
            4.3. Мы не стремимся и не намереваемся предоставить игроку услуги, нарушающие
            законодательство его юрисдикции. Принимая Соглашение, игрок подтверждает и гарантирует,
            что использование сервиса {siteName} соответствует законам и правилам, действующим на
            территории его юрисдикции.
          </p>
          <p>&nbsp;</p>
          <p>
            4.4. На игроков возлагается вся полнота ответственности по уплате налогов и сборов,
            которые применяются по отношению к любым выигрышам, полученным в результате
            использования сервиса {siteName}.
          </p>
          <p>&nbsp;</p>
          <p>
            4.5. По юридическим причинам мы не принимаем игроков из следующих стран: Иран, Северная
            Корея, Судан, Сирия, Китай, Ирак, Пакистан, Йемен, Туркменистан, Гана, Парагвай,
            Маврикий, Мьянма, Эфиопия, Непал, Перу, Бенин, Коста-Рика, Эквадор, Гаити, Мадагаскар,
            Идти, Уругвай, Аландские острова, Бутан, Ботсвана, Камбоджа, Камерун, Экваториальная
            Гвинея, Кувейт, Мали, Замбия, Лихтенштейн, американское Самоа, Андорра, Ангилья,
            Антарктида, Бермуды, Бонайре, Синт-Эстатиус и Саба, Остров Буве, Британская территория в
            Индийском океане, Буркина-Фасо, Бурунди, Кабо-Верде, Центральноафриканская Республика,
            Чад, Остров Рождества, Кокосовые острова (Килинг), Коморы, Острова Кука, Берег Слоновой
            Кости, Кюрасао, Джибути, Доминика, Сальвадор, Эритрея, Эсватини, Фолклендские острова,
            Фарерские острова, Фиджи, Французская Гвиана, Французская Полинезия, Французские южные
            территории, Габон, Гамбия, Гибралтар, Гренада, Гваделупа, Гуам, Гватемала, Гернси,
            Гвинея-Бисау, Остров Херд и острова Макдональдс, Святой Престол, Гондурас, Иран, Остров
            Мэн, Ямайка, Джерси, Кирибати, Лаосская Народно-Демократическая Республика, Лесото,
            Либерия, Малави, Маршалловы Острова, Мартиника, Мавритания, Майотта, Микронезия,
            Монтсеррат, Мозамбик, Науру, Новая Каледония, Нигер, Ниуэ, Остров Норфолк, Северные
            Марианские острова, Палау, Палестина, Государство, Папуа - Новая Гвинея, Питкэрн,
            Реюньон, Руанда, Сен-Бартельми, Остров Святой Елены, Вознесение и Тристан-да-Кунья,
            Сент-Китс и Невис, Санкт-Люсия, Сен-Мартен (французская часть), Сен-Пьер и Микелон,
            Святой Винсент и Гренадины, Самоа, Сан-Марино, Сан-Томе и Принсипи, Сенегал,
            Сьерра-Леоне, Синт-Мартен (голландская часть), Соломоновы острова, Южная Георгия и Южные
            Сандвичевы острова, южный Судан, Судан, Суринам, Шпицберген и Ян-Майен, Сирийская
            Арабская Республика, Танзания, Объединенная Республика, Тимор-Лешти, Токелау, Тонга,
            Теркс и Кайкос (о-ва), Тувалу, Вануату, Уоллис и Футуна, Зимбабве, Каймановы острова. Вы
            соглашаетесь, что не станете открывать аккаунт и использовать сервисы {siteName} или
            перечислять на него денежные средства, если являетесь резидентом одной из перечисленных
            выше юрисдикций.
          </p>
          <p>&nbsp;</p>
          <h3>5. Открытие аккаунта</h3>
          <p>
            5.1. Чтобы получить доступ ко всем основным услугам {siteName}, необходимо открыть
            аккаунт. В зависимости от используемого сервиса {siteName}, игроку необходимо выполнить
            действия для регистрации игрового аккаунта, например предоставить личную информацию:
            номер телефона и дату рождения. В Telegram боте регистрация игрового аккаунта происходит
            автоматически после запуска игроком бота командой /start.
          </p>
          <p>&nbsp;</p>
          <p>
            5.2. Для подтверждения истинности информации Компания имеет право запросить документы,
            подтверждающие личность игрока. Если в силу каких-то причин игрок не может предъявить
            документы, подтверждающие его личность, то у Компании есть право приостановить действие
            аккаунта игрока до тех пор, пока он не предъявит документы.
          </p>
          <p>&nbsp;</p>
          <p>
            5.3. Игрок подтверждает, что при регистрации в {siteName} указал полную, точную и
            достоверную информацию о себе, и если в ней произойдут какие-либо изменения, то игрок
            незамедлительно внесет их в свой профиль. Невыполнение или игнорирование данного правила
            может привести к применению ограничений, приостановлению или блокировке аккаунта, а
            также к аннулированию платежей.
          </p>
          <p>&nbsp;</p>
          <p>
            5.4. В случае возникновения каких-либо вопросов или проблем при регистрации, игрок может
            обратиться в службу поддержки в режиме telegram чата @{siteName}_games.
          </p>
          <p>&nbsp;</p>
          <p>
            5.5. Каждый игрок может открыть в сервисе {siteName} только один аккаунт. Остальные
            открытые игроком аккаунты будут рассматриваться в качестве дублирующих. У нас есть право
            закрывать такие аккаунты и:
          </p>
          <p>&nbsp;</p>
          <p>
            5.5.1. признавать недействительными все операции, проведенные с использованием
            дублирующего аккаунта;
          </p>
          <p>&nbsp;</p>
          <p>
            5.5.2. не возвращать игрокам все депозиты и ставки, сделанные с дублирующего аккаунта;
          </p>
          <p>&nbsp;</p>
          <p>
            5.5.3. требовать возвращения всех выигрышей, бонусов и денежных средств, полученных при
            использовании дублирующего аккаунта. Игрок обязан возвратить их по первому нашему
            требованию.
          </p>
          <p>&nbsp;</p>
          <h3>6. Подтверждение личности; защита от отмывания денег</h3>
          <p>6.1. Принимая данное Соглашение, игрок обязуется, соглашается и гарантирует, что:</p>
          <p>&nbsp;</p>
          <p>
            6.1.1. игроку уже исполнилось 18 лет или количество лет достаточное, чтобы считаться
            Допустимым возрастом, дающим законное право на участие в азартных играх без нарушения
            действующего законодательства своей юрисдикции;
          </p>
          <p>&nbsp;</p>
          <p>
            6.1.2. игрок – полноправный и законный владелец всех средств на своем аккаунте. Вся
            предоставленная игроком информация соответствует истине, является достоверной,
            актуальной и точной;
          </p>
          <p>&nbsp;</p>
          <p>
            6.1.3. игрок полностью осознает, что пользование сервисом {siteName} может привести к
            потере денежных средств, игрок берет на себя всю полноту ответственности за любые
            возможные потери. Игрок подтверждает, что пользуется сервисом {siteName} по своему
            собственному желанию, решению и на собственный страх и риск. Игрок не имеет права
            предъявлять Компании какие-либо претензии, связанные с его убытками и проигрышами;
          </p>
          <p>&nbsp;</p>
          <p>
            6.1.4. игрок четко понимает общие принципы, правила и процедуры предоставления услуг и
            специфику игры в Интернете. Игрок осознает, что на нем лежит ответственность по
            обеспечению правильности данных, ставок и игр. Игрок соглашается не совершать действия и
            поступки, которые могут нанести вред репутации Компании.
          </p>
          <p>&nbsp;</p>
          <p>
            6.2. Принимая условия Соглашения, игрок дает нам право на проверку достоверности
            указанной игроком информации в Профиле аккаунта.
          </p>
          <p>&nbsp;</p>
          <p>
            6.3. В период проведения проверок может быть ограничена возможность вывода денежных
            средств со счета игрока.
          </p>
          <p>&nbsp;</p>
          <p>
            6.4. Если в ходе проверки будет установлен факт ложности предоставленной информации, то
            это будет расценено как нарушение условий Соглашения, что в свою очередь, дает нам право
            закрыть аккаунт игрока или отказать игроку в пользовании сервисом {siteName}, в
            дополнение к другим действиям на наше усмотрение.
          </p>
          <p>&nbsp;</p>
          <p>
            6.5. Если мы не сможем подтвердить, что игрок достиг Допустимого возраста, то у нас
            появится право приостановить действие аккаунта игрока.
          </p>
          <p>&nbsp;</p>
          <h3>7. Имя пользователя, пароль и безопасность</h3>
          <p>
            7.1. После открытия аккаунта в {siteName}, игрок обязан хранить свой пароль и имя
            пользователя в секрете и ни в коем случае не разглашать данную информацию третьей
            стороне. В случае использования telegram бота {siteName}, игрок обязуется не
            предоставлять доступ к своей учетной записи в Телеграмм мессенджере третьим лицам.
          </p>
          <p>&nbsp;</p>
          <p>
            7.2. Игрок отвечает за сохранность пароля, а также любые действия и транзакции,
            произведенные с его счетом. Кроме того, игрок несет ответственность за все убытки,
            понесенные им в результате действий третьей стороны.
          </p>
          <p>&nbsp;</p>
          <p>
            7.3. В случае нарушения безопасности и несанкционированного доступа к аккаунту, игрок
            должен незамедлительно известить Компанию. В случае необходимости игрок обязан
            предъявить Компании доказательства того, что несанкционированный доступ действительно
            имел место. Компания не несет ответственности за ущерб, полученный игроком в результате
            неправильного или неосторожного использования имени пользователя и пароля третьей
            стороной или за несанкционированный доступ к аккаунту.
          </p>
          <p>&nbsp;</p>
          <h3>8. Размещение и вывод денежных средств со счета</h3>
          <p>
            8.1. Для того чтобы принять участие в азартных играх {siteName}, игроку нужно разместить
            на своем счету определенную сумму денег.
          </p>
          <p>&nbsp;</p>
          <p>8.2. Игрок подтверждает и обязуется, что:</p>
          <p>&nbsp;</p>
          <p>
            8.2.1. денежные средства, внесенные на игровой баланс, не были получены незаконным
            путем;
          </p>
          <p>&nbsp;</p>
          <p>
            8.2.2. не станет отказываться от совершенных транзакций, не будет отрицать и отменять
            совершенные платежи, что может стать причиной для возврата денег третьей стороне.
          </p>
          <p>&nbsp;</p>
          <p>
            8.3. Мы не принимаем денежные средства от третьих лиц: друзей, родственников или
            партнеров. Игрок обязан вносить средства только со своего счета, расчетной карты или
            системы, зарегистрированной на имя игрока.
          </p>
          <p>&nbsp;</p>
          <p>
            8.4. В случае запроса банковского перевода по возврату денежных средств их законному
            владельцу, все расходы и комиссии оплачиваются за счет получателя.
          </p>
          <p>&nbsp;</p>
          <p>
            8.5. Мы не принимаем платежи наличными денежными средствами. Мы можем использовать для
            электронных платежей, в том числе платежей и выплат игрокам, различные организации,
            занимающиеся обработкой электронных платежей или финансовые учреждения. За исключением
            тех случаев, когда правила и условия данных учреждений не противоречат условиям
            Соглашения, игрок полностью принимает такие правила.
          </p>
          <p>&nbsp;</p>
          <p>
            8.6. Игрок соглашается не отказываться, не аннулировать и не отменять транзакции,
            проведенные с участием его счета. К тому же в каждом из подобных случаев игрок обязан
            вернуть или компенсировать нам сумму неразмещенных средств, включительно с расходами,
            которые мы можем понести при сборе вкладов игрока.
          </p>
          <p>&nbsp;</p>
          <p>
            8.7. У нас есть право блокировать аккаунт игрока, а также аннулировать выплаты и
            взыскивать выигрыши, в случае возникновения у нас подозрений относительно того, что
            пополнение счета было произведено мошенническим способом. Мы имеем право известить
            соответствующие органы о мошеннических действиях с платежами и незаконной деятельности.
            У нас есть право прибегать к услугам коллекторских агентств для возврата платежей.
            Компания не несет ответственности за несанкционированное использование кредитных карт,
            независимо от того было заявлено об их краже или нет.
          </p>
          <p>&nbsp;</p>
          <p>
            8.8. Мы имеем право использовать положительный баланс счета игрока для погашения
            денежной суммы, которую игрок должен возместить Компании, в том числе и в случаях
            повторных ставок или пункта 14 («Ошибки и недочеты»).
          </p>
          <p>&nbsp;</p>
          <p>
            8.9. Игрок полностью осознает и принимает тот факт, что игровой аккаунт не является
            банковским счетом. Следовательно, по отношению к нему не действуют методы страхования,
            пополнения, гарантии и прочие способы защиты со стороны системы страхования вкладов и
            прочих систем страхования. На денежные средства на игровом счету не начисляются
            проценты.
          </p>
          <p>&nbsp;</p>
          <p>8.10. Игрок имеет право подать запрос на вывод денег с аккаунта при условии, что:</p>
          <p>&nbsp;</p>
          <p>
            8.10.1. все платежи, поступившие на счет, прошли проверку, и ни один из них не был
            отменен или аннулирован;
          </p>
          <p>&nbsp;</p>
          <p>
            8.10.2. проверочные действия, о которых говорилось в разделе 6, были проведены
            надлежащим образом.
          </p>
          <p>&nbsp;</p>
          <p>
            8.11. При оформлении заявки на вывод средств с аккаунта, важно учитывать следующие
            моменты:
          </p>
          <p>&nbsp;</p>
          <p>
            8.11.1. игровой профиль полностью заполнен, а указанная в нем почта подтверждена. В
            случае использования бота {siteName} это требование не применяется;
          </p>
          <p>&nbsp;</p>
          <p>
            8.11.2. денежные средства должны выводиться тем же способом, который был использован для
            их ввода на счет;
          </p>
          <p>&nbsp;</p>
          <p>
            8.11.3. если сумма, запрашиваемая на вывод, превышает 1000 USD, то вывод возможен только
            на биткоин кошелек игрока;
          </p>
          <p>&nbsp;</p>
          <p>
            8.11.4. если оборот средств (сумма ставок) с Основного баланса игрока в момент
            оформления заявки на вывод меньше двойного размера депозита, то вывод невозможен.
          </p>
          <p>
            Для вывода вам нужно сделать ставок на сумму, в два раза превышающую размер внесенного
            депозита.
          </p>
          <p>&nbsp;</p>
          <p>
            8.11.5. В обороте ставок учитываются только те ставки, что были сделаны игроком в
            слот-играх, ставки в риск-игре не учитываются.
          </p>
          <p>&nbsp;</p>
          <p>
            8.12. Мы имеем право удержать комиссию в размере наших затрат по выводу средств, не
            задействованных в игре.
          </p>
          <p>&nbsp;</p>
          <p>
            8.13. Сумма вывода до 1000 USD выплачивается на счет клиента от 10 минут до 2-х суток с
            момента подачи заявок, за исключением выходных и праздничных дней.
          </p>
          <p>&nbsp;</p>
          <p>
            8.14. Сумма вывода от 1000 до 3000 USD выплачивается на счет клиента в течение 5 суток с
            момента подачи заявок, за исключением выходных и праздничных дней.
          </p>
          <p>&nbsp;</p>
          <p>
            8.15. Сумма вывода от 3000 USD до 10000 USD выплачивается на счет клиента в течение 14
            суток с момента подачи заявок, за исключением выходных и праздничных дней.
          </p>
          <p>&nbsp;</p>
          <p>
            8.16. Сумма вывода от 10000 до 15 000 USD выплачивается на счет клиента в течение 21
            суток с момента подачи заявок, за исключением выходных и праздничных дней.
          </p>
          <p>&nbsp;</p>
          <p>
            8.17. Сумма вывода от 15000 USD выплачивается на счет клиента в течение 30 суток с
            момента подачи заявки, за исключением выходных и праздничных дней.
          </p>
          <p>&nbsp;</p>
          <p>8.18. Сумма вывода не может превышать 20 000 USD за 30 календарных дней.</p>
          <p>&nbsp;</p>
          <p>8.19. Сроки вывода рассчитываются от момента создания последней заявки на вывод.</p>
          <p>&nbsp;</p>
          <p>
            8.20. При оформлении вывода в рублях на сумму более 15 000 рублей заявки могут
            разбиваться на несколько платежей по 15 000 рублей каждый. Таков лимит платежной
            системы, с помощью которой происходит процесс обработки платежей.
          </p>
          <p>&nbsp;</p>
          <p>
            8.21. Сроки вывода средств могут быть больше указанных в п.п. 8.13. - 8.17. из-за
            повышенной загрузки финансового отдела, процедуры верификации личности или других
            необходимых проверок. О причинах задержки вывода в каждом конкретном случае Игрок может
            узнать в службе поддержки.
          </p>
          <p>&nbsp;</p>
          <h3>9. Депозит и Вывод в российских рублях</h3>
          <p>
            9.1. Операции Депозита (пополнения) и Вывода (снятия) денежных средств с Основного
            баланса происходят по текущему курсу криптовалюты биткоин к российскому рублю и может
            изменяться в любой момент времени. Игрок соглашается, что колебания курса не являются
            поводом для отклонения проведенных транзакций.
          </p>
          <p>&nbsp;</p>
          <p>
            10. Сговор, действия, вводящие в заблуждение, мошенничество и криминальная деятельность
          </p>
          <p>
            10.1. Следующие виды деятельности строго запрещены, совершение подобных действий будет
            расценено как прямое нарушение Соглашения:
          </p>
          <p>&nbsp;</p>
          <p>10.1.1. передача информации третьей стороне;</p>
          <p>&nbsp;</p>
          <p>
            10.1.2. незаконные действия: мошенничество, использование вредоносных программ и ошибок
            в программном обеспечении;
          </p>
          <p>&nbsp;</p>
          <p>
            10.1.3. мошеннические действия, представляющие собой использование украденных,
            клонированных или полученных иным незаконным путем данных банковской карты для
            пополнения счета;
          </p>
          <p>&nbsp;</p>
          <p>
            10.1.4. участие в преступной деятельности, отмывании денег и иных видах деятельности,
            участие в которых может повлечь за собой уголовно-правовую ответственность;
          </p>
          <p>&nbsp;</p>
          <p>
            10.1.5. вступление, попытка вступления или намерение принять участие в преступном
            сговоре с другим игроком во время игры на сервисе {siteName};
          </p>
          <p>&nbsp;</p>
          <p>
            10.2. У нас есть право приостанавливать, отменять или отзывать выплаты или выигрыши,
            связанные с бонусными средствами в том случае, если у нас появится подозрение в том, что
            игрок намеренно злоупотребляет ими.
          </p>
          <p>&nbsp;</p>
          <p>
            10.3. У нас есть право без предварительного уведомления запретить игрокам доступ к
            сервисам {siteName} и заблокировать их аккаунты, в том случае если они будут заподозрены
            в мошеннической деятельности. В таких случаях мы не несем ответственности по возврату и
            компенсации денежных средств, имеющихся на счетах таких игроков. Игроки обязаны всецело
            сотрудничать с нами при рассмотрении подобных случаев.
          </p>
          <p>&nbsp;</p>
          <p>
            10.4. Игрокам запрещено использовать услуги и программное обеспечение {siteName} для
            совершения любых мошеннических, незаконных действий и транзакций в соответствии с
            законодательством конкретной юрисдикции. Если будет установлен или выявлен факт
            совершения игроком подобных действий, то Компания может приостановить или заблокировать
            аккаунт игрока и удержать средства, имеющиеся на нем. В таких случаях у игроков нет
            права предъявлять Компании какие-либо претензии.
          </p>
          <p>&nbsp;</p>
          <h3>11. Другие запрещенные действия в сервисах {siteName}</h3>
          <p>
            11.1. На сервисах {siteName} запрещено использовать агрессивную или оскорбительную
            манеру общения, ненормативную лексику, угрозы и применять любые насильственные действия
            по отношению к сотрудникам и другим игрокам.
          </p>
          <p>&nbsp;</p>
          <p>
            11.2. Игроки обещают не совершать действия, направленные на взлом системы безопасности
            сервиса {siteName}, получение незаконного доступа к закрытым данным или DDoS-атаки. По
            отношению к игрокам, заподозренным в нарушении данного правила, будут применена
            блокировка аккаунта и всех средства на нем не подлежат возмещению.
          </p>
          <p>&nbsp;</p>
          <p>
            11.3. Мы не несем ответственности за потери и убытки, которые могут понести наши игроки
            или третья сторона вследствие технических сбоев, спровоцированных вирусными атаками или
            другими вредоносными действиями, направленными против {siteName}.
          </p>
          <p>&nbsp;</p>
          <h3>12. Срок действия и аннулирование договора</h3>
          <p>
            12.1. Мы имеем право удалить аккаунт игрока без предварительного уведомления в следующих
            случаях:
          </p>
          <p>&nbsp;</p>
          <p>
            12.1.1. в Компании было принято решение прекратить предоставление услуг всем игрокам или
            конкретному пользователю;
          </p>
          <p>&nbsp;</p>
          <p>
            12.1.2. аккаунт игрока каким-то образом связан с удаленным ранее аккаунтом за нарушение
            Договора;
          </p>
          <p>&nbsp;</p>
          <p>
            12.1.3. аккаунт игрока связан с аккаунтом, который в данный момент заблокирован, в таком
            случае мы имеем право закрыть аккаунт независимо от того, каким именно образом он
            связан, и полностью заблокировать учетные данные на этих аккаунтах;
          </p>
          <p>&nbsp;</p>
          <p>12.1.4. игрок принимает участие в преступном сговоре или пытается взломать систему;</p>
          <p>&nbsp;</p>
          <p>
            12.1.5. игрок вмешивается в работу программного обеспечения {siteName} или пытается им
            манипулировать;
          </p>
          <p>&nbsp;</p>
          <p>
            12.1.6. игрок пользуется своим аккаунтом для целей, которые могут быть расценены как
            противоправные, в соответствии с законодательством конкретной юрисдикции;
          </p>
          <p>&nbsp;</p>
          <p>
            12.1.7. игрок публикует в сервисах {siteName} информацию, носящую оскорбительный или
            унизительный характер.
          </p>
          <p>&nbsp;</p>
          <p>
            12.2. У нас есть право закрыть аккаунт игрока или отменить действие Соглашения, отправив
            игроку соответствующее уведомление в телеграмм или на email адрес, указанный в
            контактной информации. В случае таких действий с нашей стороны, за исключением ситуаций,
            упомянутых в пунктах 9 («Сговор, действия, вводящие в заблуждение, мошенничество и
            криминальная деятельность») и 16 («Нарушение условий») действующего Соглашения, мы
            обязуемся возвратить игроку средства, находящиеся на счету.
          </p>
          <p>&nbsp;</p>
          <p>
            12.3. Если аккаунт заблокирован или удален, возврат денежных средств, которые имелись на
            нем на момент закрытия, не производится. Никакие другие денежные средства не могут быть
            на него зачислены или обналичены.
          </p>
          <p>&nbsp;</p>
          <p>
            12.4. В случае закрытия аккаунт ни одна сторона не имеет никаких обязательств по
            отношению к другой.
          </p>
          <p>&nbsp;</p>
          <h3>13. Изменения на сайте</h3>
          <p>
            13.1. Мы имеем право в любое время по собственному усмотрению, вносить изменения или
            дополнять услуги, предлагаемые в сервисах {siteName} в целях поддержки и обновления
            ресурса.
          </p>
          <p>&nbsp;</p>
          <h3>14. Системные ошибки</h3>
          <p>
            14.1. Если в процессе игры произойдет сбой в работе Сайта, Компания постарается
            исправить ситуацию в кратчайшие сроки. Мы не несем ответственности за неполадки в
            средствах информационных технологий, возникшие в результате работы оборудования,
            используемого игроками для доступа к Сайту, а также за сбои в работе интернет
            провайдеров.
          </p>
          <p>&nbsp;</p>
          <h3>15. Ошибки и недочеты</h3>
          <p>15.1. У нас есть право ограничивать или отменять любые ставки.</p>
          <p>&nbsp;</p>
          <p>
            15.2. Если игрок использовал денежные средства, поступившие на его счет в результате
            ошибки для размещения ставок и участия в игре, мы можем аннулировать подобные ставки и
            любые выигрыши, полученные с их помощью. Если таким ставкам была произведена выплата
            денежных средств, то данные суммы следует считать переданными игроку в доверительное
            управление, игрок обязан возвратить их по первому нашему требованию.
          </p>
          <p>&nbsp;</p>
          <p>
            15.3. Компания и поставщики услуг не несут ответственности за ущерб, в том числе потерю
            выигрышей, произошедший в результате ошибок со стороны игрока или с нашей стороны.
          </p>
          <p>&nbsp;</p>
          <h3>16. Ограничение нашей ответственности</h3>
          <p>
            16.1. Игрок самостоятельно принимает решение пользоваться сервисами {siteName} или нет,
            и любые действия и их последствия являются следствием личного выбора игрока, сделанного
            по его усмотрению на собственный страх и риск.
          </p>
          <p>&nbsp;</p>
          <p>
            16.2. Работа сервиса {siteName} осуществляется в соответствие с пунктами данного
            Соглашения.
          </p>
          <p>&nbsp;</p>
          <p>
            16.3. Мы не несем ответственности за правонарушения, халатность, убытки, ущерб и потери,
            которые мы не можем предусмотреть в данный момент.
          </p>
          <p>&nbsp;</p>
          <h3>17. Нарушение условий</h3>
          <p>
            17.1. Игрок обязан возместить Компании любые издержки, иски и расходы (в том числе
            судебные издержки), которые могут возникнуть из-за нарушения им условий Соглашения.
          </p>
          <p>&nbsp;</p>
          <p>
            17.2. Игрок дает согласие в полной мере возмещать убытки, защищать и отстаивать интересы
            Компании, ее партнеров, сотрудников и директоров от любых притязаний, ответственности,
            издержек, потерь и расходов, возникших по причине:
          </p>
          <p>&nbsp;</p>
          <p>17.2.1. нарушения игроком условий Соглашения;</p>
          <p>&nbsp;</p>
          <p>17.2.2. нарушения игроком законов и прав третьих лиц;</p>
          <p>&nbsp;</p>
          <p>
            17.2.3. получения доступа к сервисам {siteName} любым другим лицом при помощи
            идентификационных данных игрока с его разрешения или без него, или;
          </p>
          <p>&nbsp;</p>
          <p>17.2.4. присваивать выигрыши, полученные таким путем.</p>
          <p>&nbsp;</p>
          <p>17.3. В случае если игрок нарушит условия Соглашения, мы имеем право:</p>
          <p>&nbsp;</p>
          <p>
            17.3.1. известить игрока о том, что своими действиями он нарушает условия Соглашения, и
            потребовать немедленного прекращения запрещенных действий;
          </p>
          <p>&nbsp;</p>
          <p>17.3.2. временно приостановить действие аккаунта игрока;</p>
          <p>&nbsp;</p>
          <p>17.3.3. заблокировать аккаунт игрока без предварительного уведомления;</p>
          <p>&nbsp;</p>
          <p>
            17.3.4. взыскать со счета игрока сумму выплат, выигрышей или бонусов, приобретенных в
            результате нарушения.
          </p>
          <p>&nbsp;</p>
          <h3>18. Права на объекты интеллектуальной собственности</h3>
          <p>
            18.1. Все содержание сервисом {siteName} является объектом авторского права и прочих
            прав собственности, принадлежащих Компании.
          </p>
          <p>&nbsp;</p>
          <p>
            18.2. Пользование сервисом {siteName} не дает игроку никаких прав на интеллектуальную
            собственность, принадлежащую Компании или третьей стороне.
          </p>
          <p>&nbsp;</p>
          <p>
            18.3. Запрещается любое использование или воспроизведение торговой марки, товарных
            знаков, логотипов и прочих промо-материалов, представленных в сервисах {siteName}.
          </p>
          <p>&nbsp;</p>
          <h3>19. Личная информация</h3>
          <p>
            19.1. Мы обязаны соблюдать требования по защите данных в том виде, в котором Компания
            использует личную информацию, собранную в результате использования игроком сервисов
            {siteName}. Исходя из этого, мы с максимальной серьезностью относимся к нашим
            обязательствам по использованию личных данных игроков. Компания использует личную
            информацию, предоставленную игроками с полным соблюдением политики конфиденциальности.
          </p>
          <p>&nbsp;</p>
          <p>
            19.2. Предоставляя личную информацию, игроки соглашаются с тем, что у нас есть право на
            обработку их личных данных для целей, указанных в Соглашении или же для соблюдения
            нормативных и юридических обязательств.
          </p>
          <p>&nbsp;</p>
          <p>
            19.3. Мы обязуемся не раскрывать личную информацию наших игроков никому, кроме
            работников, которые будут использовать ее исключительно для предоставления услуг.
          </p>
          <p>&nbsp;</p>
          <h3>20. Использование файлов «Cookie»</h3>
          <p>
            20.1. Мы используем файлы «cookie» для обеспечения функциональности. «Cookie» - это
            специальный текстовый файл небольшого размера, который сохраняется на компьютере
            пользователя при посещении сервиса {siteName}, с его помощью мы можем вновь узнавать
            конкретных пользователей при повторном посещении.
          </p>
          <p>&nbsp;</p>
          <h3>21. Жалобы и уведомления</h3>
          <p>
            21.1. При возникновении любых жалоб и претензий относительно работы сервиса {siteName},
            прежде всего, следует в кратчайшие сроки направить свою претензию в службу поддержки.
          </p>
          <p>&nbsp;</p>
          <p>
            21.2. Игрок соглашается с тем, что результаты игр определяются посредством работы
            генератора случайных чисел. Он генерирует события в произвольном порядке. Если между
            результатами игры на устройстве игрока и результатами на сервере возникнут расхождения,
            то результаты на сервере будут считаться более достоверными. При возникновении
            несоответствия в отображении баланса, предпочтение всегда отдается балансу на сервере.
            Денежные суммы с игрового счета могут быть потеряны из-за ошибки человека или
            технического сбоя.
          </p>
          <p>&nbsp;</p>
          <h3>22. Обстоятельства непреодолимой силы</h3>
          <p>
            22.1. Компания не несет ответственности за задержки или невыполнение обязательств,
            перечисленных в Соглашении, в том случае, если они обусловлены обстоятельствами
            непреодолимой силы, под которыми следует понимать стихийные бедствия, войны, гражданские
            волнения, промышленные споры, перебои в общественных коммунальных сетях, DDoS-атаки или
            другие интернет атаки, способные оказать негативное воздействие на работу сервиса
            {siteName}.
          </p>
          <p>&nbsp;</p>
          <p>
            22.2. В период действия обстоятельств непреодолимой силы деятельность сервиса {siteName}
            считается приостановленной, и на протяжении данного периода в исполнении обязательств
            действует отсрочка. Компания обязуется использовать все доступные ей средства для поиска
            решений, позволяющих в полной мере выполнять свои обязательства перед игроками, вплоть
            до окончания действия обстоятельств непреодолимой силы.
          </p>
          <p>&nbsp;</p>
          <h3>23. Отказ от обязательств</h3>
          <p>
            23.1. Если мы не сможем обеспечить исполнение игроком какого-либо из обязательств, или
            мы сами не сможем воспользоваться любым из средств правовой защиты, на которые у нас
            есть право, то это не следует расценивать в качестве отказа от данных средств правовой
            защиты или в качестве причины, освобождающей игрока от выполнения обязательств.
          </p>
          <p>&nbsp;</p>
          <h3>24. Делимость соглашения</h3>
          <p>
            24.1. Если какой-либо из пунктов Соглашения вдруг станет недействительным, нелегитимным
            или утратившим юридическую силу, то данное положение будет отделено от остальной части
            Соглашения, которая в полной мере сохранит свою юридическую силу. В таких случаях часть,
            которая считается недействительной, будет изменена согласно обновленным нормам.
          </p>
          <p>&nbsp;</p>
          <h3>25. Ссылки</h3>
          <p>
            25.1. В сервисах {siteName} могут присутствовать ссылки на другие ресурсы, которые
            находятся вне нашего контроля. Мы не несем ответственности за содержание других сайтов,
            действия или бездействие их владельцев. Гиперссылки на другие сайты предоставляются
            исключительно в информационных целях. Игроки используют их на собственный страх и риск.
          </p>
          <p>&nbsp;</p>
          <h3>26. Бонусы</h3>
          <p>
            26.1. За регистрацию и действия в сервисе {siteName} игрок может получать вознаграждения
            в виде бонусов. Принимая данное Соглашение, игрок подтверждает, что понимает условия
            каждого бонуса, который может быть получен им в сервисе {siteName}, и принимает их.
          </p>
          <p>&nbsp;</p>
          <p>
            26.2. Игровой баланс каждого игрока разделен на две части: Основной баланс и бонусный
            баланс. Основной баланс отображает денежные средства игрока, бонусный – сумму полученных
            бонусов. Все бонусные средства автоматически попадают на бонусный баланс, если в
            условиях бонусной акции не указано иного.
          </p>
          <p>&nbsp;</p>
          <p>
            26.3. Отыгрыш бонуса – это сумма ставок, которую необходимо сделать игроку для того,
            чтобы бонусные средства были переведены на основной баланс. При отыгрыше бонусов в
            слотах учитывается 100% от ставок. Ставки в риск-игре не учитываются.
          </p>
          <p>&nbsp;</p>
          <p>
            26.4. При совершении ставок игрок в первую очередь отыгрывает сумму полученного бонуса.
            Отыгрыш депозита (условие, при котором игроку необходимо сделать ставок на сумму, в два
            раза превышающую размер депозита) происходит только в случае, когда бонусный баланс
            равен 0.
          </p>
          <p>&nbsp;</p>
          <p>
            26.5. Если у игрока есть средства и на основном, и на бонусном балансе, то все ставки в
            первую очередь совершаются со средств на бонусном балансе. Бонусные средства
            используются для ставок до тех пор, пока бонусный баланс не станет равен 0, либо до
            полного отыгрыша каждого бонуса по отдельности и его перевода на основной баланс.
            Средства с основного баланса будут использованы для ставок только в том случае, если
            бонусный баланс равен 0.
          </p>
          <p>&nbsp;</p>
          <p>
            26.6. Все выигрыши в играх, получаемые игроком в процессе отыгрыша бонуса, попадают на
            бонусный баланс и не могут быть выведены до полного отыгрыша бонуса.
          </p>
          <p>&nbsp;</p>
          <p>
            26.7. Если размер ставки превышает количество средств на бонусном балансе, то
            недостающие средства будут взяты с реального баланса. В случае выигрыша приз будет
            зачислен на бонусный баланс. В случае проигрыша – бонусный баланс станет равен 0, а
            оставшаяся разница списана с реального баланса.
          </p>
          <p>&nbsp;</p>
          <p>
            26.8. В процессе отыгрыша бонусов игрок может участвовать в других акциях и получать
            новые бонусы. В этом случае сумма нового бонуса будет прибавлена к средствам на бонусном
            балансе. Условия отыгрыша нового бонуса будут индивидуальными в соответствии с условиями
            акции.
          </p>
          <p>
            Если бонус был получен на депозит – сумма депозита прибавится к средствам на основном
            балансе.
          </p>
          <p>&nbsp;</p>
          <p>
            26.9. Бонусы начисляются игроку автоматически и не требуют от него дополнительных
            действий. Бонус начисляется только при условии выполнения игроком требований для
            получения бонуса.
          </p>
          <p>&nbsp;</p>
          <p>
            26.10. Вывести средства можно только с основного баланса при условии, что на бонусном
            счету нет средств. Если выплата по каким-то причинам не состоялась, средства могут быть
            возвращены на основной баланс.
          </p>
        </div>
      </div>
    </div>
  );
}

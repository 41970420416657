import React, { useRef, useState } from "react";
import CloseModal from "../../../mui/close_modal/CloseModal";
import { useTranslation } from "react-i18next";
import IconMail from "../../../mui/JSXIcons/IconMail";
import CusInput from "../../../mui/custom_input/CusInput";
import { ReCAPTCHA } from "react-google-recaptcha";
import { SITE_KEY } from "../../../../store/enums";
import { useSelector } from "react-redux";
import CusButton from "../../../mui/custom_button/CusButton";
import CusForm from "../../../mui/CusForm";
import "./recovery-pas.scss";
import imgLock from "./banner-lock.png";

export default function RecoveryPas({ setForgotPas }) {
  const { t } = useTranslation();
  const lang = useSelector((state) => state.common.lang);
  const captchaRef = useRef();
  const [disableBtn, setDisableBtn] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const onFormSubmit = () => {
    setDisableBtn(true);
    // TODO API RESET PAS
    setDisableBtn(false);
  };

  return (
    <div className="recovery-pas">
      <div className="recovery-pas__img">
        <img src={imgLock} height={120} />
      </div>
      <div className="recovery-pas__fields">
        <CloseModal close={() => setForgotPas(false)} />
        <h2>{t("Forgot your password ?")}</h2>
        <p>{t("Enter the e-mail specified during registration")}</p>
        <CusForm className="recovery-pas__fields__forms" onSubmit={onFormSubmit}>
          <CusInput
            name={"email"}
            placeholder={"Email"}
            required
            iconJSXstart={<IconMail />}
            dataType={"email"}
          />

          <ReCAPTCHA ref={captchaRef} hl={lang} sitekey={SITE_KEY} />

          <CusButton
            className="remind-submit"
            text={"Remind password"}
            type={"submit"}
            disable={disableBtn}
            color="gradient-blue"
            btnLoading={btnLoading}
            setBtnLoading={setBtnLoading}
          />
        </CusForm>
      </div>
    </div>
  );
}

import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import GameContainer from "../components/games/game_container/GameContainer";
import ShowContainer from "../components/mui/ShowContainer";
import useCheckEmptyPage from "../hooks/useCheckEmptyPage";

export default function Game() {
  const games = useSelector((state) => state.games.providersGames);
  const { id } = useParams();
  const currentGame = useMemo(() => games.find((el) => el.game_id == id), [id, games]);

  useCheckEmptyPage({ isEmpry: currentGame });

  return (
    <ShowContainer condition={currentGame}>
      <GameContainer currentGame={currentGame} isBonus={false} gameId={currentGame?.game_id} />
    </ShowContainer>
  );
}

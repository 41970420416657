import { useSelector } from "react-redux";

export default function useGetItems({ isTwo } = {}) {
  const isSmallDesktop = useSelector((state) => state.deviceSize.isSmallDesktop);
  const isBigDesktop = useSelector((state) => state.deviceSize.isBigDesktop);
  const isDesktop = useSelector((state) => state.deviceSize.isDesktop);
  const isExtraSmall = useSelector((state) => state.deviceSize.isExtraSmall);

  const getItems = () => {
    if (isTwo && isExtraSmall) return 2;
    if (!isDesktop) return 3;
    if (isSmallDesktop) return 4;
    if (!isBigDesktop) return 5;
    return 6;
  };

  const items = getItems();

  return items;
}

import React from "react";

export default function IconClip() {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.28865 9.64534L8.56287 5.55398C9.07604 5.06277 9.07604 4.26635 8.56287 3.77513C8.0497 3.28391 7.21769 3.28391 6.70451 3.77513L2.46126 7.83684C1.48624 8.77015 1.48624 10.2834 2.46126 11.2167C3.43629 12.15 5.01712 12.15 5.99214 11.2167L10.2973 7.09565C11.7342 5.72025 11.7342 3.49027 10.2973 2.11487C8.86046 0.739461 6.53082 0.739461 5.09394 2.11487L1.625 5.43539"
        stroke="url(#paint0_linear_4226_2598)"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4226_2598"
          x1="3.36607"
          y1="0.309504"
          x2="7.16161"
          y2="11.6961"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2EA7FF" />
          <stop offset="1" stopColor="#0057FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

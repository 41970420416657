import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setPromoCode } from "../../../store/siteSlice";
import useGetLink from "../../../hooks/other/useGetLink";

export default function LinksFooter() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onPromoClick = () => dispatch(setPromoCode(true));

  const providers = useGetLink("/games/providers");
  const bonus = useGetLink("/games/bonus");
  const hit = useGetLink("/games/hit");
  const all = useGetLink("/games/all");
  const terms = useGetLink("/terms");

  return (
    <div className="footer__content__contents__items">
      <Link to={providers}>{t("All providers")}</Link>
      <button onClick={onPromoClick}>{t("Promo")}</button>
      <Link to={bonus}>{t("Bonus Game")}</Link>
      <Link to={hit}>{t("Hit")}</Link>
      <Link to={all}>{t("All games")}</Link>
      <Link to={terms}>{t("Usage agreement")}</Link>
    </div>
  );
}

import React, { useState } from "react";
import CarouselDesk from "../../mui/carousel_desk/CarouselDesk";
import "./providers.scss";

import { useSelector } from "react-redux";
import ShowContainer from "../../mui/ShowContainer";
import useNavigateWithSearch from "../../../hooks/useNavigateWitchSearch";
import useGetItems from "../../../hooks/other/useGetItems";

export default function Providers() {
  const providers = useSelector((state) => state.games.providers);
  const navigate = useNavigateWithSearch();
  const checkNoEmptyList = Array.isArray(providers) && providers.length;

  const onProviderClick = (el) => {
    navigate("/games/provider/" + el.name);
  };

  const slidesToShow = useGetItems();
  const isRow2 = providers.length > slidesToShow * 2 - 1;
  const GameRowClass = isRow2 ? "games-rows-2" : "games-rows";

  const ProviderItem = ({ el }) => {
    if (!el) return;
    const [hover, setHover] = useState(false);

    return (
      <button
        key={el.name}
        className="providers__item"
        onClick={() => onProviderClick(el)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}>
        <img src={el.imageHover && hover ? el.imageHover : el.image} />
      </button>
    );
  };

  const getGameItems = () => {
    if (!isRow2) return providers.map((el) => ({ item1: el }));

    const res = [];
    let curItem = {
      item1: undefined,
      item2: undefined
    };

    providers.forEach((el, indx) => {
      indx % 2 ? (curItem.item1 = el) : (curItem.item2 = el);

      if (curItem.item1 && curItem.item2) {
        res.push(curItem);
        curItem = {};
      }
    });
    return res;
  };

  const providersItems = getGameItems();

  return (
    <ShowContainer condition={checkNoEmptyList}>
      <section className="providers">
        <div className="providers__content">
          <CarouselDesk
            showAllLink="/games/providers"
            title={"Providers"}
            items={providersItems}
            row2={isRow2}>
            {providersItems.map((el, indx) => {
              const item1 = el.item1;
              const item2 = el.item2;
              return (
                <div key={el.id || indx} className={GameRowClass}>
                  <ProviderItem key={indx} el={item1} />

                  <ShowContainer condition={!!item2}>
                    <ProviderItem key={indx} el={item2} />
                  </ShowContainer>
                </div>
              );
            })}
          </CarouselDesk>
        </div>
      </section>
    </ShowContainer>
  );
}

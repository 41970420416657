import React from "react";

export default function IconGrid() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.25 8.25C6.90685 8.25 8.25 6.90685 8.25 5.25C8.25 3.59315 6.90685 2.25 5.25 2.25C3.59315 2.25 2.25 3.59315 2.25 5.25C2.25 6.90685 3.59315 8.25 5.25 8.25ZM12.75 8.25C14.4069 8.25 15.75 6.90685 15.75 5.25C15.75 3.59315 14.4069 2.25 12.75 2.25C11.0931 2.25 9.75 3.59315 9.75 5.25C9.75 6.90685 11.0931 8.25 12.75 8.25ZM8.25 12.75C8.25 14.4069 6.90685 15.75 5.25 15.75C3.59315 15.75 2.25 14.4069 2.25 12.75C2.25 11.0931 3.59315 9.75 5.25 9.75C6.90685 9.75 8.25 11.0931 8.25 12.75ZM12.75 15.75C14.4069 15.75 15.75 14.4069 15.75 12.75C15.75 11.0931 14.4069 9.75 12.75 9.75C11.0931 9.75 9.75 11.0931 9.75 12.75C9.75 14.4069 11.0931 15.75 12.75 15.75Z"
        fill="#75A7CD"
      />
    </svg>
  );
}

import React from "react";
import "./banners.scss";
import { useTranslation } from "react-i18next";
import CusButton from "../../mui/custom_button/CusButton";
import imgGirlWelcome from "./welcom-girl.png";
import imgGirlBonus from "./bonus-girl.png";
import CarouselOrTag from "../../mui/CarouselOrTag";
import { useAppSelector } from "../../../store/index.ts";
import useNavigateWithSearch from "../../../hooks/useNavigateWitchSearch.js";

export default function Banners() {
  const { t } = useTranslation();
  const isExtraSmall = useAppSelector((state) => state.deviceSize.isExtraSmall);

  const navigate = useNavigateWithSearch();

  return (
    <CarouselOrTag
      tag={"article"}
      className="banners"
      isCarousel={isExtraSmall}
      items={1}
      margin={12}
      loop={true}
      autoplaySpeed={500}
      autoplay>
      <section
        className="banners__welcome"
        onClick={() => {
          if (isExtraSmall) return;
          navigate("/games/all");
        }}>
        <div className="banners__welcome__info">
          <h2>{t("Welcome to the world of luck and big winnings!")}</h2>
          <p>{t("Start playing now!")}</p>
          <CusButton to={"/games/all"} text={"Start the game"} color="blue" />
        </div>
        <img src={imgGirlWelcome} />
        <div className="bottom-blur" />
      </section>
      <section
        className="banners__bonus"
        onClick={() => {
          if (isExtraSmall) return;
          navigate("/promotions");
        }}>
        <div className="banners__bonus__info">
          <h2>{t("All bonuses for you:")}</h2>
          <p>{t("All bonuses in one place: welcome, no deposit and much more")}</p>
          <CusButton to={"/promotions"} text={"Go"} color="blue" />
        </div>
        <img src={imgGirlBonus} />
        <div className="bottom-blur" />
      </section>
    </CarouselOrTag>
  );
}

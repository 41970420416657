import React from "react";

export default function IconCross() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.75 5.25L5.25 12.75M5.25 5.25L12.75 12.75"
        stroke="#5280A3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

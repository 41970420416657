import React from "react";

export default function IconChampionsCup() {
  return (
    <svg
      width="16.666016"
      height="17.291656"
      viewBox="0 0 16.666 17.2917"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <path
        id="Vector"
        d="M11.98 0.28C11.04 0.13 9.82 0 8.33 0C6.84 0 5.62 0.13 4.68 0.28C3.73 0.44 3.26 0.52 2.86 1.01C2.46 1.5 2.49 2.03 2.53 3.08L2.53 3.08C2.67 6.71 3.45 11.23 7.7 11.63L7.7 14.58L6.51 14.58C6.11 14.58 5.77 14.86 5.69 15.25L5.54 16.04L3.33 16.04C2.98 16.04 2.7 16.32 2.7 16.66C2.7 17.01 2.98 17.29 3.33 17.29L13.33 17.29C13.67 17.29 13.95 17.01 13.95 16.66C13.95 16.32 13.67 16.04 13.33 16.04L11.12 16.04L10.96 15.25C10.88 14.86 10.54 14.58 10.15 14.58L8.95 14.58L8.95 11.63C13.2 11.23 13.99 6.71 14.13 3.08C14.17 2.03 14.19 1.5 13.8 1.01C13.4 0.52 12.92 0.44 11.98 0.28ZM9.04 3.35L9.12 3.49C9.21 3.66 9.26 3.74 9.33 3.79C9.4 3.84 9.49 3.86 9.66 3.9L9.82 3.94C10.43 4.08 10.74 4.15 10.81 4.38C10.89 4.62 10.68 4.86 10.26 5.35L10.15 5.48C10.03 5.62 9.97 5.69 9.94 5.77C9.92 5.86 9.93 5.95 9.94 6.14L9.96 6.31C10.02 6.96 10.06 7.29 9.86 7.44C9.67 7.58 9.39 7.45 8.81 7.18L8.66 7.11C8.5 7.04 8.41 7 8.33 7C8.24 7 8.16 7.04 8 7.11L7.85 7.18C7.27 7.45 6.98 7.58 6.79 7.44C6.6 7.29 6.63 6.96 6.7 6.31L6.71 6.14C6.73 5.95 6.74 5.86 6.71 5.77C6.69 5.69 6.63 5.62 6.51 5.48L6.4 5.35C5.98 4.86 5.77 4.62 5.84 4.38C5.91 4.15 6.22 4.08 6.84 3.94L7 3.9C7.17 3.86 7.26 3.84 7.33 3.79C7.4 3.74 7.44 3.66 7.53 3.49L7.62 3.35C7.93 2.78 8.09 2.5 8.33 2.5C8.57 2.5 8.72 2.78 9.04 3.35ZM0 5.13L0 5.19C0 5.91 0 6.27 0.17 6.56C0.34 6.85 0.65 7.03 1.28 7.38L1.94 7.74C1.49 6.2 1.33 4.55 1.28 3.13C1.28 3.07 1.27 3.01 1.27 2.95L1.27 2.91C0.73 3.09 0.42 3.23 0.23 3.5C0 3.83 0 4.26 0 5.13ZM16.66 5.13L16.66 5.19C16.66 5.91 16.66 6.27 16.49 6.56C16.32 6.85 16 7.03 15.38 7.38L14.71 7.74C15.17 6.2 15.32 4.55 15.38 3.13C15.38 3.07 15.38 3.01 15.39 2.95L15.39 2.91C15.93 3.09 16.24 3.23 16.43 3.5C16.66 3.83 16.66 4.26 16.66 5.13Z"
        fill="#20A1FF"
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
    </svg>
  );
}

import { useInterval } from "usehooks-ts";
import { TEST_MODE } from "../../store/enums";
import { API_CASINO } from "../../api/casino";
import { setServerInfo } from "../../store/siteSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../store/index.ts";
import { useEffect, useRef } from "react";
import useBalanceUpdate from "./useBalanceUpdate.js";

const DELAY_GAME_STATE = 30 * 1000;

export default function useMainPage() {
  const dispatch = useDispatch();
  const isRunningGame = useAppSelector((state) => state.site.isRunningGame);
  const isRunningGameRef = useRef();
  useBalanceUpdate(isRunningGameRef);

  const getSiteInfo = () => {
    if (TEST_MODE) {
      dispatch(setServerInfo({ online: 15000 }));
      return;
    }
    if (isRunningGameRef.current) return;
    API_CASINO.getGameStat()
      .then((e) => {
        const data = e.data.gamers;
        dispatch(setServerInfo(data));
      })
      .catch(console.error);
  };

  useEffect(() => {
    isRunningGameRef.current = isRunningGame;
  }, [isRunningGame]);

  useEffect(() => {
    getSiteInfo();
  }, []);

  useInterval(() => {
    getSiteInfo();
  }, DELAY_GAME_STATE);
}

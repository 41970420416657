import React, { lazy, Suspense } from "react";

const DynamicBanners = lazy(() => import("./dynamic_banners/DynamicBanners"));

export default function TopBonusBanner() {
  return (
    <>
      {/* <StaticBanner /> */}
      <Suspense>
        <DynamicBanners />
      </Suspense>
    </>
  );
}

import React from "react";

export default function IconEyeHide() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
      <path
        d="M2.37402 3.6925L3.19024 2.87629L16.6219 16.308L15.8057 17.1242L2.37402 3.6925Z"
        fill="white"
        fillOpacity="0.6"
      />
      <path
        d="M9.62402 7.62836L11.8717 9.87607C11.8405 9.29007 11.5937 8.73632 11.1787 8.32137C10.7638 7.90642 10.21 7.65958 9.62402 7.62836Z"
        fill="white"
        fillOpacity="0.6"
      />
      <path
        d="M9.37564 12.3717L7.12793 10.124C7.15916 10.71 7.40599 11.2637 7.82094 11.6787C8.2359 12.0936 8.78965 12.3405 9.37564 12.3717Z"
        fill="white"
        fillOpacity="0.6"
      />
      <path
        d="M9.5 13.5625C8.95194 13.5625 8.41126 13.4361 7.92004 13.1931C7.42881 12.9501 7.00028 12.597 6.66779 12.1613C6.3353 11.7256 6.10782 11.2191 6.00304 10.6811C5.89826 10.1432 5.91901 9.58829 6.06367 9.05967L3.50053 6.49615C2.45367 7.45506 1.45617 8.70639 0.59375 10C1.57418 11.6328 2.91531 13.3117 4.31211 14.2743C5.91449 15.3779 7.65678 15.9375 9.49109 15.9375C10.4935 15.9382 11.4885 15.7649 12.4316 15.4254L10.4422 13.4364C10.1352 13.5204 9.8183 13.5628 9.5 13.5625Z"
        fill="white"
        fillOpacity="0.6"
      />
      <path
        d="M9.5001 6.4375C10.0482 6.43747 10.5888 6.5639 11.0801 6.80693C11.5713 7.04997 11.9998 7.40307 12.3323 7.83875C12.6648 8.27443 12.8923 8.78096 12.9971 9.31891C13.1018 9.85686 13.0811 10.4117 12.9364 10.9404L15.5586 13.5625C16.6403 12.5884 17.6393 11.278 18.4063 10C17.4274 8.38834 16.0722 6.71359 14.6516 5.7391C13.0292 4.62656 11.2929 4.0625 9.49119 4.0625C8.49979 4.06392 7.51672 4.24354 6.58887 4.59279L8.55975 6.56367C8.86615 6.47981 9.18242 6.43737 9.5001 6.4375Z"
        fill="white"
        fillOpacity="0.6"
      />
    </svg>
  );
}

import React, { useEffect, useState } from "react";
import "./type-games-menu.scss";
import IconMenuCircle from "../../mui/JSXIcons/IconMenuCircle";
import { useTranslation } from "react-i18next";
import IconTg from "../../mui/JSXIcons/IconTg";
import IconBonus from "../../mui/JSXIcons/IconBonus";
import IconFavorite from "../../mui/JSXIcons/IconFavorite";
import IconFair from "../../mui/JSXIcons/IconFair";
import IconReload from "../../mui/JSXIcons/IconReload";
import { ENUM_GAME_TYPE } from "../../../store/enums";
import useNavigateWithSearch from "../../../hooks/useNavigateWitchSearch";
import { useSelector } from "react-redux";
import ReactOwlCarousel from "react-owl-carousel";
import { useReadLocalStorage } from "usehooks-ts";
import ShowContainer from "../../mui/ShowContainer";
import { useAppSelector } from "../../../store/index.ts";

export default function TypeGamesMenu({ activeTab }) {
  const { t } = useTranslation();
  const start_r = useReadLocalStorage("start_r");
  const navigate = useNavigateWithSearch();
  const botName = useSelector((state) => state.common.botName);
  const isLsm = useSelector((state) => state.deviceSize.isLsm);
  const isDesktop = useSelector((state) => state.deviceSize.isDesktop);
  const isExtraSmall = useSelector((state) => state.deviceSize.isExtraSmall);
  const hitGames = useAppSelector((state) => state.games.hitGames);
  const bonusGames = useAppSelector((state) => state.games.bonusGames);
  const favoritesGames = useAppSelector((state) => state.games.favoriteGames);
  const historyGames = useAppSelector((state) => state.games.runningGames);

  const [items, setItems] = useState([]);
  const checkActive = (tab) => (activeTab === tab ? "active" : "");

  const casinoLink = start_r
    ? `https://t.me/${botName}?start_r=${start_r}`
    : `https://t.me/${botName}`;

  const onItemClick = (item) => {
    if (item?.link) window.open(item.link, "_blank");
    if (item?.navigate) navigate(item.navigate);
  };

  const getItemsBySize = () => {
    if (isExtraSmall) return 2;
    if (!isDesktop) return 3;
    if (isLsm) return 4;
  };
  const itemsBySize = getItemsBySize();

  useEffect(() => {
    const items = [
      {
        id: ENUM_GAME_TYPE.lobby,
        title: t("Lobby"),
        icon: <IconMenuCircle />,
        navigate: "/"
      }
    ];

    const hit = {
      id: ENUM_GAME_TYPE.hit,
      title: t("Hit"),
      icon: <IconFair />,
      navigate: "/games/hit"
    };

    const bonus = {
      id: ENUM_GAME_TYPE.bonus,
      title: t("Bonus Games"),
      icon: <IconBonus />,
      navigate: "/games/bonus"
    };
    const favorites = {
      id: ENUM_GAME_TYPE.favorites,
      title: t("Favorites Games"),
      icon: <IconFavorite />,
      navigate: "/games/favorites"
    };

    const history = {
      id: ENUM_GAME_TYPE.history,
      title: t("My game`s history"),
      icon: <IconReload />,
      navigate: "/games/history"
    };
    const casino = {
      id: "casino",
      title: t("Telegram casino"),
      icon: <IconTg />,
      link: casinoLink
    };

    if (hitGames.length) items.push(hit);
    if (bonusGames.length) items.push(bonus);
    if (favoritesGames.length) items.push(favorites);
    if (historyGames.length) items.push(history);
    if (botName) items.push(casino);

    setItems(items);
  }, [hitGames]);

  return (
    <ShowContainer condition={items.length}>
      {isLsm ? (
        <ReactOwlCarousel className="type-game-menu" dots={false} loop={false} items={itemsBySize}>
          {items.map((item, indx) => (
            <button
              key={indx}
              className={`type-game-item ${checkActive(item.id)}`}
              onClick={() => onItemClick(item)}>
              {item.icon}
              <span>{t(item.title)}</span>
            </button>
          ))}
        </ReactOwlCarousel>
      ) : (
        <nav className="type-game-menu">
          {items.map((item, indx) => (
            <button
              key={indx}
              className={`type-game-item ${checkActive(item.id)}`}
              onClick={() => onItemClick(item)}>
              {item.icon}
              <span>{t(item.title)}</span>
            </button>
          ))}
        </nav>
      )}
    </ShowContainer>
  );
}

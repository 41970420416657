import React from "react";

export default function IconEdit() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7657 13.7513C13.9116 13.6375 14.0438 13.5052 14.3084 13.2407L17.6062 9.94287C17.6859 9.86314 17.6495 9.72581 17.5429 9.68886C17.1537 9.5538 16.6473 9.30025 16.1736 8.82656C15.6999 8.35288 15.4464 7.84651 15.3113 7.45724C15.2744 7.35071 15.137 7.31422 15.0573 7.39395L11.7595 10.6918L11.7595 10.6918C11.4949 10.9563 11.3627 11.0886 11.2489 11.2344C11.1147 11.4065 10.9997 11.5926 10.9058 11.7895C10.8263 11.9565 10.7671 12.134 10.6488 12.4889L10.4959 12.9475L10.2529 13.6767L10.025 14.3604C9.96674 14.5351 10.0122 14.7276 10.1424 14.8578C10.2726 14.988 10.4651 15.0334 10.6398 14.9752L11.3235 14.7473L12.0527 14.5042L12.5113 14.3514L12.5113 14.3514C12.8662 14.2331 13.0437 14.1739 13.2106 14.0943C13.4076 14.0005 13.5937 13.8854 13.7657 13.7513Z"
        fill="#5280A3"
      />
      <path
        d="M18.6388 8.91031C19.3426 8.20645 19.3426 7.06526 18.6388 6.36139C17.9349 5.65753 16.7937 5.65753 16.0898 6.36139L15.9838 6.46742C15.8815 6.56973 15.8352 6.71263 15.8607 6.85504C15.8768 6.94463 15.9066 7.07561 15.9608 7.23189C16.0693 7.54444 16.274 7.95473 16.6597 8.34043C17.0454 8.72612 17.4557 8.9309 17.7683 9.03934C17.9246 9.09356 18.0555 9.12336 18.1451 9.13944C18.2875 9.165 18.4304 9.11865 18.5327 9.01634L18.6388 8.91031Z"
        fill="#5280A3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.47631 2.64281C2.5 3.61913 2.5 5.19047 2.5 8.33317V11.6665C2.5 14.8092 2.5 16.3805 3.47631 17.3569C4.45262 18.3332 6.02397 18.3332 9.16667 18.3332H10.8333C13.976 18.3332 15.5474 18.3332 16.5237 17.3569C17.4843 16.3962 17.4997 14.8595 17.5 11.8166L15.1514 14.1652C14.9266 14.3901 14.7425 14.5744 14.5346 14.7366C14.2909 14.9266 14.0273 15.0895 13.7484 15.2224C13.5104 15.3359 13.2632 15.4182 12.9615 15.5186L11.0351 16.1607C10.4113 16.3687 9.7235 16.2063 9.25852 15.7413C8.79355 15.2764 8.6312 14.5886 8.83914 13.9648L9.06705 13.2811L9.46298 12.0933L9.48124 12.0384C9.58171 11.7367 9.66399 11.4895 9.77745 11.2514C9.91037 10.9725 10.0733 10.7089 10.2633 10.4653C10.4255 10.2574 10.6098 10.0732 10.8347 9.84843L14.1734 6.50974L15.1 5.58321L15.206 5.47719C15.8022 4.88102 16.5836 4.58301 17.365 4.58317C17.2389 3.72438 16.9951 3.11423 16.5237 2.64281C15.5474 1.6665 13.976 1.6665 10.8333 1.6665H9.16667C6.02397 1.6665 4.45262 1.6665 3.47631 2.64281ZM6.04167 7.49984C6.04167 7.15466 6.32149 6.87484 6.66667 6.87484H12.0833C12.4285 6.87484 12.7083 7.15466 12.7083 7.49984C12.7083 7.84502 12.4285 8.12484 12.0833 8.12484H6.66667C6.32149 8.12484 6.04167 7.84502 6.04167 7.49984ZM6.04167 10.8332C6.04167 10.488 6.32149 10.2082 6.66667 10.2082H8.75C9.09518 10.2082 9.375 10.488 9.375 10.8332C9.375 11.1783 9.09518 11.4582 8.75 11.4582H6.66667C6.32149 11.4582 6.04167 11.1783 6.04167 10.8332ZM6.04167 14.1665C6.04167 13.8213 6.32149 13.5415 6.66667 13.5415H7.91667C8.26184 13.5415 8.54167 13.8213 8.54167 14.1665C8.54167 14.5117 8.26184 14.7915 7.91667 14.7915H6.66667C6.32149 14.7915 6.04167 14.5117 6.04167 14.1665Z"
        fill="#5280A3"
      />
    </svg>
  );
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CusInput from "../../../mui/custom_input/CusInput";
import CusForm from "../../../mui/CusForm";
import IconWarning from "../../../mui/JSXIcons/IconWarning";
import ShowContainer from "../../../mui/ShowContainer";
// import { API_USER } from "../../../../api/user";
// import { DEBUG } from "../../../../store/enums";
// import { errorToast } from "../../../mui/Toaster";
// import { messageByCode } from "../../../../utils/utils";
import CheckInfo from "../../../mui/site/check_info/CheckInfo";
import IconCardTopUp from "../../../mui/JSXIcons/IconCardTopUp";
import SuccessCancelButtons from "../../../mui/success_cancel_buttons/SuccessCancelButtons";
import { ENUM_CURRENCY } from "../../../../store/enums";

export default function TopUpReal({ currency, closeModal, min, max, setStep, setData }) {
  const { t } = useTranslation();
  const [btnLoading, setBtnLoading] = useState(false);

  const infoByCurrency = {
    [ENUM_CURRENCY.RUB]: t("A deposit in rubles can be made by transfer through Russian banks."),
    [ENUM_CURRENCY.UZS]: t("A deposit in sums can be made by transfer through Uzbek banks")
  };

  const info = infoByCurrency[currency];

  const onSubmit = async (e, field) => {
    e.preventDefault();
    setBtnLoading(true);

    const data = { amount: +field.amount, currency };
    setStep(2);
    setData(data);
    setBtnLoading(false);
  };

  return (
    <div className="top-up top-up__real">
      <p>{t("We do not require you to enter your card")}</p>
      <ShowContainer condition={!!info}>
        <CheckInfo className={"top-up__real__info"}>
          <p>{info}</p>
        </CheckInfo>
      </ShowContainer>
      <CusForm className={"top-up__real__form"} onSubmit={onSubmit}>
        <CusInput
          iconJSXstart={<IconCardTopUp />}
          placeholder={"Enter amount"}
          required
          type={"number"}
          dataType={"number"}
          name={"amount"}
          min={min}
          max={max}
        />
        <ShowContainer condition={min}>
          <div className="top-up__min-deposit">
            <IconWarning />
            <span className="top-up__min-deposit__text">
              {t("Minimum deposit")}
              <span className="top-up__min-deposit__value">
                {min}
                <span className="top-up__min-deposit__currency">{currency}</span>
              </span>
            </span>
          </div>
        </ShowContainer>

        <SuccessCancelButtons
          className="top-up__buttons"
          btnLoading={btnLoading}
          setBtnLoading={setBtnLoading}
          successText="Top up"
          closeModal={closeModal}
        />
      </CusForm>
    </div>
  );
}

import React from "react";

export default function IconCirclePlus() {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.9375 8.5C0.9375 12.9528 4.5472 16.5625 9 16.5625C13.4528 16.5625 17.0625 12.9528 17.0625 8.5C17.0625 4.0472 13.4528 0.4375 9 0.4375C4.5472 0.4375 0.9375 4.0472 0.9375 8.5ZM9 4.75C9.41422 4.75 9.75 5.08579 9.75 5.5V7.75H12C12.4142 7.75 12.75 8.08577 12.75 8.5C12.75 8.91422 12.4142 9.25 12 9.25H9.75V11.5C9.75 11.9142 9.41422 12.25 9 12.25C8.58577 12.25 8.25 11.9142 8.25 11.5V9.25H6C5.58579 9.25 5.25 8.91422 5.25 8.5C5.25 8.08577 5.58579 7.75 6 7.75H8.25V5.5C8.25 5.08579 8.58577 4.75 9 4.75Z"
        fill="white"
      />
    </svg>
  );
}

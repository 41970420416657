import React, { useMemo } from "react";
import { useSelector } from "react-redux";

export default function CopyrightFooter() {
  const year = useMemo(() => new Date().getFullYear(), []);
  const logoText = useSelector((state) => state.common.casinoName);

  return (
    <span className="footer__copyright">
      © {year} {logoText}
    </span>
  );
}

import React from "react";

export default function IconHit() {
  return (
    <svg
      width="16.666016"
      height="16.666656"
      viewBox="0 0 16.666 16.6667"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <path
        id="Vector"
        d="M16.34 9.63L16.53 7.6C16.68 6.01 16.75 5.21 16.48 4.88C16.33 4.7 16.13 4.59 15.92 4.57C15.52 4.54 15.02 5.11 14.03 6.24C13.51 6.83 13.25 7.12 12.97 7.16C12.81 7.19 12.65 7.16 12.5 7.09C12.23 6.96 12.06 6.59 11.7 5.87L9.84 2.05C9.17 0.68 8.84 0 8.33 0C7.82 0 7.49 0.68 6.82 2.05L4.95 5.87C4.6 6.59 4.42 6.96 4.16 7.09C4.01 7.16 3.85 7.19 3.69 7.16C3.4 7.12 3.15 6.83 2.63 6.24C1.63 5.11 1.14 4.54 0.74 4.57C0.53 4.59 0.33 4.7 0.18 4.88C-0.09 5.21 -0.02 6.01 0.13 7.6L0.32 9.63C0.64 12.97 0.79 14.65 1.78 15.65C2.76 16.66 4.24 16.66 7.19 16.66L9.46 16.66C12.41 16.66 13.89 16.66 14.88 15.65C15.86 14.65 16.02 12.97 16.34 9.63Z"
        fill="#6287A2"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
    </svg>
  );
}

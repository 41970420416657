import React from "react";

export default function IconPromo() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6726 15.8358L11.6774 14.1667C11.6774 13.7064 12.0515 13.3333 12.5128 13.3333C12.9742 13.3333 13.3482 13.7064 13.3482 14.1667V15.8137C13.3482 16.215 13.3482 16.4157 13.4769 16.5387C13.6056 16.6617 13.8024 16.6534 14.1962 16.6367C15.7486 16.571 16.7024 16.361 17.3747 15.6904C18.0486 15.0182 18.2585 14.0638 18.3239 12.5088C18.3368 12.2004 18.3433 12.0462 18.2857 11.9433C18.2281 11.8404 17.9982 11.7121 17.5384 11.4553C17.0278 11.1701 16.6826 10.6253 16.6826 10C16.6826 9.37474 17.0278 8.82989 17.5384 8.54473C17.9982 8.28796 18.2281 8.15957 18.2857 8.0567C18.3433 7.95382 18.3368 7.79963 18.3239 7.49124C18.2585 5.93617 18.0486 4.98187 17.3747 4.30965C16.6433 3.58004 15.5787 3.39568 13.7725 3.3491C13.5395 3.34309 13.3482 3.53099 13.3482 3.76349V5.83334C13.3482 6.29358 12.9742 6.66668 12.5128 6.66668C12.0515 6.66668 11.6774 6.29358 11.6774 5.83334L11.6714 3.7488C11.6707 3.51916 11.4839 3.33334 11.2537 3.33334H8.32853C5.178 3.33334 3.60274 3.33334 2.624 4.30965C1.95011 4.98187 1.74021 5.93617 1.67483 7.49124C1.66186 7.79963 1.65538 7.95382 1.71297 8.0567C1.77056 8.15957 2.00045 8.28796 2.46025 8.54473C2.97089 8.82989 3.31607 9.37474 3.31607 10C3.31607 10.6253 2.97089 11.1701 2.46025 11.4553C2.00046 11.7121 1.77056 11.8404 1.71297 11.9433C1.65538 12.0462 1.66186 12.2004 1.67483 12.5088C1.74021 14.0638 1.95011 15.0182 2.624 15.6904C3.60274 16.6667 5.178 16.6667 8.32853 16.6667H10.8372C11.2299 16.6667 11.4263 16.6667 11.5486 16.5451C11.6709 16.4235 11.6714 16.2276 11.6726 15.8358ZM13.3482 10.8333V9.16668C13.3482 8.70644 12.9742 8.33334 12.5128 8.33334C12.0515 8.33334 11.6774 8.70644 11.6774 9.16668V10.8333C11.6774 11.2936 12.0515 11.6667 12.5128 11.6667C12.9742 11.6667 13.3482 11.2936 13.3482 10.8333Z"
        fill="#6287A2"
      />
    </svg>
  );
}

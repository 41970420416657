import React from "react";

export default function IconStarFilledThin() {
  return (
    <svg
      className="icon-start"
      width="24.900391"
      height="24.900757"
      viewBox="0 0 24.9004 24.9008"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <rect
        id="star/filled/thin"
        rx="0"
        width="19.357307"
        height="19.357307"
        transform="translate(0.500000 5.725555) rotate(-14.873882)"
        fill="#FFFFFF"
        fillOpacity="0"
      />
      <path
        id="star/simple/thin"
        d="M9.83 2.61L12.7 12.01L22.28 9.83L12.88 12.7L15.06 22.28L12.19 12.88L2.61 15.06L12.01 12.2L9.83 2.61Z"
        fill="#FCFEFF"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
    </svg>
  );
}

import React, { useState } from "react";
import { useAppSelector } from "../../../../../store/index.ts";
import imgDefaultAva from "./default-ava.png";

export default function AvatarImg() {
  const imgSource = useAppSelector((state) => state.user.info.photoUrl);

  const [errorAvatar, setErrorAvatar] = useState(null);

  return (
    <img
      // src={errorAvatar || imgSource || imgDefaultAva}
      src={imgDefaultAva}
      onError={() => setErrorAvatar(imgDefaultAva)}
    />
  );
}

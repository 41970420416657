import React from "react";
import CusInput from "../../../mui/custom_input/CusInput";
import IconCard from "../../../mui/JSXIcons/IconCard";

export default function CardFields() {
  return (
    <>
      <CusInput
        iconJSXstart={<IconCard />}
        name={"cardNumber"}
        type={"number"}
        dataType={"card"}
        placeholder={"Card number"}
        minLength={13}
        maxLength={19}
        required
      />
    </>
  );
}

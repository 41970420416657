import React from "react";
import "./carousel-desk-header.scss";
import useNavigateWithSearch from "../../../../hooks/useNavigateWitchSearch";
import IconArrow from "../../JSXIcons/IconArrow";
import CusButton from "../../custom_button/CusButton";
import DeskTitle from "../../desk_title/DeskTitle";
import useGetIconByTitle from "../../../../hooks/other/useGetIconByTitle";

export default function CarouselDeskHeader({
  title,
  showAllLink,
  slidesToShow,
  sliderRef,
  manyLength
}) {
  const navigate = useNavigateWithSearch();

  const onShowAll = () => {
    if (showAllLink) navigate(`${showAllLink}`);
  };

  const onPrevClick = () => {
    for (let i = 0; i < slidesToShow; i++) {
      sliderRef.current.prev();
    }
  };

  const onNextClick = () => {
    for (let i = 0; i < slidesToShow; i++) {
      sliderRef.current.next();
    }
  };

  const iconTitle = useGetIconByTitle(title);

  return (
    <div className="carousel-desk__header">
      <DeskTitle iconTitle={iconTitle} title={title} />
      <div className="carousel-desk__header__right">
        <CusButton color="blue show-all" text="Show all" onClick={onShowAll} />
        <div className="carousel-desk__controls">
          <button
            disabled={!manyLength}
            className="carousel-desk__controls__prev"
            onClick={onPrevClick}>
            <IconArrow className="rotate90" />
          </button>
          <button
            disabled={!manyLength}
            className="carousel-desk__controls__next"
            onClick={onNextClick}>
            <IconArrow className="rotate270" />
          </button>
        </div>
      </div>
    </div>
  );
}

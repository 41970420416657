import React from "react";
import DefaultModal from "../DefaultModal";
import { useTranslation } from "react-i18next";
import CusButton from "../../mui/custom_button/CusButton";

export default function ConfirmCloseModal({ isOpen, closeModal, onConfirm }) {
  const { t } = useTranslation();
  return (
    <DefaultModal isOpen={isOpen} closeModal={closeModal} classModal="error-game-modal">
      <h2>{t("Close the game")}</h2>
      <div className="error-game-modal__content">
        <p>{t("Changes made may be lost")}</p>

        <div className="buttons">
          <CusButton color="gray" text={"Close"} onClick={onConfirm} />
          <CusButton text={"Back"} onClick={closeModal} />
        </div>
      </div>
    </DefaultModal>
  );
}

import React from "react";

export default function IconDollarCircle() {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 7.5C15.5 11.6421 12.1421 15 8 15C3.85786 15 0.5 11.6421 0.5 7.5C0.5 3.35786 3.85786 0 8 0C12.1421 0 15.5 3.35786 15.5 7.5ZM8 6.75C7.58579 6.75 7.25 6.41421 7.25 6C7.25 5.58579 7.58579 5.25 8 5.25C8.41421 5.25 8.75 5.58579 8.75 6C8.75 6.41421 9.08579 6.75 9.5 6.75C9.91421 6.75 10.25 6.41421 10.25 6C10.25 5.02034 9.62389 4.18691 8.75 3.87803V3.375C8.75 2.96079 8.41421 2.625 8 2.625C7.58579 2.625 7.25 2.96079 7.25 3.375V3.87803C6.37611 4.18691 5.75 5.02034 5.75 6C5.75 7.24264 6.75736 8.25 8 8.25C8.41421 8.25 8.75 8.58579 8.75 9C8.75 9.41421 8.41421 9.75 8 9.75C7.58579 9.75 7.25 9.41421 7.25 9C7.25 8.58579 6.91421 8.25 6.5 8.25C6.08579 8.25 5.75 8.58579 5.75 9C5.75 9.97966 6.37611 10.8131 7.25 11.122V11.625C7.25 12.0392 7.58579 12.375 8 12.375C8.41421 12.375 8.75 12.0392 8.75 11.625V11.122C9.62389 10.8131 10.25 9.97966 10.25 9C10.25 7.75736 9.24264 6.75 8 6.75Z"
        fill="#20A1FF"
      />
    </svg>
  );
}

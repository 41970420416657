import { useDispatch } from "react-redux";
import { setAngle, setFullWidth, setWidth } from "../store/deviceSizeSlice";
import { useEventListener } from "usehooks-ts";

export default function useDeviceSizeFill() {
  const dispatch = useDispatch();

  const checkSizeWindow = () => {
    dispatch(setAngle(screen?.orientation?.angle));
    if (!window.innerWidth) return;
    dispatch(setFullWidth(window.outerWidth));
    dispatch(setWidth(window.innerWidth));
    dispatch(setAngle(screen?.orientation?.angle));
  };

  useEventListener("resize", checkSizeWindow);
}

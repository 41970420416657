import React from "react";

export default function IconProviders() {
  return (
    <svg
      width="20.000000"
      height="17.058838"
      viewBox="0 0 20 17.0588"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <path
        id="Vector"
        d="M9.41 6.18C5.44 6.27 2.35 7.24 2.35 8.82C2.35 10.47 5.75 11.47 10 11.47C14.24 11.47 17.64 10.47 17.64 8.82C17.64 7.24 14.55 6.27 10.58 6.18L10.58 4.71C15.82 4.82 20 6.47 20 8.82C20 11.25 15.5 12.94 10 12.94C4.49 12.94 0 11.25 0 8.82C0 6.47 4.17 4.82 9.41 4.71L9.41 6.18ZM20 12.94C20 15.37 15.5 17.05 10 17.05C4.49 17.05 0 15.37 0 12.94L0 10.94C1.65 12.76 5.46 13.82 10 13.82C14.53 13.82 18.34 12.76 20 10.94L20 12.94ZM9.41 8.52C9.41 8.85 9.67 9.11 10 9.11C10.32 9.11 10.58 8.85 10.58 8.52L10.58 7.36C12.2 7.4 13.7 7.6 14.82 7.93C15.9 8.24 16.47 8.63 16.47 8.82C16.47 9.01 15.9 9.39 14.82 9.71C13.56 10.08 11.84 10.29 10 10.29C8.15 10.29 6.43 10.08 5.17 9.71C4.09 9.39 3.52 9.01 3.52 8.82C3.52 8.63 4.09 8.24 5.17 7.93C6.29 7.6 7.79 7.4 9.41 7.36L9.41 8.52ZM10 4.7C9.8 4.7 9.6 4.7 9.41 4.71L9.41 3.42C8.72 3.18 8.23 2.53 8.23 1.76C8.23 0.79 9.02 0 10 0C10.97 0 11.76 0.79 11.76 1.76C11.76 2.53 11.27 3.18 10.58 3.42L10.58 4.71C10.39 4.7 10.19 4.7 10 4.7Z"
        fill="#6287A2"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
    </svg>
  );
}

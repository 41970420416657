import React from "react";
import IconFair from "../../components/mui/JSXIcons/IconFair";
import IconProviders from "../../components/mui/JSXIcons/IconProviders";
import IconBonus from "../../components/mui/JSXIcons/IconBonus";
import IconPlay from "../../components/mui/JSXIcons/IconPlay";
import IconFavorite from "../../components/mui/JSXIcons/IconFavorite";

export default function useGetIconByTitle(title) {
  const imgByTitle = {
    Hit: <IconFair />,
    "Hit Games": <IconFair />,
    Providers: <IconProviders />,
    "Game providers": <IconProviders />,
    "Bonus Games": <IconBonus />,
    "History Games": <IconPlay />,
    Favorites: <IconFavorite />
  };

  const iconTitle = imgByTitle[title];

  return iconTitle;
}

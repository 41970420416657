import { useAppSelector } from "../../store/index.ts";

export default function useGetLink(link) {
  const theme = useAppSelector((state) => state.common.botTheme);
  const mode = useAppSelector((state) => state.common.modeGame);
  const sessionId = useAppSelector((state) => state.common.sessionId);
  const lang = useAppSelector((state) => state.common.lang);

  let url = link + "?";
  if (theme) {
    url += `bot_theme=${theme}&`;
  }
  if (mode) {
    url += `mode=${mode}&`;
  }
  if (sessionId) {
    url += `sessionId=${sessionId}&`;
  }
  if (lang) {
    url += `lang=${lang}`;
  }

  return url;
}

import React, { useMemo } from "react";
import CheckInfo from "../../mui/site/check_info/CheckInfo";
import { useTranslation } from "react-i18next";
import ShowContainer from "../../mui/ShowContainer";
import { useAppSelector } from "../../../store/index.ts";

export default function WithdrawCheck() {
  const { t } = useTranslation();
  const currency = useAppSelector((state) => state.user.currency);
  const balance = useAppSelector((state) => state.user.balance);

  const curBalance = useMemo(() => balance?.[currency], [balance, currency]);

  const getDecimalValue = (val) => {
    const decimal = curBalance?.info?.decimal;
    return val?.toFixed(decimal || 0);
  };

  const payout = useMemo(
    () => getDecimalValue(curBalance?.balances?.payout),
    [curBalance, currency]
  );

  const depositWager = useMemo(
    () => getDecimalValue(curBalance?.balances?.depositWager),
    [curBalance]
  );
  const bonusWager = useMemo(() => getDecimalValue(curBalance?.balances?.bonusWager), [curBalance]);

  return (
    <>
      <CheckInfo className={"withdraw__check"}>
        <div className="withdraw__check__info">
          <span>{t("Available withdrawal amount")}</span>
          <span className="withdraw__check__amount">
            <span className="withdraw__check__value">{payout}</span>
            <span className="withdraw__check__currency">{currency}</span>
          </span>
        </div>
      </CheckInfo>

      <ShowContainer condition={+depositWager}>
        <CheckInfo className={"withdraw__check"}>
          <div className="withdraw__check__info">
            <span>
              {t("Place amount of bets to open the entire amount for withdrawal.", {
                depositWager,
                currency
              })}
            </span>
          </div>
        </CheckInfo>
      </ShowContainer>

      <ShowContainer condition={+bonusWager}>
        <CheckInfo className={"withdraw__check"}>
          <div className="withdraw__check__info">
            <span>
              {t("Place amount of bets to win back your bonus.", {
                bonusWager,
                currency
              })}
            </span>
          </div>
        </CheckInfo>
      </ShowContainer>
    </>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import "./custom-radio.scss";
import IconCircleInfo from "../JSXIcons/IconCircleInfo";
import ShowContainer from "../ShowContainer";

//CusRadio - дефолтный компонент radio
// options - возможные поля для выбора следующего вида :
//  [{
//     title - название которое будет отображаться пользователю
//     value - значение которое передастся в htmp input
//     info  - дополнительный блок информации с каким то текстом
//  }]

/**
 *
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.legend
 * @param {boolean} props.required
 * @param { React.ChangeEventHandler<HTMLInputElement>} props.onChange
 * @param {(indx: number) => boolean} props.defaultChecked
 * @param {Array<{title: string, value: string, info: string}>} props.options - The radio button options

 */

export default function CusRadio({ name, legend, required, onChange, defaultChecked, options }) {
  const { t } = useTranslation();

  return (
    <fieldset className="custom-radio">
      {legend && <legend> {t(legend)} </legend>}

      {options?.map((option, indx) => (
        <div key={option.value}>
          <input
            onChange={onChange}
            type="radio"
            id={option.value}
            name={name}
            required={required}
            value={option.value}
            defaultChecked={defaultChecked?.(indx)}
          />
          <label htmlFor={option.value}>{t(option.title)}</label>

          <ShowContainer condition={option.info}>
            <div className="custom-radio__info">
              <IconCircleInfo />
              <span className="custom-radio__info__text">{t(option.info)}</span>
            </div>
          </ShowContainer>
        </div>
      ))}
    </fieldset>
  );
}

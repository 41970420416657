import React, { useMemo } from "react";
import DefaultModal from "../DefaultModal";
import { useTranslation } from "react-i18next";
import "./error-game-modal.scss";
import CusButton from "../../mui/custom_button/CusButton";

export default function ErrorGameModal({ isOpen, closeModal, message, currentGame }) {
  const { t } = useTranslation();

  const gameName = useMemo(() => {
    let name = "";

    if (currentGame.name) {
      name = currentGame.provider
        ? `${currentGame.name} (${currentGame.provider})`
        : `${currentGame.name}`;
    } else name = "Game";

    return name;
  }, [currentGame]);

  return (
    <DefaultModal miniModal isOpen={isOpen} closeModal={closeModal} classModal="error-game-modal">
      <h2>
        {gameName} {t("not available")}
      </h2>
      <div className="error-game-modal__content">
        <div className="chose-mode__game">
          <img src={currentGame.image} />
          <p>{message}</p>
        </div>

        <CusButton text={"Back"} onClick={closeModal} />
      </div>
    </DefaultModal>
  );
}

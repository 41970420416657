import React from "react";

export default function IconCopy() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        d="M11.3327 9.13783V12.1128C11.3327 14.592 10.341 15.5837 7.86185 15.5837H4.88685C2.40768 15.5837 1.41602 14.592 1.41602 12.1128V9.13783C1.41602 6.65866 2.40768 5.66699 4.88685 5.66699H7.86185C10.341 5.66699 11.3327 6.65866 11.3327 9.13783Z"
        fill="#363636"
      />
      <path
        d="M12.1126 1.41699H9.13758C7.07066 1.41699 6.04252 2.1112 5.75745 3.77392C5.66334 4.32283 6.12882 4.78158 6.68573 4.78158H7.86258C10.8376 4.78158 12.2188 6.16283 12.2188 9.13783V10.3147C12.2188 10.8716 12.6776 11.3371 13.2265 11.243C14.8892 10.9579 15.5834 9.92975 15.5834 7.86283V4.88783C15.5834 2.40866 14.5917 1.41699 12.1126 1.41699Z"
        fill="#363636"
      />
    </svg>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/index.ts";

export default function PromoCodeSuccess({ info }) {
  const { t } = useTranslation();
  const channelUrl = useAppSelector((state) => state.links.channelUrl);

  return (
    <div className="promo-code__success">
      <h3>
        {info.amount} {info.currency} {t("credited to the balance")}
      </h3>

      <p className="promo-code__text">{t("Congratulations, you are faster than other players.")}</p>
      <p className="promo-code__text">
        {t("This is not your last promo code, catch new promo codes on our channel")}{" "}
        <a className="promo-code__text__link" href={channelUrl} target="_blank" rel="noreferrer">
          {channelUrl}
        </a>
      </p>
    </div>
  );
}

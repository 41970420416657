import React from "react";
import "./find-game-line.scss";
import FindGame from "../find_game/FindGame";
// import CusSelect from "../../mui/find_select/CusSelect";

export default function FindGameLine() {
  // TODO - скрыто, не определена логика
  // const providers = [{ value: "all", label: "Все провайдеры" }];

  return (
    <div className="find-game-line">
      <FindGame />
      {/* <CusSelect options={providers} valueProps="all" /> */}
    </div>
  );
}

import React from "react";

export default function IconPik() {
  return (
    <svg
      width="12.935547"
      height="12.999981"
      viewBox="0 0 12.9355 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient
          x1="2.309925"
          y1="-0.928568"
          x2="6.467794"
          y2="12.999983"
          id="paint_linear_4156_5661_0"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2EA7FF" />
          <stop offset="1.000000" stopColor="#0057FF" />
        </linearGradient>
      </defs>
      <path
        id="Vector"
        d="M11.48 4.67C9.46 3.09 7.61 1.05 6.84 0.17C6.64 -0.06 6.29 -0.06 6.09 0.17C5.32 1.05 3.47 3.09 1.45 4.67C0.53 5.39 0 6.47 0 7.62C0 9.65 1.69 11.3 3.78 11.3C4.48 11.3 5.13 11.03 5.61 10.6L5.61 11.32C5.61 12.19 4.98 12.3 4.5 12.71C4.38 12.8 4.45 13 4.6 13L8.31 13C8.46 13 8.53 12.81 8.42 12.71C7.94 12.3 7.32 12.2 7.32 11.32L7.32 10.6C7.8 11.03 8.44 11.3 9.15 11.3C11.24 11.3 12.93 9.65 12.93 7.62C12.93 6.47 12.39 5.39 11.48 4.67Z"
        fill="url(#paint_linear_4156_5661_0)"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
    </svg>
  );
}

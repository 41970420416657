import React from "react";

export default function IconWallet() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6067 6.57373C13.5703 6.57141 13.5306 6.57143 13.4895 6.57144L13.4793 6.57144H11.8674C10.5388 6.57144 9.4019 7.62548 9.4019 9.00001C9.4019 10.3745 10.5388 11.4286 11.8674 11.4286H13.4793L13.4895 11.4286C13.5306 11.4286 13.5703 11.4286 13.6067 11.4263C14.1464 11.392 14.6236 10.9663 14.6638 10.3718C14.6664 10.3328 14.6664 10.2908 14.6663 10.2519L14.6663 10.2413V7.75875L14.6663 7.74817C14.6664 7.70923 14.6664 7.66721 14.6638 7.62822C14.6236 7.03371 14.1464 6.60807 13.6067 6.57373ZM11.7244 9.64763C12.0665 9.64763 12.3438 9.35768 12.3438 9.00001C12.3438 8.64234 12.0665 8.35239 11.7244 8.35239C11.3824 8.35239 11.1051 8.64234 11.1051 9.00001C11.1051 9.35768 11.3824 9.64763 11.7244 9.64763Z"
        fill="#FCFEFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4891 12.4C13.5849 12.3975 13.6574 12.4869 13.6314 12.5799C13.5024 13.0413 13.2977 13.4346 12.9692 13.7657C12.4883 14.2503 11.8785 14.4654 11.1252 14.5675C10.3931 14.6667 9.45778 14.6667 8.27689 14.6667H6.91925C5.73836 14.6667 4.80301 14.6667 4.07099 14.5675C3.31762 14.4654 2.70786 14.2503 2.22698 13.7657C1.7461 13.281 1.5327 12.6665 1.43141 11.9072C1.33299 11.1694 1.333 10.2267 1.33301 9.03655V8.96347C1.333 7.77331 1.33299 6.83061 1.43141 6.09283C1.53269 5.33355 1.7461 4.71899 2.22698 4.23434C2.70786 3.74969 3.31762 3.5346 4.07099 3.43252C4.80301 3.33333 5.73836 3.33333 6.91925 3.33334L8.27689 3.33334C9.45778 3.33333 10.3931 3.33333 11.1252 3.43252C11.8785 3.5346 12.4883 3.74968 12.9692 4.23434C13.2977 4.56545 13.5024 4.9587 13.6314 5.42009C13.6574 5.51307 13.5849 5.60252 13.4891 5.60001L11.8673 5.60002C10.0446 5.60002 8.43799 7.05127 8.43799 9.00001C8.43799 10.9488 10.0446 12.4 11.8673 12.4L13.4891 12.4ZM3.74265 5.92382C3.47649 5.92382 3.26072 6.14128 3.26072 6.40953C3.26072 6.67779 3.47649 6.89525 3.74265 6.89525H6.31293C6.57909 6.89525 6.79486 6.67779 6.79486 6.40953C6.79486 6.14128 6.57909 5.92382 6.31293 5.92382H3.74265Z"
        fill="#FCFEFF"
      />
      <path
        d="M5.18413 2.68293L6.49 1.72085C7.1913 1.20417 8.14138 1.20417 8.84268 1.72085L10.1554 2.68799C9.60654 2.66665 8.99359 2.66666 8.32159 2.66668H6.87455C6.26049 2.66666 5.69574 2.66665 5.18413 2.68293Z"
        fill="#FCFEFF"
      />
    </svg>
  );
}

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setChooseMode, setDataChooseMode } from "../store/siteSlice";
import { ENUM_MODE_GAME } from "../store/enums";

export default function useChooseMode() {
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.common.modeGame);
  const hardModeGame = useSelector((state) => state.common.hardModeGame);

  const onChoseMode = useCallback(
    ({ cbReal = () => {}, cbDemo = () => {}, close = () => {}, game }) => {
      if (hardModeGame) {
        return hardModeGame === ENUM_MODE_GAME.demo ? cbDemo() : cbReal();
      }
      dispatch(setChooseMode(true));
      dispatch(setDataChooseMode({ cbReal, cbDemo, close, game }));
    },
    [hardModeGame]
  );

  const checkIsModeGame = useCallback(
    (...props) => {
      if (mode) return true;
      onChoseMode(...props);
    },
    [mode, onChoseMode]
  );

  return { checkIsModeGame, onChoseMode };
}

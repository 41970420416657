import React from "react";
import IconCross from "../JSXIcons/IconCross";
import "./close-modal.scss";

export default function CloseModal({ close }) {
  return (
    <button className="modal__close" title="close" onClick={close}>
      <IconCross />
    </button>
  );
}

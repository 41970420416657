import React, { useState } from "react";
import CusInput from "../../../mui/custom_input/CusInput";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import IconAddress from "../../../mui/JSXIcons/IconAddress";

export default function AddressFields() {
  const { t } = useTranslation();
  const currency = useSelector((state) => state.user.currency);

  const [address, setAddress] = useState("");

  return (
    <CusInput
      iconJSXstart={<IconAddress />}
      defaultValue={address}
      name={"address"}
      onChange={(e) => setAddress(e.target.value)}
      placeholder={`${t("Wallet number")} ${currency} (TRC20)`}
      required
    />
  );
}

import { useDispatch } from "react-redux";
import useNavigateWithSearch from "../useNavigateWitchSearch";
import { setSaveSiteBeforeGame } from "../../store/commonSlice";
import useChooseMode from "../useChooseMode";

export default function useGameClick() {
  const dispatch = useDispatch();
  const navigate = useNavigateWithSearch();
  const { onChoseMode } = useChooseMode();

  const onGameClick = ({ urlParam, url = "game/", isChoose, currentGame }) => {
    const pathName = window.location.pathname;
    const id = currentGame.game_id || currentGame.id;

    const cbRunGame = () => {
      dispatch(setSaveSiteBeforeGame({ url: pathName, topScroll: window.scrollY }));
      navigate(url + id, { state: urlParam });
    };

    if (isChoose) {
      onChoseMode({ cbReal: cbRunGame, cbDemo: cbRunGame, game: currentGame });
    } else {
      cbRunGame();
    }
  };

  return { onGameClick };
}

import React from "react";

export default function IconFlagRu() {
  return (
    <svg
      width="32.000000"
      height="32.000000"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="clip2002_13">
          <rect
            id="openmoji:flag-russia"
            width="32.000000"
            height="32.000000"
            fill="white"
            fillOpacity="0"
          />
        </clipPath>
      </defs>
      <rect
        id="openmoji:flag-russia"
        width="32.000000"
        height="32.000000"
        fill="#FFFFFF"
        fillOpacity="0"
      />
      <g clipPath="url(#clip2002_13)">
        <path
          id="Vector"
          d="M2.22 7.55L29.77 7.55L29.77 24.44L2.22 24.44L2.22 7.55Z"
          fill="#D22F27"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M2.22 7.55L29.77 7.55L29.77 13.33L2.22 13.33L2.22 7.55Z"
          fill="#FFFFFF"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M2.22 13.33L29.77 13.33L29.77 18.66L2.22 18.66L2.22 13.33Z"
          fill="#1E50A0"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M29.77 7.55L29.77 24.44L2.22 24.44L2.22 7.55L29.77 7.55Z"
          stroke="#000000"
          strokeOpacity="1.000000"
          strokeWidth="0.666667"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

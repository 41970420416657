import React from "react";
import useScrollUp from "../../../hooks/useScrollUp";
import Header from "../../landing/header/Header";
import Back from "../../mui/back/Back";
import Footer from "../../landing/footer/Footer";
import ButtonUp from "../../mui/button_up/ButtonUp";
import PageWitchMenu from "../../mui/page_witch_menu/PageWitchMenu";
import FindGameLine from "../../landing/find_game_line/FindGameLine";
import useGetIconByTitle from "../../../hooks/other/useGetIconByTitle";

export default function GamesContainer({ children, title, backLink = "/" }) {
  useScrollUp();

  const icon = useGetIconByTitle(title);

  return (
    <PageWitchMenu className={"games"}>
      <Header />
      <section className="games__content">
        <div className="games__content__content">
          <Back icon={icon} to={backLink} title={title} />
          <FindGameLine />
          {children}
        </div>
      </section>
      <ButtonUp />
      <Footer />
    </PageWitchMenu>
  );
}

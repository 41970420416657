import React, { useMemo } from "react";
import GameItem from "../mui/game_tem/GameItem";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useCheckEmptyPage from "../../hooks/useCheckEmptyPage";

export default function Provider() {
  const { subId } = useParams();

  const providersGames = useSelector((state) => state.games.providersGames);
  const games = useMemo(
    () => providersGames.filter((el) => el.provider == subId || el.providerName == subId),
    [providersGames, subId]
  );

  useCheckEmptyPage({ isEmpry: games.length });

  return (
    <div className="games__games">
      {games?.map((el) => (
        <GameItem currentGame={el} key={el.game_id} name={el.name} image={el.image} />
      ))}
    </div>
  );
}

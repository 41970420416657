import React, { useMemo } from "react";
import DefaultModal from "../DefaultModal";
import { useTranslation } from "react-i18next";
import CusButton from "../../mui/custom_button/CusButton";
import { useDispatch } from "react-redux";
import { setModeGame } from "../../../store/commonSlice";
import { ENUM_MODE_GAME } from "../../../store/enums";
import useLoginAction from "../../../hooks/useLoginAction";
import useCheckDemoMode from "../../../hooks/useCheckDemoMode";
import ShowContainer from "../../mui/ShowContainer";
import "./chose-mode.scss";

export default function ChooseModeGameModal({ isOpen, closeModal, data }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onLoginAction } = useLoginAction();
  const currentGame = useMemo(() => data.game, [data]);
  const { availableDemo, availableReal } = useCheckDemoMode({ currentGame });
  const isAvailable = useMemo(() => availableDemo || availableReal, [availableReal, availableDemo]);

  const onDemoClick = () => {
    data?.cbDemo?.();
    dispatch(setModeGame(ENUM_MODE_GAME.demo));
    closeModal(true);
  };

  const onRealClick = () => {
    onLoginAction(() => {
      data?.cbReal?.();
      dispatch(setModeGame(ENUM_MODE_GAME.real));
    });
    closeModal(true);
  };

  return (
    <DefaultModal miniModal isOpen={isOpen} closeModal={closeModal} classModal="chose-mode">
      {isAvailable ? (
        <>
          <h2>{t("Selecting a game mode")}</h2>
          <div className="chose-mode__content">
            <p>{t("Select the mode you want to continue in")}</p>

            <div className="chose-mode__game">
              <img src={currentGame.image} />

              <span className="chose-mode__game__title">{currentGame.name}</span>
            </div>

            <div className="buttons">
              <ShowContainer condition={availableReal}>
                <CusButton color={"gradient-blue"} text={t("Real")} onClick={onRealClick} />
              </ShowContainer>
              <ShowContainer condition={availableDemo}>
                <CusButton color="primary" text={t("Demo")} onClick={onDemoClick} />
              </ShowContainer>
            </div>
          </div>
        </>
      ) : (
        <>
          <h2>{t("Not available")}</h2>
          <CusButton text={t("Close")} onClick={closeModal} />
        </>
      )}
    </DefaultModal>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import IconBonus from "../../mui/JSXIcons/IconBonus";

import "./static-bonus.scss";
import StaticBonusItem from "./StaticBonusItem";
import useFillBonus from "../../../hooks/common/use_fill_bonus/useFillBonus";

export default function StaticBonus() {
  const { t } = useTranslation();
  const { bonuses } = useFillBonus();

  return (
    <div className="static-bonus">
      <h3>
        <IconBonus /> {t("Bonuses")}
      </h3>

      <div className="static-bonus__bonuses">
        {bonuses.map((el) => (
          <StaticBonusItem bonus={el} key={el.id} />
        ))}
      </div>
    </div>
  );
}

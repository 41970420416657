import React from "react";
import PageWitchMenu from "../components/mui/page_witch_menu/PageWitchMenu";
import Header from "../components/landing/header/Header";
import Footer from "../components/landing/footer/Footer";
import PromotionsMain from "../components/promotions/PromotionsMain";
import useScrollUp from "../hooks/useScrollUp";
import ErrorBoundary from "../components/mui/error_boundary/ErrorBoundary";

export default function Promotions() {
  useScrollUp();

  return (
    <PageWitchMenu className={"landing"}>
      <ErrorBoundary>
        <Header />
      </ErrorBoundary>
      <ErrorBoundary>
        <PromotionsMain />
      </ErrorBoundary>
      <ErrorBoundary>
        <Footer />
      </ErrorBoundary>
    </PageWitchMenu>
  );
}

import React from "react";

export default function IconCardTopUp() {
  return (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8523 7.1025C13.0719 6.88283 13.4281 6.88283 13.6477 7.1025L15.1477 8.6025C15.3674 8.82217 15.3674 9.17832 15.1477 9.39799C14.9281 9.61766 14.5719 9.61766 14.3523 9.39799L13.8125 8.85824V12.0002C13.8125 12.3109 13.5607 12.5627 13.25 12.5627C12.9393 12.5627 12.6875 12.3109 12.6875 12.0002V8.85824L12.1477 9.39799C11.9281 9.61766 11.5719 9.61766 11.3523 9.39799C11.1326 9.17832 11.1326 8.82217 11.3523 8.6025L12.8523 7.1025Z"
        fill="#5280A3"
      />
      <path
        d="M6.5 0.000244141H9.5C12.3284 0.000244141 13.7426 0.000244141 14.6213 0.878924C15.254 1.51158 15.4311 2.42186 15.4807 3.93774H0.519287C0.568885 2.42186 0.746024 1.51158 1.37868 0.878924C2.25736 0.000244141 3.67157 0.000244141 6.5 0.000244141Z"
        fill="#5280A3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 12.0002H9.5C10.2919 12.0002 10.973 12.0002 11.5625 11.981V10.6774C11.1951 10.6365 10.8385 10.4752 10.5568 10.1935C9.89775 9.53448 9.89775 8.46601 10.5568 7.807L12.0568 6.307C12.7158 5.64799 13.7842 5.64799 14.4432 6.307L15.4953 7.35906C15.5 6.94624 15.5 6.49486 15.5 6.00024C15.5 5.6688 15.5 5.35677 15.4986 5.06274H0.501414C0.5 5.35677 0.5 5.6688 0.5 6.00024C0.5 8.82867 0.5 10.2429 1.37868 11.1216C2.25736 12.0002 3.67157 12.0002 6.5 12.0002ZM2.9375 9.00024C2.9375 8.68958 3.18934 8.43774 3.5 8.43774H6.5C6.81066 8.43774 7.0625 8.68958 7.0625 9.00024C7.0625 9.3109 6.81066 9.56274 6.5 9.56274H3.5C3.18934 9.56274 2.9375 9.3109 2.9375 9.00024ZM8.375 8.43774C8.06434 8.43774 7.8125 8.68958 7.8125 9.00024C7.8125 9.3109 8.06434 9.56274 8.375 9.56274H9.5C9.81066 9.56274 10.0625 9.3109 10.0625 9.00024C10.0625 8.68958 9.81066 8.43774 9.5 8.43774H8.375Z"
        fill="#5280A3"
      />
    </svg>
  );
}

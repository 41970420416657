import React from "react";

export default function IconSearch() {
  return (
    <svg
      width="20.000000"
      height="20.000000"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <path
        id="Vector"
        d="M13.99 13.99C14.32 13.66 14.84 13.66 15.17 13.99L18.92 17.74C19.24 18.06 19.24 18.59 18.92 18.92C18.59 19.24 18.07 19.24 17.74 18.92L13.99 15.17C13.66 14.84 13.66 14.31 13.99 13.99Z"
        fill="#6287A2"
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
      <path
        id="Vector"
        d="M0.83 9.16C0.83 4.56 4.56 0.83 9.16 0.83C13.76 0.83 17.49 4.56 17.49 9.16C17.49 13.76 13.76 17.5 9.16 17.5C4.56 17.5 0.83 13.76 0.83 9.16ZM9.16 2.5C5.48 2.5 2.49 5.48 2.49 9.16C2.49 12.84 5.48 15.83 9.16 15.83C12.84 15.83 15.83 12.84 15.83 9.16C15.83 5.48 12.84 2.5 9.16 2.5Z"
        fill="#6287A2"
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
    </svg>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import DefaultModal from "../../DefaultModal";
import WarningInfo from "../../../mui/warning_info/WarningInfo";
import CopyValue from "../../../mui/copy_value/CopyValue";

export default function ModalPaymentCard({ isOpen, closeModal, data }) {
  const { t } = useTranslation();
  const { invoiceNumber, cardNumber, recipient, bankName, sum, currency } = data || {};

  return (
    <DefaultModal isOpen={isOpen} closeModal={closeModal} classModal="payment-detail">
      <h2>
        {t("An application for replenishment has been created.", {
          number: invoiceNumber
        })}
      </h2>
      <p className="descriptions">
        {t("Make a translation. Clicking on the card number will copy it to the clipboard.")}
      </p>
      <section className="payment-detail__content">
        <h3>{t("Make a translation")}</h3>
        <div className="payment-detail__fields">
          <div className="payment-detail__fields__field">
            <span className="field__label">{t("Card number")}</span>
            <CopyValue className="field__value" value={cardNumber} />
          </div>
          <div className="payment-detail__fields__field">
            <span className="field__label">{t("Full name of the recipient")}</span>
            <CopyValue className="field__value" value={recipient} />
          </div>
          <div className="payment-detail__fields__field">
            <span className="field__label">{t("Beneficiary bank name")}</span>
            <CopyValue className="field__value" value={bankName} />
          </div>
          <div className="payment-detail__fields__field">
            <span className="field__label">{t("Amount")}</span>
            <CopyValue className="field__value" value={sum} textAfter={currency} />
          </div>
        </div>
        <WarningInfo>
          <p>
            {t(
              "This application can only be paid ONE time! To subsequently replenish your game balance, create a new application AGAIN! There is no need to leave a comment on the payment",
              { number: invoiceNumber }
            )}
          </p>
        </WarningInfo>
      </section>
    </DefaultModal>
  );
}

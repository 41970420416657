import React, { useMemo } from "react";
import DefaultModal from "../DefaultModal";
import IconWallet from "../../mui/JSXIcons/IconWallet";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/index.ts";
import "./show-balance.scss";

export default function ShowBalance({ isOpen, closeModal }) {
  const { t } = useTranslation();
  const currency = useAppSelector((state) => state.user.currency);
  const balanceAll = useAppSelector((state) => state.user.balance);
  const balances = useMemo(() => balanceAll[currency]?.balances, [balanceAll, currency]);

  const getBalanceByType = (type) => {
    const payoutVal = balances?.[type];
    const decimal = balanceAll[currency]?.info?.decimal;
    return payoutVal?.toFixed(decimal || 0);
  };

  const fields = [
    {
      className: "green",
      text: "Main balance",
      value: getBalanceByType("main")
    },
    {
      className: "",
      text: "Deposit wager",
      value: getBalanceByType("depositWager")
    },
    {
      className: "green",
      text: "Bonus balance",
      value: getBalanceByType("bonus")
    },
    {
      className: "",
      text: "Wager bonus",
      value: getBalanceByType("bonusWager")
    }
  ];

  return (
    <DefaultModal
      miniModal
      isOpen={isOpen}
      closeModal={closeModal}
      classModal="show-balance"
      overlayClassName={"show-balance__overlay"}>
      <h2>
        <IconWallet /> {t("Balance")}
      </h2>
      <div className="show-balance__fields">
        {fields.map((field, index) => (
          <div className="show-balance__fields__field" key={index}>
            <span className={`text ${field.className}`}>{t(field.text)}</span>
            <span className="value">
              <span className="number">{field.value}</span>
              <span className="currency">{currency}</span>
            </span>
          </div>
        ))}
      </div>
    </DefaultModal>
  );
}

import React, { useEffect } from "react";
import present from "./present.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "./top-up-balance-bonus.scss";
import { useAppSelector } from "../../../../store/index.ts";
import ShowContainer from "../../../mui/ShowContainer.jsx";
import { API_CASINO } from "../../../../api/casino";
import { useReadLocalStorage } from "usehooks-ts";
import {
  setCashback,
  setFirstDeposit,
  setForUser,
  setRegistration
} from "../../../../store/promotionsSlice.js";
import { TEST_MODE } from "../../../../store/enums.js";

export default function TopUpBonus() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const start_r = useReadLocalStorage("start_r");
  const currency = useSelector((state) => state.user.currency);
  const firstDep = useAppSelector((state) => state.promotions.firstDeposit);
  const cashback = useAppSelector((state) => state.promotions.cashback);
  const userId = useAppSelector((state) => state.user.userId);
  const forUserBonus = useAppSelector((state) => state.promotions.forUser);

  const bonusCashback = cashback?.values?.[currency]?.percent;
  const minCashback = cashback?.values?.[currency]?.minDeposit;
  const maxCashback = cashback?.values?.[currency]?.amount;

  const minFirst = firstDep?.values?.[currency]?.minDeposit;

  const min = firstDep?.toDeposit ? minFirst : minCashback;

  useEffect(() => {
    if (userId === forUserBonus) return;
    if (TEST_MODE) return;

    API_CASINO.getBonuses({ start_r })
      .then((e) => {
        const data = e.data;
        dispatch(setCashback(data.cashback));
        dispatch(setFirstDeposit(data.firstDeposit));
        dispatch(setRegistration(data.registration));
        dispatch(setForUser(userId));
      })
      .catch((e) => {
        console.log(e);
      });
  }, [userId, forUserBonus, start_r]);

  return (
    <ShowContainer condition={bonusCashback || maxCashback}>
      <div className="top-up-balance__bonus">
        <div className="top-up-balance__bonus__info">
          <span className="top-up-balance__bonus__info__item">
            <span>
              {t("Top up from")} {min}
            </span>
            <span className="currency">{currency}</span>
          </span>

          {firstDep?.toDeposit ? (
            <div className="top-up-balance__bonus__info__item column">
              <span>{t("Get double the amount!")}</span>
              <div className="bonus-container">
                <span className="bonus">
                  {t("to_max_cur", {
                    max: maxCashback,
                    cur: currency
                  })}
                </span>
              </div>
            </div>
          ) : (
            <div className="top-up-balance__bonus__info__item">
              <span>{t("Get an extra bonus!")}</span>
              <div className="bonus-container">
                <span className="bonus">+{bonusCashback}</span>
              </div>
            </div>
          )}
        </div>
        <img src={present} />
      </div>
    </ShowContainer>
  );
}

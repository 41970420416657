import React from "react";

export default function IconEye() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.12533 10C8.12533 8.96448 8.96479 8.12502 10.0003 8.12502C11.0359 8.12502 11.8753 8.96448 11.8753 10C11.8753 11.0356 11.0359 11.875 10.0003 11.875C8.96479 11.875 8.12533 11.0356 8.12533 10Z"
        fill="#2F4A72"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66699 10C1.66699 11.3662 2.02113 11.8262 2.7294 12.7464C4.14363 14.5837 6.51542 16.6667 10.0003 16.6667C13.4852 16.6667 15.857 14.5837 17.2712 12.7464C17.9795 11.8262 18.3337 11.3662 18.3337 10C18.3337 8.63387 17.9795 8.17379 17.2712 7.25363C15.857 5.41632 13.4852 3.33335 10.0003 3.33335C6.51542 3.33335 4.14363 5.41632 2.7294 7.25363C2.02113 8.17379 1.66699 8.63387 1.66699 10ZM10.0003 6.87502C8.27444 6.87502 6.87533 8.27413 6.87533 10C6.87533 11.7259 8.27444 13.125 10.0003 13.125C11.7262 13.125 13.1253 11.7259 13.1253 10C13.1253 8.27413 11.7262 6.87502 10.0003 6.87502Z"
        fill="#2F4A72"
      />
    </svg>
  );
}

import React from "react";

export default function IconPeople() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9173 6.25001C12.9173 7.86084 11.6115 9.16668 10.0007 9.16668C8.38982 9.16668 7.08398 7.86084 7.08398 6.25001C7.08398 4.63918 8.38982 3.33334 10.0007 3.33334C11.6115 3.33334 12.9173 4.63918 12.9173 6.25001Z"
        fill="url(#paint0_linear_4156_5816)"
      />
      <path
        d="M15 13.75C15 15.3608 12.7614 16.6667 10 16.6667C7.23858 16.6667 5 15.3608 5 13.75C5 12.1392 7.23858 10.8333 10 10.8333C12.7614 10.8333 15 12.1392 15 13.75Z"
        fill="url(#paint1_linear_4156_5816)"
      />
      <path
        d="M5.93439 4.16666C6.08227 4.16666 6.22665 4.18117 6.36606 4.20879C6.02643 4.81204 5.83268 5.50839 5.83268 6.24999C5.83268 6.97353 6.01711 7.654 6.34151 8.24696C6.20972 8.27151 6.07361 8.28438 5.93439 8.28438C4.75571 8.28438 3.8002 7.36259 3.8002 6.22552C3.8002 5.08844 4.75571 4.16666 5.93439 4.16666Z"
        fill="url(#paint2_linear_4156_5816)"
      />
      <path
        d="M4.5388 15.8216C4.06553 15.2559 3.74935 14.5617 3.74935 13.75C3.74935 12.9631 4.04649 12.2866 4.49583 11.7306C2.9086 11.8537 1.66602 12.7218 1.66602 13.7745C1.66602 14.837 2.93043 15.7115 4.5388 15.8216Z"
        fill="url(#paint3_linear_4156_5816)"
      />
      <path
        d="M14.1662 6.24999C14.1662 6.97354 13.9818 7.65401 13.6574 8.24696C13.7891 8.27152 13.9253 8.28438 14.0645 8.28438C15.2432 8.28438 16.1987 7.36259 16.1987 6.22552C16.1987 5.08844 15.2432 4.16666 14.0645 4.16666C13.9166 4.16666 13.7722 4.18117 13.6328 4.20879C13.9724 4.81204 14.1662 5.50839 14.1662 6.24999Z"
        fill="url(#paint4_linear_4156_5816)"
      />
      <path
        d="M15.4601 15.8216C17.0684 15.7115 18.3329 14.837 18.3329 13.7745C18.3329 12.7218 17.0903 11.8537 15.503 11.7306C15.9524 12.2866 16.2495 12.9631 16.2495 13.75C16.2495 14.5617 15.9333 15.2559 15.4601 15.8216Z"
        fill="url(#paint5_linear_4156_5816)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4156_5816"
          x1="8.12565"
          y1="2.91668"
          x2="10.0007"
          y2="9.16668"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2EA7FF" />
          <stop offset="1" stopColor="#0057FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4156_5816"
          x1="6.78571"
          y1="10.4167"
          x2="7.94248"
          y2="17.0267"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2EA7FF" />
          <stop offset="1" stopColor="#0057FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4156_5816"
          x1="2.50531"
          y1="3.33416"
          x2="9.0237"
          y2="12.0963"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2EA7FF" />
          <stop offset="1" stopColor="#0057FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4156_5816"
          x1="2.50531"
          y1="3.33416"
          x2="9.0237"
          y2="12.0963"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2EA7FF" />
          <stop offset="1" stopColor="#0057FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4156_5816"
          x1="14.4721"
          y1="3.33416"
          x2="20.9905"
          y2="12.0963"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2EA7FF" />
          <stop offset="1" stopColor="#0057FF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_4156_5816"
          x1="14.4721"
          y1="3.33416"
          x2="20.9905"
          y2="12.0963"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2EA7FF" />
          <stop offset="1" stopColor="#0057FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

import { useDispatch } from "react-redux";
import {
  setBonusGames,
  setExcludeDemoMode,
  setFavoriteGames,
  setGameId,
  setHitGames,
  setProviders,
  setProvidersGames
} from "../../store/gamesSlice";
import { setBanners, setCasinoField } from "../../store/commonSlice";
import { useEffect } from "react";
import { API_CASINO } from "../../api/casino";
import { DEBUG, ENUM_MODE_GAME, TEST_MODE } from "../../store/enums";
import { testCasinoInfo } from "../../store/testConst";
import { useLocalStorage } from "usehooks-ts";
import { getParamFromUrl } from "../../utils/utils";

// const MINUTES = 1 / 60;
const MINUTES = 0.1;

export default function useFillCasino() {
  const dispatch = useDispatch();
  const [startR, setStartR] = useLocalStorage("start_r", "");
  const [pbParams, setPbParams] = useLocalStorage("pb_params", "");
  const [casinoInfo, setCasinoInfo] = useLocalStorage("casinoInfo", {});

  const getMinutes = (mn) => mn * 60000;

  // getProviders
  const getProviders = async (
    gameCategories,
    gameHits,
    updateFavorite,
    favoritesId,
    excludeDemoMode
  ) => {
    const mode = getParamFromUrl("mode");
    const isDemo = mode === ENUM_MODE_GAME.demo;

    const providersGamesById = {};
    const validGameHitsById = {};
    const providerCount = {};
    const favoriteGames = [];
    const providersSet = new Set([]);

    // CRUTCH // сортировка должна быть ссервера
    let curIndx = 0;

    gameHits.forEach((game) => {
      if (isDemo && excludeDemoMode?.includes(game.providerName)) return;
      const id = game.game_id;
      validGameHitsById[id] = { ...game, curIndx };
      if (providersGamesById[id]) return;

      providersGamesById[id] = { ...game, curIndx };
      const update = game.favorites && updateFavorite;

      if (update || favoritesId?.includes(id)) {
        favoriteGames[id] = game;
      }
      curIndx++;
    });

    gameCategories.forEach((category) => {
      // fill games
      category.games.forEach((el) => {
        const id = el.game_id;
        const game = { ...el, groupId: category.id, curIndx: curIndx };

        if (el.hit) {
          if (!validGameHitsById[id]) {
            validGameHitsById[id] = { ...game, curIndx: curIndx };
          } else if (game.priority) {
            validGameHitsById[id] = { ...validGameHitsById[id], priority: game.priority };
          }
        }

        const update = game.favorites && updateFavorite;
        if (update || favoritesId?.includes(id)) {
          favoriteGames[id] = game;
        }

        if (providersGamesById[id]) return;
        if (isDemo && excludeDemoMode?.includes(el.provider)) return;
        providersGamesById[id] = game;

        curIndx++;
      });
    });

    // у кого приоритет выше тот первее
    const providersGames = Object.values(providersGamesById);
    const validGameHits = Object.values(validGameHitsById);

    providersGames.sort((l, r) => l.curIndx - r.curIndx);
    providersGames.sort((l, r) => l.priority - r.priority);
    validGameHits.sort((l, r) => l.curIndx - r.curIndx);
    validGameHits.sort((l, r) => l.priority - r.priority);

    dispatch(setGameId(providersGamesById));
    dispatch(setProvidersGames(providersGames));
    dispatch(setExcludeDemoMode(excludeDemoMode));
    dispatch(setFavoriteGames(favoriteGames.filter((el) => el)));
    dispatch(setHitGames(validGameHits));

    // providers
    providersGames.forEach((el) => {
      const provider = el.provider;
      providerCount[provider] = (providerCount[provider] || 0) + 1;
      providersSet.add(provider);
    });

    const providers = [...providersSet].map((provider) => ({
      name: provider,
      count: providerCount[provider] || 0
    }));
    dispatch(setProviders(providers));
  };

  // fillCasinoInfo - заполнение стора инфой полученный от сервера при инициализации
  const fillCasinoInfo = async (data, updateFavorite, favoritesId) => {
    const gameCategories = data.gameCategories;
    const bonusGames = data.bonusGames;
    const banners = [...data.banners];
    getProviders(gameCategories, data.gameHits, updateFavorite, favoritesId, data.excludeFromDemo);

    dispatch(setCasinoField(data));
    dispatch(setBonusGames(bonusGames));
    dispatch(setBanners(banners.sort((l, r) => l.position - r.position)));
  };

  // getStartR - получение start_r
  const getStartR = () => {
    if (startR) return startR;
    const tempStartR = getParamFromUrl("start");
    if (tempStartR) setStartR(tempStartR);
    return tempStartR;
  };

  // getPbParams - получение pb_params
  const getPbParams = () => {
    if (pbParams) return pbParams;

    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("start");
    queryParams.delete("mode");
    queryParams.delete("sessionId");

    const tempBpParams = queryParams.toString();
    const base64EncodedQueryString = btoa(tempBpParams);
    if (tempBpParams) setPbParams(base64EncodedQueryString);
    return tempBpParams;
  };

  useEffect(() => {
    const start_r = getStartR();
    const pb_params = getPbParams();

    if (TEST_MODE) {
      const favoritesId = JSON.parse(localStorage.getItem("favoritesId")) || [];
      fillCasinoInfo(testCasinoInfo, false, favoritesId);
      return;
    }

    if (+casinoInfo?.time + getMinutes(MINUTES) > +new Date()) {
      const favoritesId = JSON.parse(localStorage.getItem("favoritesId")) || [];
      fillCasinoInfo(casinoInfo.info, false, favoritesId);
      return;
    }

    API_CASINO.info({ start_r, pb_params })
      .then((e) => {
        setCasinoInfo({ time: +new Date(), info: e.data });
        fillCasinoInfo(e.data, true);

        if (DEBUG && e.data.error) console.log("error casino info", e.data?.error);
      })
      .catch((e) => {
        if (DEBUG) console.log("catch casino info ", e);
      });
  }, []);
}

import React from "react";

export default function IconSupport() {
  return (
    <svg
      width="16.666016"
      height="16.666687"
      viewBox="0 0 16.666 16.6667"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <path
        id="Vector"
        d="M0 8.43C0 3.77 3.73 0 8.33 0C12.93 0 16.66 3.77 16.66 8.43L16.66 12.8C16.66 13.48 16.66 13.95 16.54 14.36C16.31 15.16 15.75 15.82 15 16.18C14.62 16.37 14.17 16.44 13.5 16.54L13.39 16.56C13.16 16.6 12.96 16.63 12.79 16.65C12.61 16.67 12.44 16.67 12.25 16.63C11.66 16.52 11.16 16.09 10.95 15.51C10.89 15.33 10.87 15.15 10.86 14.98C10.85 14.81 10.85 14.6 10.85 14.36L10.85 10.88C10.85 10.53 10.85 10.22 10.93 9.96C11.11 9.36 11.6 8.91 12.2 8.77C12.47 8.71 12.76 8.73 13.11 8.76L13.19 8.77L13.28 8.77C13.94 8.83 14.38 8.86 14.76 9C15.03 9.1 15.28 9.24 15.5 9.41L15.5 8.43C15.5 4.42 12.29 1.17 8.33 1.17C4.37 1.17 1.16 4.42 1.16 8.43L1.16 9.41C1.38 9.24 1.63 9.1 1.89 9C2.27 8.86 2.72 8.83 3.37 8.77L3.46 8.77L3.54 8.76C3.89 8.73 4.19 8.71 4.46 8.77C5.06 8.91 5.54 9.36 5.73 9.96C5.81 10.22 5.81 10.53 5.81 10.88L5.81 14.36C5.81 14.6 5.81 14.81 5.8 14.98C5.79 15.15 5.77 15.33 5.7 15.51C5.49 16.09 5 16.52 4.4 16.63C4.22 16.67 4.04 16.67 3.87 16.65C3.7 16.63 3.5 16.6 3.26 16.56L3.16 16.54C2.49 16.44 2.03 16.37 1.65 16.18C0.91 15.82 0.35 15.16 0.11 14.36C0.02 14.02 0 13.64 0 13.14L0 12.98L0 8.43Z"
        fill="#6287A2"
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
    </svg>
  );
}

import React from "react";

export default function IconPlay() {
  return (
    <svg
      width="20.000000"
      height="15.000000"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <path
        id="Vector"
        d="M8.66 1.13L8.16 0.77C7.44 0.27 6.59 0 5.71 0L5.1 0C4.69 0 4.29 0.02 3.9 0.16C1.52 1 -0.12 4.09 0 11.1C0.02 12.51 0.35 14.07 1.63 14.68C2.03 14.87 2.49 15 3.02 15C3.66 15 4.16 14.81 4.55 14.56C4.96 14.29 5.32 13.94 5.68 13.59C6.13 13.16 6.57 12.73 7.11 12.46C7.69 12.15 8.34 12 9 12L10.99 12C11.65 12 12.3 12.15 12.88 12.46C13.42 12.73 13.86 13.16 14.31 13.59C14.67 13.94 15.03 14.29 15.44 14.56C15.83 14.81 16.33 15 16.97 15C17.5 15 17.96 14.87 18.36 14.68C19.64 14.07 19.97 12.51 19.99 11.1C20.11 4.09 18.47 1 16.09 0.16C15.7 0.02 15.3 0 14.89 0L14.28 0C13.4 0 12.55 0.27 11.83 0.77L11.33 1.13C10.94 1.41 10.47 1.55 10 1.55C9.52 1.55 9.05 1.41 8.66 1.13ZM14.75 4C15.16 4 15.5 4.33 15.5 4.75C15.5 5.16 15.16 5.5 14.75 5.5C14.33 5.5 14 5.16 14 4.75C14 4.33 14.33 4 14.75 4ZM5.5 4.25C5.91 4.25 6.25 4.58 6.25 5L6.25 5.75L7 5.75C7.41 5.75 7.75 6.08 7.75 6.5C7.75 6.91 7.41 7.25 7 7.25L6.25 7.25L6.25 8C6.25 8.41 5.91 8.75 5.5 8.75C5.08 8.75 4.75 8.41 4.75 8L4.75 7.25L4 7.25C3.58 7.25 3.25 6.91 3.25 6.5C3.25 6.08 3.58 5.75 4 5.75L4.75 5.75L4.75 5C4.75 4.58 5.08 4.25 5.5 4.25ZM17 6.25C17 6.66 16.66 7 16.25 7C15.83 7 15.5 6.66 15.5 6.25C15.5 5.83 15.83 5.5 16.25 5.5C16.66 5.5 17 5.83 17 6.25ZM13.25 7C13.66 7 14 6.66 14 6.25C14 5.83 13.66 5.5 13.25 5.5C12.83 5.5 12.5 5.83 12.5 6.25C12.5 6.66 12.83 7 13.25 7ZM15.5 7.75C15.5 7.33 15.16 7 14.75 7C14.33 7 14 7.33 14 7.75C14 8.16 14.33 8.5 14.75 8.5C15.16 8.5 15.5 8.16 15.5 7.75Z"
        fill="#20A1FF"
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
    </svg>
  );
}

import { useTimeout } from "usehooks-ts";
import useNavigateWithSearch from "./useNavigateWitchSearch";
import { useSelector } from "react-redux";

export default function useCheckEmptyPage({ isEmpry }) {
  const navigate = useNavigateWithSearch();
  const bonusGames = useSelector((state) => state.games.bonusGames);
  const games = useSelector((state) => state.games.providersGames);

  useTimeout(() => {
    if (bonusGames.length || games.length) {
      if (!isEmpry) navigate("/");
    }
  }, 2000);

  return {};
}

import React from "react";
import CusButton from "../custom_button/CusButton";
import "./success-cancel-buttons.scss";

export default function SuccessCancelButtons({
  successText = "Send",
  cancelText = "Cancel",
  className = "",
  btnLoading,
  setBtnLoading,
  closeModal
}) {
  return (
    <div className={`success-cancel-buttons ${className}`}>
      <CusButton
        text={successText}
        type={"submit"}
        color="gradient-blue"
        btnLoading={btnLoading}
        setBtnLoading={setBtnLoading}
      />
      <CusButton text={cancelText} onClick={closeModal} />
    </div>
  );
}

import React from "react";

export default function IconMail() {
  return (
    <svg
      width="16.666992"
      height="15.000000"
      viewBox="0 0 16.667 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <path
        id="Subtract"
        d="M3.33 0C1.49 0 0 1.49 0 3.33L0 11.66C0 13.5 1.49 15 3.33 15L13.33 15C15.17 15 16.66 13.5 16.66 11.66L16.66 3.33C16.66 1.49 15.17 0 13.33 0L3.33 0ZM3.67 3.64C3.39 3.45 3 3.53 2.81 3.81C2.62 4.1 2.69 4.49 2.98 4.68L6.13 6.78C7.46 7.67 9.19 7.67 10.52 6.78L13.67 4.68C13.96 4.49 14.04 4.1 13.85 3.81C13.66 3.53 13.27 3.45 12.98 3.64L9.83 5.74C8.92 6.35 7.74 6.35 6.83 5.74L3.67 3.64Z"
        fill="rgb(82, 128, 163)"
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
    </svg>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "usehooks-ts";
import { setTypeLogin } from "../../../store/siteSlice";
import {
  DEBUG,
  ENUM_ACCOUNT_REGISTER,
  ENUM_LCS_NAME,
  ENUM_LOGIN,
  SITE_KEY
} from "../../../store/enums";
import { API_USER } from "../../../api/user";
import { errorToast, successToast } from "../../mui/Toaster";
import { setInfo, setUserId } from "../../../store/userSlice";
import ShowContainer from "../../mui/ShowContainer";
import AuthTg from "../../mui/auth_tg/AuthTg";
import Segmented from "rc-segmented";
import CusForm from "../../mui/CusForm";
import CusInput from "../../mui/custom_input/CusInput";
import IconMail from "../../mui/JSXIcons/IconMail";
import ReCAPTCHA from "react-google-recaptcha";
import CusButton from "../../mui/custom_button/CusButton";
import CheckboxInfo from "../../mui/checkbox_info/CheckboxInfo";
import { Link } from "react-router-dom";
import IconLock from "../../mui/JSXIcons/IconLook";

export default function LoginFields({ closeModal, dataLogin, setForgotPas }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [startR] = useLocalStorage("start_r", "");
  const [pbParams] = useLocalStorage("pb_params ", "");
  const lang = useSelector((state) => state.common.lang);
  const isTgBot = useSelector((state) => state.common.isTgBot);
  const typeLogin =
    useSelector((state) => state.site.modals?.data?.login?.type) || ENUM_LOGIN.register;
  const botName = useSelector((state) => state.common.botName);
  const telegramWidget = useSelector((state) => state.common.telegramWidget);

  const [, setAlreadyAccount] = useLocalStorage(ENUM_LCS_NAME.alreadyAccount);

  const [disableBtn, setDisableBtn] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const captchaRef = useRef();
  const isTypeLogin = typeLogin == ENUM_LOGIN.login;

  const changeTypeLogin = (type) => dispatch(setTypeLogin(type));

  const typeOptions = [
    { label: t("Login"), value: ENUM_LOGIN.login },
    { label: t("Registration"), value: ENUM_LOGIN.register }
  ];

  const titleByType = {
    [ENUM_LOGIN.login]: t("Login"),
    [ENUM_LOGIN.register]: t("Register")
  };

  const subTitleByType = {
    [ENUM_LOGIN.login]: t("Login to your account via Email or Telegram"),
    [ENUM_LOGIN.register]: t("Create your account now")
  };

  const btnTextByType = {
    [ENUM_LOGIN.login]: t("Login"),
    [ENUM_LOGIN.register]: t("Register")
  };

  const tgTitleByType = {
    [ENUM_LOGIN.login]: t("Login via Telegram"),
    [ENUM_LOGIN.register]: t("Registration via Telegram")
  };

  const onComeIn = async (e, field) => {
    e.preventDefault();
    console.log("typeLogin", typeLogin);
    setBtnLoading(true);

    const info = {
      email: field.email,
      password: field.password,
      captcha: captchaRef.current?.getValue?.()
    };

    if (typeLogin === ENUM_LOGIN.register) {
      const domain = window.location.hostname;

      info.domain = domain;
      info.start_r = startR;
      info.pb_params = pbParams;
    }

    setDisableBtn(true);
    await API_USER.login
      .email(info)
      .then((e) => {
        const data = e.data;
        const error = data.error;
        if (DEBUG) console.log("loginEmail res", e);
        if (error) return errorToast(error);
        dispatch(setUserId(data.user_id));
        successToast(t("Successfully"));
        closeModal(true);
        dataLogin?.cb?.();

        dispatch(
          setInfo({
            nickname: field.email,
            email: field.email,
            register: ENUM_ACCOUNT_REGISTER.email
          })
        );
        setAlreadyAccount(true);
      })
      .catch((e) => {
        if (DEBUG) console.log("catch loginEmail", e);
        errorToast(e || "unknown error");
      });
    captchaRef.current?.reset();
    setDisableBtn(false);
    setBtnLoading(false);
  };

  useEffect(() => {
    captchaRef.current?.reset();
  }, []);

  return (
    <div className="login-fields">
      <h2>{titleByType[typeLogin]}</h2>
      <h3>{subTitleByType[typeLogin]}</h3>

      <ShowContainer condition={!isTgBot}>
        <Segmented
          className="login__segment custom-segment"
          value={typeLogin}
          options={typeOptions}
          onChange={changeTypeLogin}
        />
        <CusForm onSubmit={onComeIn} className={"login__form"}>
          <CusInput
            name={"email"}
            placeholder={"Email"}
            required
            iconJSXstart={<IconMail />}
            dataType={"email"}
          />
          <CusInput
            name={"password"}
            placeholder={"Password"}
            type={"password"}
            required
            minLength={8}
            iconJSXstart={<IconLock />}
          />
          <ReCAPTCHA ref={captchaRef} hl={lang} sitekey={SITE_KEY} />
          <ShowContainer condition={!isTypeLogin}>
            <CheckboxInfo
              id={"login-agree"}
              text={
                <>
                  <span>{t("I agree to the")}</span>{" "}
                  <Link target="_blank" to={"/terms"}>
                    {t("User Agreement")}
                  </Link>{" "}
                  <span>{t("and confirm that I am over 18 years old.")}</span>
                </>
              }
            />
          </ShowContainer>
          <CusButton
            className="login-submit"
            text={btnTextByType[typeLogin]}
            type={"submit"}
            disable={disableBtn}
            color="gradient-blue"
            btnLoading={btnLoading}
            setBtnLoading={setBtnLoading}
          />
        </CusForm>
        <ShowContainer condition={isTypeLogin}>
          <button className="forgot-pas" onClick={() => setForgotPas(true)}>
            {t("Forgot your password?")}
          </button>
        </ShowContainer>
        <ShowContainer condition={telegramWidget && botName}>
          <span className="tg__title">
            <div className="tg__title__left" />
            <span className="tg__title__text">{tgTitleByType[typeLogin]}</span>
            <div className="tg__title__right" />
          </span>
          <div className="login__auth">
            <AuthTg
              successCb={() => {
                dataLogin?.cb?.();
              }}
              closeModal={closeModal}
            />
          </div>
        </ShowContainer>
      </ShowContainer>
    </div>
  );
}

import React, { useState } from "react";
import "./avatar-popup.scss";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../store/index.ts";
import IconArrow from "../../../../mui/JSXIcons/IconArrow.jsx";
import { useTranslation } from "react-i18next";
import ShowContainer from "../../../../mui/ShowContainer.jsx";
import { setModalProfile } from "../../../../../store/siteSlice.js";
import { dispatchCusEvent } from "../../../../../utils/utils.js";
import { ENUM_COMMON_EVENT } from "../../../../../hooks/common/useListener.js";
import { ENUM_ACCOUNT_REGISTER } from "../../../../../store/enums.js";
import AvatarImg from "./AvatarImg.jsx";

export default function AvatarPopup() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const nickname = useAppSelector((state) => state.user.info.nickname) || "Nickname";
  const isSmallDesktop = useAppSelector((state) => state.deviceSize.isSmallDesktop);
  const registerType = useAppSelector((state) => state.user.info.register);
  const isRegisterEmail = registerType === ENUM_ACCOUNT_REGISTER.email;

  const showNick = nickname.includes("@") ? nickname : `@${nickname}`;

  const [openPopup, setOpenPopup] = useState(false);

  const arrowClass = openPopup ? "rotate180" : "";
  const classPopup = isSmallDesktop ? "small-desktop" : "";

  return (
    <div className={`avatar-popup ${classPopup}`}>
      <button
        className="avatar-popup__person"
        // onMouseLeave={() => setOpenPopup(false)}
        // onMouseEnter={() => setOpenPopup(true)}
        onClick={() => setOpenPopup((prev) => !prev)}>
        <AvatarImg />
        <ShowContainer condition={!isSmallDesktop}>
          <span className="avatar-popup__person__name">{showNick}</span>
          <IconArrow className={arrowClass} />
        </ShowContainer>
      </button>
      <ShowContainer condition={openPopup}>
        <ul className="avatar-popup__popup">
          <ShowContainer condition={isSmallDesktop}>
            <li className="nickname">
              <span className="avatar-popup__person__name">{showNick}</span>
            </li>
          </ShowContainer>
          <ShowContainer condition={isRegisterEmail}>
            <li>
              <button onClick={() => dispatch(setModalProfile(true))}>{t("Profile")}</button>
            </li>
          </ShowContainer>
          <li>
            <button onClick={() => dispatchCusEvent(ENUM_COMMON_EVENT.exit)}>{t("Exit")}</button>
          </li>
        </ul>
      </ShowContainer>
    </div>
  );
}

import React from "react";
import * as ReactDOM from "react-dom/client";
import "rc-segmented/assets/index.css";
import "react-toastify/dist/ReactToastify.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./style/base.scss";
import "./style/vars.scss";

import reportWebVitals from "./reportWebVitals";
import "./i18n";
import i18next from "i18next";
import { initReactI18next, I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { store } from "./store/index.ts";
import Router from "./Router";

// Install modules
const i18n = i18next.use(initReactI18next);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <React.StrictMode>
        <Router />
      </React.StrictMode>
    </I18nextProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDataLogin, setModalLogin } from "../store/siteSlice";
import { ENUM_LCS_NAME, ENUM_LOGIN } from "../store/enums";
import { useReadLocalStorage } from "usehooks-ts";

export default function useLoginAction() {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.user.isAuth);
  const alreadyAccount = useReadLocalStorage(ENUM_LCS_NAME.alreadyAccount);

  const onLoginAction = useCallback(
    (cb) => {
      if (!isAuth) {
        const type = alreadyAccount ? ENUM_LOGIN.login : ENUM_LOGIN.register;
        dispatch(setModalLogin(true));
        dispatch(setDataLogin({ cb, type }));
        return;
      }

      cb();
    },
    [isAuth]
  );

  return { onLoginAction };
}

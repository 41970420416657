import React from "react";

export default function IconReload() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.07868 5.06901C8.87402 1.27902 15.0437 1.31932 18.8622 5.13787C22.6824 8.95806 22.7211 15.1314 18.9262 18.9263C15.1312 22.7212 8.95793 22.6825 5.13774 18.8623C2.87389 16.5985 1.93904 13.51 2.34047 10.5813C2.39672 10.1709 2.775 9.88386 3.18537 9.94011C3.59575 9.99636 3.88282 10.3746 3.82658 10.785C3.4866 13.2653 4.27782 15.8811 6.1984 17.8017C9.44288 21.0461 14.6664 21.0647 17.8655 17.8656C21.0646 14.6665 21.046 9.44301 17.8015 6.19853C14.5587 2.95571 9.33889 2.93548 6.13935 6.12966L6.88705 6.13342C7.30126 6.1355 7.63535 6.47297 7.63327 6.88718C7.63119 7.30139 7.29372 7.63548 6.87951 7.6334L4.33396 7.62061C3.92269 7.61854 3.58981 7.28565 3.58774 6.87439L3.57495 4.32884C3.57286 3.91463 3.90696 3.57716 4.32117 3.57508C4.73538 3.57299 5.07285 3.90709 5.07493 4.3213L5.07868 5.06901Z"
        fill="#6287A2"
      />
    </svg>
  );
}

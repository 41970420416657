import React from "react";
import ShowContainer from "../../mui/ShowContainer";
import IconTg from "../../mui/JSXIcons/IconTg";
import { useTranslation } from "react-i18next";
import { useReadLocalStorage } from "usehooks-ts";
import { useAppSelector } from "../../../store/index.ts";

export default function TgLinksFooter() {
  const { t } = useTranslation();
  const start_r = useReadLocalStorage("start_r");
  const botName = useAppSelector((state) => state.common.botName);
  const supportChat = useAppSelector((state) => state.links.supportChat);
  const isTgBot = useAppSelector((state) => state.common.isTgBot);

  const playTg = start_r ? `https://t.me/${botName}?start_r=${start_r}` : `https://t.me/${botName}`;

  const supportTg = start_r
    ? `https://t.me/${supportChat}?start_r=${start_r}`
    : `https://t.me/${supportChat}`;

  return (
    <div className="footer__content__three__tg">
      <ShowContainer condition={!isTgBot}>
        <ShowContainer condition={botName}>
          <a className="footer__play-tg" target="_blank" href={playTg} rel="noreferrer">
            <span className="footer__play-tg__title">{t("Play as a bot:")}</span>
            <span className="footer__play-tg__name">
              <IconTg />@{botName}
            </span>
          </a>
        </ShowContainer>

        <ShowContainer condition={supportChat}>
          <a className="footer__support-tg" target="_blank" href={supportTg} rel="noreferrer">
            <span className="footer__support-tg__title">{t("Support: ")}</span>
            <span className="footer__support-tg__name">
              <IconTg />@{supportChat}
            </span>
          </a>
        </ShowContainer>
      </ShowContainer>
    </div>
  );
}

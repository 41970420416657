import React from "react";
import { useSelector } from "react-redux";

export default function TitleUserColor(props) {
  // const hColor = useSelector((state) => state.common.headerColor);
  const hColor = undefined;

  const level = props.level || 7;
  const title = props.title || "";

  const style = hColor
    ? {
        background: `linear-gradient(180deg, ${hColor}, #fff) text`,
        // background: `${hColor} text`,
        WebkitTextFillColor: "transparent"
      }
    : undefined;

  if (level === 1)
    return (
      <h1 style={style} {...props}>
        {title}
      </h1>
    );
  if (level === 2)
    return (
      <h2 style={style} {...props}>
        {title}
      </h2>
    );
  if (level === 3)
    return (
      <h3 style={style} {...props}>
        {title}
      </h3>
    );
  if (level === 4)
    return (
      <h4 style={style} {...props}>
        {title}
      </h4>
    );
  if (level === 5)
    return (
      <h5 style={style} {...props}>
        {title}
      </h5>
    );
  if (level === 6)
    return (
      <h6 style={style} {...props}>
        {title}
      </h6>
    );
  if (level === 7)
    return (
      <span style={style} {...props}>
        {title}
      </span>
    );

  return null;
}

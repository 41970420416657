export const TEST_MODE =
  window.location.hostname === "localhost" ||
  window.location.hostname.startsWith("192.168.") ||
  window.location.hostname.startsWith("10.");

export const API_SERVER = "devf.securebet.xyz";
export const SITE_KEY = "6LdFOfMpAAAAAPvA5gKu67QgmlU5ScIop0LHLI-8";
export const DEBUG = false;

//var

export const MOBILE_SIZE = 670;
export const EXTRA_SMALL = 576;
export const SIZE_TABLET = 780;
export const SIZE_LSM = 950;
export const SIZE_SMALL_DESKTOP = 1024;
export const SIZE_MENU_ABSOLUTE = 1220;
export const SIZE_BIG_DESKTOP = 1440;
export const SIZE_2K = 2048;
export const SIZE_4K = 4096;

// common
export const ENUM_MODE_GAME = {
  real: "real",
  demo: "demo"
};

export const ENUM_BOT_THEME = {
  telegram: "telegram"
};

export const ENUM_MADE_ON = {
  tgBot: "bot",
  site: "site"
};

export const ENUM_GAME_TYPE = {
  lobby: "lobby",
  hit: "hit",
  history: "history",
  favorites: "favorites",
  bonus: "bonus"
};

export const ENUM_LOGIN = {
  login: "login",
  register: "register"
};

export const checkUrlBotTg = () => window.location.search.search("bot_theme=telegram") >= 0;

// deviceSize

export const EnumDeviceModes = {
  mobile: "mobile",
  desktop: "desktop"
};
export const ENUM_DEVICE_ORIENTATION = {
  portrait: "portrait",
  landscape: "landscape"
};

//  user

export const ENUM_CURRENCY = {
  USDT: "USDT",
  RUB: "RUB",
  EUR: "EUR",
  UZS: "UZS"
};

export const ENUM_ACCOUNT_REGISTER = {
  email: "email",
  telegram: "telegram",
  noRegister: "no_register"
};

export const defaultUser = {
  firstName: "",
  lastName: "",
  photoUrl: "",
  username: "",
  email: "",
  register: ENUM_ACCOUNT_REGISTER.noRegister
};

// site

export const ENUM_BALANCE_TYPE = {
  fiat: "fiat",
  crypto: "crypto"
};

// other
export const ONE_PORTION = 6;
export const GAME_COUNT = ONE_PORTION * 4;

// local storage

export const ENUM_LCS_NAME = {
  alreadyAccount: "alreadyAccount"
};

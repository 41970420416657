import React from "react";
import "./copy-value.scss";
import IconCopy from "../JSXIcons/IconCopy";
import { successToast } from "../Toaster";
import { useTranslation } from "react-i18next";

export default function CopyValue({ value, textAfter, className = "" }) {
  const { t } = useTranslation();

  const onCopyClick = () => {
    navigator.clipboard.writeText(value);
    successToast(t("successfully copied to clipboard"));
  };

  return (
    <span className={`${className} copy-value`}>
      {value} <span className="text-after">{textAfter}</span>
      <button onClick={onCopyClick}>
        <IconCopy />
      </button>
    </span>
  );
}

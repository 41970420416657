import { useSelector } from "react-redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import commonSlice from "./commonSlice";
import gamesSlice from "./gamesSlice";
import siteSlice from "./siteSlice";
import userSlice from "./userSlice";
import deviceSize from "./deviceSizeSlice";
import promotionsSlice from "./promotionsSlice";
import linksSlice from "./linksSlice";

const rootReduces = combineReducers({
  common: commonSlice,
  user: userSlice,
  games: gamesSlice,
  site: siteSlice,
  deviceSize: deviceSize,
  promotions: promotionsSlice,
  links: linksSlice
});

export const store = configureStore({ reducer: rootReduces });

type RootState = ReturnType<typeof rootReduces>;

export const useAppSelector = useSelector.withTypes<RootState>();

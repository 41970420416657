import React from "react";

export default function IconAddress() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5844 6.66961C17.5372 6.66663 17.4858 6.66665 17.4325 6.66667L17.4192 6.66667H15.3287C13.6057 6.66667 12.1313 8.02297 12.1313 9.79167C12.1313 11.5604 13.6057 12.9167 15.3287 12.9167H17.4192L17.4325 12.9167C17.4858 12.9167 17.5372 12.9167 17.5844 12.9137C18.2843 12.8695 18.9033 12.3218 18.9553 11.5568C18.9587 11.5067 18.9587 11.4526 18.9587 11.4025L18.9586 11.3889V8.19445L18.9587 8.18085C18.9587 8.13073 18.9587 8.07666 18.9553 8.0265C18.9033 7.2615 18.2843 6.71381 17.5844 6.66961ZM15.1434 10.625C15.587 10.625 15.9466 10.2519 15.9466 9.79167C15.9466 9.33143 15.587 8.95833 15.1434 8.95833C14.6998 8.95833 14.3402 9.33143 14.3402 9.79167C14.3402 10.2519 14.6998 10.625 15.1434 10.625Z"
        fill="#5280A3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4319 14.1667C17.5562 14.1635 17.6502 14.2785 17.6165 14.3982C17.4492 14.9919 17.1837 15.4979 16.7576 15.924C16.134 16.5476 15.3432 16.8244 14.3662 16.9557C13.4168 17.0834 12.2038 17.0833 10.6723 17.0833H8.91165C7.38018 17.0833 6.16715 17.0834 5.21781 16.9557C4.24079 16.8244 3.45 16.5476 2.82636 15.924C2.20273 15.3003 1.92596 14.5095 1.79461 13.5325C1.66697 12.5832 1.66698 11.3701 1.66699 9.83868V9.74465C1.66698 8.21319 1.66697 7.00016 1.7946 6.05082C1.92596 5.0738 2.20273 4.28301 2.82636 3.65937C3.45 3.03573 4.24079 2.75897 5.21781 2.62761C6.16715 2.49998 7.38018 2.49999 8.91164 2.5L10.6723 2.5C12.2038 2.49999 13.4168 2.49998 14.3662 2.62761C15.3432 2.75897 16.134 3.03573 16.7576 3.65937C17.1837 4.08544 17.4492 4.59145 17.6165 5.18515C17.6502 5.3048 17.5562 5.41989 17.4319 5.41667L15.3287 5.41667C12.9648 5.41667 10.8813 7.28409 10.8813 9.79167C10.8813 12.2993 12.9648 14.1667 15.3287 14.1667L17.4319 14.1667ZM5.83366 12.9167C5.48848 12.9167 5.20866 12.6368 5.20866 12.2917V7.29167C5.20866 6.94649 5.48848 6.66667 5.83366 6.66667C6.17884 6.66667 6.45866 6.94649 6.45866 7.29167V12.2917C6.45866 12.6368 6.17884 12.9167 5.83366 12.9167Z"
        fill="#5280A3"
      />
    </svg>
  );
}

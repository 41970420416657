import React from "react";
import "./default-modal.scss";
import Modal from "react-modal";
import CloseModal from "../mui/close_modal/CloseModal";
import ShowContainer from "../mui/ShowContainer";
import ErrorBoundary from "../mui/error_boundary/ErrorBoundary";

export default function DefaultModal({
  isOpen,
  closeModal,
  classModal = "",
  children,
  hideClose,
  miniModal,
  overlayClassName
}) {
  const modalMiniClass = miniModal ? "mini" : "";

  return (
    <Modal
      ariaHideApp={false}
      className={`default-modal ${modalMiniClass}  ${classModal}`}
      isOpen={isOpen}
      onRequestClose={closeModal}
      overlayClassName={overlayClassName}
      shouldFocusAfterRender={false}>
      <ShowContainer condition={!hideClose}>
        <CloseModal close={closeModal} />
      </ShowContainer>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Modal>
  );
}

import { useNavigate, useLocation } from "react-router-dom";

const useNavigateWithSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (path, options) => {
    const searchParams = location.search;
    navigate(`${path}${searchParams}`, options);
  };
};

export default useNavigateWithSearch;

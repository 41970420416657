import React from "react";

export default function IconCard() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.667 3.3335H8.33366C5.19096 3.3335 3.61961 3.3335 2.6433 4.30981C1.94035 5.01276 1.74353 6.02418 1.68842 7.7085H18.3122C18.2571 6.02418 18.0603 5.01276 17.3573 4.30981C16.381 3.3335 14.8097 3.3335 11.667 3.3335Z"
        fill="#5280A3"
      />
      <path
        d="M8.33366 16.6668H11.667C14.8097 16.6668 16.381 16.6668 17.3573 15.6905C18.3337 14.7142 18.3337 13.1429 18.3337 10.0002C18.3337 9.63189 18.3337 9.28519 18.3321 8.9585H1.66856C1.66699 9.28519 1.66699 9.63189 1.66699 10.0002C1.66699 13.1429 1.66699 14.7142 2.6433 15.6905C3.61961 16.6668 5.19096 16.6668 8.33366 16.6668Z"
        fill="#5280A3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.37533 13.3335C4.37533 12.9883 4.65515 12.7085 5.00033 12.7085H8.33366C8.67884 12.7085 8.95866 12.9883 8.95866 13.3335C8.95866 13.6787 8.67884 13.9585 8.33366 13.9585H5.00033C4.65515 13.9585 4.37533 13.6787 4.37533 13.3335Z"
        fill="#101D30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.79199 13.3335C9.79199 12.9883 10.0718 12.7085 10.417 12.7085H11.667C12.0122 12.7085 12.292 12.9883 12.292 13.3335C12.292 13.6787 12.0122 13.9585 11.667 13.9585H10.417C10.0718 13.9585 9.79199 13.6787 9.79199 13.3335Z"
        fill="#101D30"
      />
    </svg>
  );
}

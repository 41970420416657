import { useEffect } from "react";
import { useAppSelector } from "../../store/index.ts";
import { useDispatch, useSelector } from "react-redux";
import {
  setChannelUrl,
  setChatUrl,
  setLinkLang,
  setReviewUrl,
  setSupportChat,
  setTgPostUrl
} from "../../store/linksSlice.js";

export default function useFillLinks() {
  const dispatch = useDispatch();
  const langLink = useAppSelector((state) => state.common.langLink);
  const language = useAppSelector((state) => state.common.lang);
  const supportChatDefault = useAppSelector((state) => state.common.supportChat);
  const channelUrlDefault = useSelector((state) => state.common.channelUrl);
  const reviewUrlDefault = useSelector((state) => state.common.reviewUrl);
  const chatUrlDefault = useSelector((state) => state.common.chatUrl);
  const tgPostUrlDefault = useSelector((state) => state.common.tgPostUrl);

  useEffect(() => {
    const links = langLink[language];

    if (!links) return;

    const supportChat = links?.supportChat || supportChatDefault;
    const channelUrl = links?.channelUrl || channelUrlDefault;
    const reviewUrl = links?.reviewUrl || reviewUrlDefault;
    const chatUrl = links?.chatUrl || chatUrlDefault;
    const tgPostUrl = links?.tgPostUrl || tgPostUrlDefault;

    dispatch(setLinkLang(language));
    dispatch(setSupportChat(supportChat));
    dispatch(setChannelUrl(channelUrl));
    dispatch(setReviewUrl(reviewUrl));
    dispatch(setChatUrl(chatUrl));
    dispatch(setTgPostUrl(tgPostUrl));
  }, [language, langLink, supportChatDefault, channelUrlDefault, reviewUrlDefault, chatUrlDefault]);
}

import { useDispatch, useSelector } from "react-redux";
import { useEventListener } from "usehooks-ts";
import { setModalLogin } from "../../store/siteSlice";
import { API_USER } from "../../api/user";
import { setBalance, setCurrency, setInfo, setUserId } from "../../store/userSlice";
import { defaultUser, TEST_MODE } from "../../store/enums";
import { dispatchCusEvent } from "../../utils/utils";

export const ENUM_COMMON_EVENT = {
  sessionError: "session.error",
  exit: "account.exit",
  getBalance: "site.balance.get"
};

export default function useListener() {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.user.isAuth);

  const eventSessionError = ENUM_COMMON_EVENT.sessionError;
  const eventExit = ENUM_COMMON_EVENT.exit;
  const eventBalance = ENUM_COMMON_EVENT.getBalance;

  const onExit = async () => {
    await API_USER.logout();
    dispatch(setUserId(0));
    dispatch(setInfo(defaultUser));
  };

  const getBalance = (DEBUG = false) => {
    if (!isAuth) return;
    if (TEST_MODE) return;

    return API_USER.balance
      .info()
      .then((e) => {
        const data = e.data;

        if (data.errorCode == 1 && data.session === false) {
          console.log("error");
          dispatchCusEvent(ENUM_COMMON_EVENT.exit);
        }

        if (DEBUG) console.log("balance res", e);
        dispatch(setBalance(data));
        for (const currency in data) {
          const info = data[currency]?.info;
          if (info?.current) {
            dispatch(setCurrency(currency));
            break;
          }
        }
      })
      .catch((e) => {
        if (DEBUG) console.log("balance catch", e);
      });
  };

  useEventListener(eventSessionError, () => {
    dispatch(setModalLogin(true));
  });

  useEventListener(eventExit, () => {
    onExit();
  });

  useEventListener(eventBalance, () => {
    console.log("event balance", isAuth);

    getBalance();
  });
}

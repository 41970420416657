import React from "react";
import "./desk-title.scss";
import { useTranslation } from "react-i18next";

export default function DeskTitle({ iconTitle, title }) {
  const { t } = useTranslation();

  return (
    <h3 className="desk-title">
      {iconTitle} <span>{t(title)}</span>
    </h3>
  );
}

import React from "react";
import { useLocation, useParams } from "react-router-dom";
import GamesContainer from "../components/games/games_container/GamesContainer";
import BonusGames from "../components/games/BonusGames";
import HitGames from "../components/games/HitGames";
import ProvidersAllGames from "../components/games/ProvidersAllGames";
import Providers from "../components/games/Providers";
import HistoryGames from "../components/games/HistoryGames";
import Provider from "../components/games/Provider";
import useCheckEmptyPage from "../hooks/useCheckEmptyPage";
import FavoritesGames from "../components/games/FavoritesGames";

export default function Games() {
  const { id, subId } = useParams();
  const location = useLocation();
  const to = location?.state?.to;

  const pageById = {
    bonus: <BonusGames />,
    hit: <HitGames />,
    providers: <Providers />,
    provider: <Provider />,
    all: <ProvidersAllGames />,
    history: <HistoryGames />,
    favorites: <FavoritesGames />
  };

  const titleById = {
    bonus: "Bonus Games",
    hit: "Hit Games",
    providers: "Game providers",
    all: "All Games",
    history: "History",
    provider: subId,
    favorites: "Favorites"
  };

  useCheckEmptyPage({ isEmpry: pageById[id] });

  return (
    <GamesContainer backLink={to} title={titleById[id]}>
      {pageById[id]}
    </GamesContainer>
  );
}

import React, { useMemo } from "react";
import GameDesk from "../catalog_game/games-desk/GameDesk";
import "./favorites.scss";
import { useSelector } from "react-redux";
import ShowContainer from "../../mui/ShowContainer";
import { getSliceForCarousel } from "../../../utils/utils";

export default function Favorites() {
  const favoritesS = useSelector((state) => state.games.favoriteGames);
  const favorites = useMemo(() => getSliceForCarousel(favoritesS), [favoritesS]);
  const isAuth = useSelector((state) => state.user.isAuth);

  return (
    <ShowContainer condition={favorites.length && isAuth}>
      <section className="favorites">
        <div className="favorites__content">
          <GameDesk showAllLink={"/games/favorites"} title={"Favorites"} items={favorites} />
        </div>
      </section>
    </ShowContainer>
  );
}

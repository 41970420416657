import React from "react";
import ReactOwlCarousel from "react-owl-carousel";

export default function CarouselOrTag({ tag, isCarousel, children, ...props }) {
  if (isCarousel) {
    return <ReactOwlCarousel {...props}>{children}</ReactOwlCarousel>;
  }

  if (tag === "article") {
    return <article {...props}>{children}</article>;
  }

  return <div {...props}>{children}</div>;
}

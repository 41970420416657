import React from "react";
import "./warning-info.scss";
import IconWarning from "../JSXIcons/IconWarning";

export default function WarningInfo({ children, isRed }) {
  const classBg = isRed ? "red" : "";

  return (
    <div className={`warning-info ${classBg}`}>
      <IconWarning color={isRed ? "#f00" : undefined} />
      <div className="warning-info__text">{children}</div>
    </div>
  );
}

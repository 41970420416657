import React, { useState } from "react";
import IconCircleInfo from "../JSXIcons/IconCircleInfo";
import "./circle-info.scss";
import { useAppSelector } from "../../../store/index.ts";
import { useEventListener } from "usehooks-ts";

export default function CircleInfo({ info }) {
  const isTouch = useAppSelector((state) => state.deviceSize.isTouch);
  const [active, setActive] = useState(false);

  const touchClass = isTouch ? "touch" : "";
  const activeClass = active ? "active" : "";

  useEventListener("click", () => setActive(false));

  return (
    <div
      className={`circle-info ${activeClass} ${touchClass}`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setActive((prev) => !prev);
      }}>
      <div className="circle-info__popup">
        {info}
        <div className="circle-info__popup__rect" />
        <div className="circle-info__popup__plug" />
      </div>
      <IconCircleInfo />
    </div>
  );
}

import React from "react";
import "./jackpot-banner.scss";
import Modal from "react-modal";
import CusButton from "../../../mui/custom_button/CusButton";
import ShowContainer from "../../../mui/ShowContainer";

export default function JackpotBanner({ isOpen, jackpot, closeModal }) {
  const onGetBonus = () => {
    closeModal();
  };

  return (
    <Modal
      ariaHideApp={false}
      className={"jackpot-banner-modal"}
      isOpen={isOpen}
      shouldFocusAfterRender={false}>
      <div className="jackpot-banner__content">
        <div className="jackpot-banner__main">
          <CusButton
            disable
            className="grande-jackpot"
            text={`${jackpot?.type} JACKPOT` || "JACKPOT"}
            color="gradient-purpure"
          />
          <ShowContainer condition={jackpot?.amount}>
            <h1>
              {jackpot?.amount} {jackpot?.currency}
            </h1>
          </ShowContainer>
          <CusButton text={"Continue"} color="gradient-blue" onClick={onGetBonus} />
        </div>
      </div>
    </Modal>
  );
}

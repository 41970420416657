import React from "react";

export default function IconPhone() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.36433 4.43031L8.90518 5.39941C9.39326 6.27398 9.19733 7.42126 8.4286 8.18999C8.4286 8.19 8.4286 8.19 8.4286 8.19C8.42848 8.19011 7.49626 9.12255 9.18677 10.8131C10.8766 12.5029 11.809 11.5721 11.8098 11.5712C11.8099 11.5712 11.8099 11.5712 11.8099 11.5712C12.5786 10.8025 13.7259 10.6066 14.6004 11.0947L15.5695 11.6355C16.8901 12.3725 17.0461 14.2245 15.8853 15.3853C15.1878 16.0828 14.3333 16.6256 13.3888 16.6614C11.7987 16.7217 9.09824 16.3192 6.38943 13.6104C3.68061 10.9016 3.27819 8.20118 3.33847 6.61107C3.37428 5.6665 3.91701 4.81203 4.61451 4.11452C5.7753 2.95373 7.62732 3.10969 8.36433 4.43031Z"
        fill="#5280A3"
      />
    </svg>
  );
}

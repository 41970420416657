import React from "react";

export default function IconWarning({ color = "#FFD954" }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M18.1331 13.2667L12.7997 3.66675C12.0831 2.37508 11.0914 1.66675 9.99974 1.66675C8.90807 1.66675 7.9164 2.37508 7.19974 3.66675L1.8664 13.2667C1.1914 14.4917 1.1164 15.6667 1.65807 16.5917C2.19974 17.5167 3.2664 18.0251 4.6664 18.0251H15.3331C16.7331 18.0251 17.7997 17.5167 18.3414 16.5917C18.8831 15.6667 18.8081 14.4834 18.1331 13.2667Z"
        fill={color}
      />
      <path
        d="M10 12.2917C9.65833 12.2917 9.375 12.0083 9.375 11.6667V7.5C9.375 7.15833 9.65833 6.875 10 6.875C10.3417 6.875 10.625 7.15833 10.625 7.5V11.6667C10.625 12.0083 10.3417 12.2917 10 12.2917Z"
        fill={color}
      />
      <path
        d="M10.0003 15C9.95033 15 9.89199 14.9917 9.83366 14.9834C9.78366 14.975 9.73366 14.9584 9.68366 14.9334C9.63366 14.9167 9.58366 14.8917 9.53366 14.8584C9.49199 14.825 9.45033 14.7917 9.40866 14.7584C9.25866 14.6 9.16699 14.3834 9.16699 14.1667C9.16699 13.95 9.25866 13.7334 9.40866 13.575C9.45033 13.5417 9.49199 13.5084 9.53366 13.475C9.58366 13.4417 9.63366 13.4167 9.68366 13.4C9.73366 13.375 9.78366 13.3584 9.83366 13.35C9.94199 13.325 10.0587 13.325 10.1587 13.35C10.217 13.3584 10.267 13.375 10.317 13.4C10.367 13.4167 10.417 13.4417 10.467 13.475C10.5087 13.5084 10.5503 13.5417 10.592 13.575C10.742 13.7334 10.8337 13.95 10.8337 14.1667C10.8337 14.3834 10.742 14.6 10.592 14.7584C10.5503 14.7917 10.5087 14.825 10.467 14.8584C10.417 14.8917 10.367 14.9167 10.317 14.9334C10.267 14.9584 10.217 14.975 10.1587 14.9834C10.1087 14.9917 10.0503 15 10.0003 15Z"
        fill={color}
      />
    </svg>
  );
}

import React from "react";
import LoginModal from "../modal/login_modal/LoginModal";
import { useDispatch } from "react-redux";
import {
  setChooseMode,
  setDataLogin,
  setModalBalance,
  setModalLogin,
  setModalProfile,
  setOpenJackpot,
  setPromoCode,
  setShowBalance,
  setWithdrawal
} from "../../store/siteSlice";
import TopUpBalance from "../modal/top_up_balance/TopUpBalance";
import WithdrawalModal from "../modal/withdrawal/Withdrawal";
import PromoCodeModal from "../modal/promo_code/PromoCodeModal";
import ChooseModeGameModal from "../modal/choose_mode_game/ChooseModeGameModal";
import ShowBalance from "../modal/show_balance/ShowBalance";
import ProfileModal from "../modal/profile/ProfileModal";
import { useAppSelector } from "../../store/index.ts";
import JackpotBanner from "../landing/jackpot/jackpot_banner/JackpotBanner.jsx";

export default function CommonModals() {
  const dispatch = useDispatch();
  const openLogin = useAppSelector((state) => state.site.modals.login);
  const openBalance = useAppSelector((state) => state.site.modals.balance);
  const openWithdrawal = useAppSelector((state) => state.site.modals.withdrawal);
  const openShowBalance = useAppSelector((state) => state.site.modals.showBalance);
  const openPromoCode = useAppSelector((state) => state.site.modals.promoCode);
  const openChooseMode = useAppSelector((state) => state.site.modals.chooseMode);
  const openProfile = useAppSelector((state) => state.site.modals.profile);
  // const openWagering = useSelector((state) => state.site.modals.bonusWagering);

  const dataChooseMode = useAppSelector((state) => state.site.modals.data.chooseMode);
  const dataLogin = useAppSelector((state) => state.site.modals.data.login);
  const bannerJackpot = useAppSelector((state) => state.site.modals.banners.jackpot);

  const closeLogin = () => {
    dispatch(setModalLogin(false));
    dispatch(setDataLogin({ cb: () => {} }));
  };

  const closeBalance = () => dispatch(setModalBalance(false));
  const closeWithdrawal = () => dispatch(setWithdrawal(false));
  const closePromoCode = () => dispatch(setPromoCode(false));
  const closeShowBalance = () => dispatch(setShowBalance(false));
  const closeProfile = () => dispatch(setModalProfile(false));
  const closeJackpot = () => dispatch(setOpenJackpot(false));
  // const closeWagering = () => dispatch(setBonusWagering(false));
  const closeChooseMode = (e) => {
    dispatch(setChooseMode(false));
    dataChooseMode?.close(e);
  };

  return (
    <>
      <TopUpBalance isOpen={!!openBalance} closeModal={closeBalance} />
      <WithdrawalModal isOpen={openWithdrawal} closeModal={closeWithdrawal} />
      <PromoCodeModal isOpen={openPromoCode} closeModal={closePromoCode} />
      <ChooseModeGameModal
        isOpen={openChooseMode}
        closeModal={closeChooseMode}
        data={dataChooseMode}
      />
      <ShowBalance isOpen={openShowBalance} closeModal={closeShowBalance} />
      <ProfileModal isOpen={openProfile} closeModal={closeProfile} />
      {/* <BonusWager isOpen={openWagering} closeModal={closeWagering} /> */}

      <LoginModal isOpen={openLogin} closeModal={closeLogin} data={dataLogin} />

      <JackpotBanner jackpot={bannerJackpot} isOpen={!!bannerJackpot} closeModal={closeJackpot} />
    </>
  );
}

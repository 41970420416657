import React from "react";

export default function IconMenuCircle() {
  return (
    <svg
      width="24.000000"
      height="24.000000"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <path
        id="Vector"
        d="M4 7.79C4 9.88 5.69 11.58 7.79 11.58C9.88 11.58 11.58 9.88 11.58 7.79C11.58 5.69 9.88 4 7.79 4C5.69 4 4 5.69 4 7.79Z"
        fill="#FFFFFF"
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
      <path
        id="Vector"
        d="M4 16.81C4 18.91 5.69 20.61 7.79 20.61C9.88 20.61 11.58 18.91 11.58 16.81C11.58 14.72 9.88 13.02 7.79 13.02C5.69 13.02 4 14.72 4 16.81Z"
        fill="#FFFFFF"
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
      <path
        id="Vector"
        d="M12.66 7.79C12.66 9.88 14.36 11.58 16.45 11.58C18.55 11.58 20.25 9.88 20.25 7.79C20.25 5.69 18.55 4 16.45 4C14.36 4 12.66 5.69 12.66 7.79Z"
        fill="#FFFFFF"
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
      <path
        id="Vector"
        d="M12.66 16.81C12.66 18.91 14.36 20.61 16.45 20.61C18.55 20.61 20.25 18.91 20.25 16.81C20.25 14.72 18.55 13.02 16.45 13.02C14.36 13.02 12.66 14.72 12.66 16.81Z"
        fill="#FFFFFF"
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
    </svg>
  );
}

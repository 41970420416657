import { useEffect } from "react";
import { getParamFromUrl } from "../utils/utils";
import { useDispatch } from "react-redux";
import { setHardModeGame, setSessionId } from "../store/commonSlice";
import useFillCasino from "./common/useFillCasino";
import useNavigateWithSearch from "./useNavigateWitchSearch";
import useDeviceSizeFill from "./useDeviceSizeFill";
import { useLocalStorage } from "usehooks-ts";
import useMainPage from "./common/useMainPage";
import useFillLinks from "./common/useFillLinks";
import useListener from "./common/useListener";
import useFixCarouselDeskListener from "./common/useFixCarouselDeskListener";
import useJackpotInterval from "./common/useJackpotInterval";
import useResizeListener from "./common/useResizeListener";

// useCommonHooks - основные хуки для
/**
  @description  основные небольшие хуки 
 */

export default function useCommonHooks() {
  useFillCasino();
  useDeviceSizeFill();
  useFixCarouselDeskListener();
  useListener();
  useFillLinks();
  useJackpotInterval();
  useMainPage();
  useResizeListener();

  const [saveGame, setSaveGame] = useLocalStorage("saveGame");
  const navigate = useNavigateWithSearch();
  const dispatch = useDispatch();

  useEffect(() => {
    const sessionId = getParamFromUrl("sessionId");
    const mode = getParamFromUrl("mode");

    if (sessionId) dispatch(setSessionId(sessionId));
    if (mode) dispatch(setHardModeGame(mode));
  }, [window.location.search]);

  useEffect(() => {
    const path = saveGame?.path;
    if (!path) return;
    if (window.location.pathname == path) return;
    navigate(path);
    console.log("path", path);
    setSaveGame({});
  }, [navigate, saveGame]);

  // useEffect(() => {
  //   if (isTgBot) return;
  //   dispatch(setModeGame(isAuth ? ENUM_MODE_GAME.real : ENUM_MODE_GAME.demo));
  // }, [isAuth, isTgBot]);
}

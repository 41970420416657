import React from "react";
import { useTranslation } from "react-i18next";
import "./top-up-sum.scss";

export default function TopUpSum({ value, currency, setStep }) {
  const { t } = useTranslation();

  return (
    <div className="top-up-balance__select">
      <div className="top-up-select__content-container">
        <div className="top-up-select__content">
          <div className="top-up-select__gradient" />
          <span className="top-up-select__value">{value}</span>
          <div className="top-up-select__currency">{currency}</div>
          <button onClick={() => setStep(1)} className="top-up-select__change">
            {t("Change amount")}
          </button>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import "./withdrawal-modal.scss";
import DefaultModal from "../DefaultModal";
import { useTranslation } from "react-i18next";
import CusForm from "../../mui/CusForm";
import CusInput from "../../mui/custom_input/CusInput";
import { useDispatch, useSelector } from "react-redux";
import WithdrawSuccess from "./result/WithdrawSuccess";
import WithdrawError from "./result/WithdrawError";
import { DEBUG, TEST_MODE } from "../../../store/enums";
import { API_USER } from "../../../api/user";
import { errorToast } from "../../mui/Toaster";
import { setCurrency } from "../../../store/userSlice";
import CardFields from "./fields/CardFields";
import AddressFields from "./fields/AddressFields";
import PhoneFields from "./fields/PhoneFields";
import { dispatchCusEvent, messageByCode } from "../../../utils/utils";
import IconCardTopUp from "../../mui/JSXIcons/IconCardTopUp";
import { testPaymentInfo } from "../../../store/testConst";
import SuccessCancelButtons from "../../mui/success_cancel_buttons/SuccessCancelButtons";
import LoaderContainer from "../../mui/loader_container/LoaderContainer";
import { ENUM_COMMON_EVENT } from "../../../hooks/common/useListener";
import WithdrawInfoCard from "./WithdrawInfoCard";
import ErrorBoundary from "../../mui/error_boundary/ErrorBoundary";
import WithdrawCheck from "./WithdrawCheck";
import ShowContainer from "../../mui/ShowContainer";
import CusRadio from "../../mui/custom_radio/CusRadio";
import IconEdit from "../../mui/JSXIcons/IconEdit";

const ENUM_USER_DATA = {
  card: "card",
  phone: "phone",
  address: "address"
};

export default function WithdrawalModal({ isOpen, closeModal = () => {} }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const balance = useSelector((state) => state.user.balance);
  const currency = useSelector((state) => state.user.currency);
  const isAuth = useSelector((state) => state.user.isAuth);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [minSum, setMinSum] = useState(0);
  const [maxSum, setMaxSum] = useState(Infinity);
  const [commission1, setCommission1] = useState(0);
  const [commission2, setCommission2] = useState(0);
  const [fixedCommission, setFixedCommission] = useState(0);
  const [commissionStone, setCommissionStone] = useState(0);
  const [userData, setUserData] = useState(ENUM_USER_DATA.card);
  const [btnLoading, setBtnLoading] = useState(false);
  const [bankList, setBankList] = useState({});
  const [loader, setLoader] = useState(false);
  const [sumValue, setSumValue] = useState(undefined);
  const [fieldType, setFieldType] = useState(userData);
  const [selectBank, setSelectBank] = useState(undefined);

  const optionsFields = [
    { title: "Card", value: ENUM_USER_DATA.card },
    { title: "Phone (SBP)", value: ENUM_USER_DATA.phone }
  ];

  const sessionError = ENUM_COMMON_EVENT.sessionError;

  const checkOpen = (data) => {
    if (isOpen) {
      if (data.errorCode === 1) dispatchCusEvent(sessionError);
    }
  };

  useEffect(() => {
    if (!isAuth) return;
    if (!isOpen) return;
    if (TEST_MODE) {
      const data = testPaymentInfo;

      dispatch(setCurrency(data.currency));
      setMinSum(data.minAmount);
      setMaxSum(data.maxAmount);
      setCommissionStone(data.extraTaxAmount);
      setCommission1(data.extraTaxPercent);
      setCommission2(data.taxPercent);
      setFixedCommission(data.taxFixed);
      setUserData(data.userData);
      setFieldType(data.userData);
      setBankList(data?.bankList);
      return;
    }
    setLoader(true);
    API_USER.balance.payout
      .info()
      .then((e) => {
        if (DEBUG) console.log("payout res", e);
        const data = e.data;
        checkOpen(e.data);
        dispatch(setCurrency(data.currency));
        setMinSum(data.minAmount);
        setMaxSum(data.maxAmount);
        setCommissionStone(data.extraTaxAmount);
        setCommission1(data.extraTaxPercent);
        setCommission2(data.taxPercent);
        setFixedCommission(data.taxFixed);
        setFieldType(data.userData);
        setUserData(data.userData);
        setBankList(data?.bankList);
      })
      .catch(() => {
        errorToast("An error has occurred");
      })
      .finally(() => {
        setLoader(false);
      });
  }, [isOpen, isAuth, currency]);

  const onSubmit = async (e, field) => {
    e.preventDefault();
    const data = { ...field, currency, bankName: selectBank };

    const curBalance = balance?.[currency];
    const payout = curBalance?.balances?.payout;

    if (!payout || payout < data.amount) {
      return errorToast(t("The amount exceeds the user's balance"));
    }

    delete data.fieldType;
    console.log("data", data);

    if (TEST_MODE) return Math.random() < 0.5 ? setOpenSuccess(true) : setErrorSuccess(true);

    await API_USER.balance.payout
      .make(data)
      .then((e) => {
        if (DEBUG) console.log("payout make res", e);
        const data = e.data;

        if (data.error || data?.dataError) return setErrorSuccess(t(messageByCode(data.dataError)));

        closeModal();
        setOpenSuccess(data);
      })
      .catch((e) => {
        if (DEBUG) console.log("payout catch", e);
      });
  };

  const field = {
    [ENUM_USER_DATA.card]: <CardFields />,
    [ENUM_USER_DATA.address]: <AddressFields />,
    [ENUM_USER_DATA.phone]: <PhoneFields bankList={bankList?.phone} setSelectBank={setSelectBank} />
  };

  return (
    <>
      <DefaultModal miniModal isOpen={isOpen} closeModal={closeModal} classModal="withdraw">
        <h2>{t("Withdraw currency from balance", { currency })}</h2>
        <p>{t("Withdraw funds quickly and safely")}</p>

        <LoaderContainer center loader={loader}>
          <ErrorBoundary>
            <WithdrawCheck />
          </ErrorBoundary>

          <div className="withdraw__content">
            <CusForm
              onSubmit={async (e, f) => {
                setBtnLoading(true);
                await onSubmit(e, f);
                setBtnLoading(false);
              }}>
              <CusInput
                name={"amount"}
                placeholder={"Withdrawal amount"}
                defaultValue={sumValue}
                iconJSXstart={<IconCardTopUp />}
                onChange={(e) => setSumValue(e.target.value)}
                required
                dataType={"number"}
                min={minSum}
                max={maxSum}
                type={"number"}
              />

              <ShowContainer condition={userData === ENUM_USER_DATA.card}>
                <CusInput
                  iconJSXstart={<IconEdit />}
                  name={"firstName"}
                  placeholder={"Receiver name"}
                  required
                />

                <CusInput
                  iconJSXstart={<IconEdit />}
                  name={"lastName"}
                  placeholder={"Receiver last name"}
                  required
                />

                <CusRadio
                  name="fieldType"
                  options={optionsFields}
                  defaultChecked={(indx) => indx === 0}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFieldType(value);
                  }}
                />
              </ShowContainer>

              {field[fieldType]}

              <div className="withdraw__info">
                <ErrorBoundary>
                  <WithdrawInfoCard
                    maxSum={maxSum}
                    minSum={minSum}
                    commission1={commission1}
                    commission2={commission2}
                    commissionStone={commissionStone}
                    fixedCommission={fixedCommission}
                  />
                </ErrorBoundary>

                <SuccessCancelButtons
                  btnLoading={btnLoading}
                  setBtnLoading={setBtnLoading}
                  successText="Withdraw"
                  closeModal={closeModal}
                />
              </div>
            </CusForm>
          </div>
        </LoaderContainer>
      </DefaultModal>

      <WithdrawSuccess
        isOpen={!!openSuccess}
        info={openSuccess}
        closeModal={() => setOpenSuccess(false)}
      />
      <WithdrawError
        isOpen={!!errorSuccess}
        message={errorSuccess}
        closeModal={() => setErrorSuccess(false)}
      />
    </>
  );
}

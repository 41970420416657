import React, { lazy, Suspense, useEffect, useRef } from "react";
import "./page-witch-menu.scss";
import { useTranslation } from "react-i18next";
import ShowContainer from "../ShowContainer";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../spinner/Spinner";
import ErrorBoundary from "../error_boundary/ErrorBoundary";
import { setPageWitchMenuContent } from "../../../store/siteSlice";

const AsideMenu = lazy(() => import("../../landing/menu/AsideMenu"));

export default function PageWitchMenu({ className, children }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMenuAbsolute = useSelector((state) => state.deviceSize.isMenuAbsolute);
  const pageContentRef = useRef(null);

  useEffect(() => {
    dispatch(setPageWitchMenuContent(pageContentRef.current));
  }, []);

  return (
    <main className={`page-witch-menu ${className} ${t("currentLang")}`}>
      <ShowContainer condition={!isMenuAbsolute}>
        <ErrorBoundary>
          <Suspense
            fallback={
              <aside className="loader-aside-center">
                <Spinner />
              </aside>
            }>
            <AsideMenu className="page-witch-menu__aside" />
          </Suspense>
        </ErrorBoundary>
      </ShowContainer>
      <ErrorBoundary>
        <div className="page-witch-menu__content" ref={pageContentRef}>
          {children}
        </div>
      </ErrorBoundary>
    </main>
  );
}

import React, { useState } from "react";
import CusInput from "../../../mui/custom_input/CusInput";
import IconPhone from "../../../mui/JSXIcons/IconPhone";
import CusSelect from "../../../mui/find_select/CusSelect";
import ShowContainer from "../../../mui/ShowContainer";
import IconAddress from "../../../mui/JSXIcons/IconAddress";

export default function PhoneFields({ bankList, setSelectBank }) {
  const [phone, setPhone] = useState();

  return (
    <>
      <CusInput
        iconJSXstart={<IconPhone />}
        defaultValue={phone}
        onChange={(e) => setPhone(e.target.value)}
        type={"tel"}
        placeholder="+71234567890"
        name={"phoneNumber"}
        required
      />

      <ShowContainer condition={bankList}>
        <CusSelect
          name="bankName"
          required
          className="bank-select"
          options={bankList?.map((el) => ({ label: el, value: el }))}
          placeholder="Beneficiary bank"
          onChange={setSelectBank}
          iconJSXstart={<IconAddress />}
        />
      </ShowContainer>
    </>
  );
}

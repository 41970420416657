import React from "react";

export default function IconCircleInfo() {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6654 8.49998C14.6654 12.1819 11.6806 15.1666 7.9987 15.1666C4.3168 15.1666 1.33203 12.1819 1.33203 8.49998C1.33203 4.81808 4.3168 1.83331 7.9987 1.83331C11.6806 1.83331 14.6654 4.81808 14.6654 8.49998ZM7.9987 12.3333C8.27484 12.3333 8.4987 12.1095 8.4987 11.8333V7.83331C8.4987 7.55717 8.27484 7.33331 7.9987 7.33331C7.72256 7.33331 7.4987 7.55717 7.4987 7.83331V11.8333C7.4987 12.1095 7.72256 12.3333 7.9987 12.3333ZM7.9987 5.16665C8.36689 5.16665 8.66536 5.46512 8.66536 5.83331C8.66536 6.2015 8.36689 6.49998 7.9987 6.49998C7.63051 6.49998 7.33203 6.2015 7.33203 5.83331C7.33203 5.46512 7.63051 5.16665 7.9987 5.16665Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
}

import React, { useMemo, useState } from "react";
import "./bottom-navigation.scss";
import IconPlay from "../mui/JSXIcons/IconPlay";
import IconWallet from "../mui/JSXIcons/IconWallet";
import IconCirclePlus from "../mui/JSXIcons/IconCirclePlus";
import IconCircleMinus from "../mui/JSXIcons/IconCircleMinus";
import { setModalBalance, setShowBalance, setWithdrawal } from "../../store/siteSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/index.ts";
import ShowContainer from "../mui/ShowContainer";
import useNavigateWithSearch from "../../hooks/useNavigateWitchSearch.js";
import { useLocation } from "react-router-dom";
import IconHome from "../mui/JSXIcons/IconHome.jsx";
import { useEventListener } from "usehooks-ts";
import useLoginAction from "../../hooks/useLoginAction.js";

export default function BottomNavigation() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigateWithSearch();
  const { onLoginAction } = useLoginAction();

  const pathname = useMemo(() => location.pathname, [location]);
  const isExtraSmall = useAppSelector((state) => state.deviceSize.isExtraSmall);
  const openDeposit = useAppSelector((state) => state.site.modals.balance);
  const openWithdrawal = useAppSelector((state) => state.site.modals.withdrawal);
  const openShowBalance = useAppSelector((state) => state.site.modals.showBalance);

  const [show, setShow] = useState(undefined);

  useEventListener("scroll", () => {
    const scrollY = window.scrollY;
    const newShow = scrollY > 80;

    if (show === undefined && !newShow) return;
    setShow(newShow);
  });

  const items = [
    {
      id: "/",
      icon: <IconHome />,
      text: "Home",
      className: "",
      onclick: () => navigate("/")
    },
    // {
    //   id: "/promotions",
    //   icon: <IconBonus />,
    //   text: "Bonuses",className:"",
    //   onclick: () => navigate("/promotions")
    // },
    {
      id: "/games/all",
      icon: <IconPlay />,
      text: "Games",
      className: "",
      onclick: () => navigate("/games/all")
    },
    {
      id: "balance",
      icon: <IconWallet />,
      text: "Balance",
      className: "balance",
      onclick: () => onLoginAction(() => dispatch(setShowBalance(!openShowBalance)))
    },
    {
      id: "deposit",
      icon: <IconCirclePlus />,
      text: "Deposit",
      className: "",
      onclick: () => onLoginAction(() => dispatch(setModalBalance(!openDeposit)))
    },
    {
      id: "withdrawal",
      icon: <IconCircleMinus />,
      text: "Withdrawal",
      className: "",
      onclick: () => onLoginAction(() => dispatch(setWithdrawal(!openWithdrawal)))
    }
  ];

  const getActiveClass = (item) => {
    const active = "active";
    if (item.id === "deposit") return openDeposit ? active : "";
    if (item.id === "withdrawal") return openWithdrawal ? active : "";
    if (item.id === "balance") return openShowBalance ? active : "";
    if (item.id === pathname && !openDeposit && !openWithdrawal && !openShowBalance) return active;

    return "";
  };

  const showClass = show === undefined ? "" : show ? "show" : "hide";

  return (
    <ShowContainer condition={isExtraSmall}>
      <div className={`bottom-navigation ${showClass}`}>
        {items.map((item) => {
          const activeClass = getActiveClass(item);

          return (
            <button
              key={item.id}
              className={`bottom-navigation__button ${activeClass} ${item.className}`}
              onClick={item.onclick}>
              <div className="icon">{item.icon}</div>
              <span className="text">{t(item.text)}</span>
              <span className="circle" />
            </button>
          );
        })}
      </div>
    </ShowContainer>
  );
}

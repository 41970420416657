import React from "react";
import { useTranslation } from "react-i18next";
import ShowContainer from "../../mui/ShowContainer.jsx";
import { useAppSelector } from "../../../store/index.ts";

export default function WithdrawInfoCard({
  minSum,
  maxSum,
  commissionStone,
  commission1,
  commission2,
  fixedCommission
}) {
  const { t } = useTranslation();
  const currency = useAppSelector((state) => state.user.currency);

  return (
    <div className="withdraw__info__card">
      <ShowContainer condition={minSum && maxSum}>
        <div className="withdraw__info__card__item min">
          <span className="label">{t("Withdrawal amount")}</span>
          <span className="amount">
            <span className="value">{minSum}</span>-<span className="value">{maxSum}</span>
            <span className="currency">{currency}</span>
          </span>
        </div>
      </ShowContainer>
      <ShowContainer condition={commissionStone && commission1}>
        <div className="withdraw__info__card__item commission-max">
          <span className="label">{t("Commission for withdrawal up to")}</span>
          <span className="amount">
            <span className="value">{commissionStone}</span>
            <span className="currency">{currency}</span>
            <span className="percent">{commission1} %</span>
          </span>
        </div>
      </ShowContainer>
      <ShowContainer condition={commissionStone && commission2}>
        <div className="withdraw__info__card__item commission-min">
          <span className="label">{t("Commission for withdrawal from")}</span>
          <span className="amount">
            <span className="value">{commissionStone}</span>
            <span className="currency">{currency}</span>
            <span className="percent">{commission2} %</span>
          </span>
        </div>
      </ShowContainer>
      <ShowContainer condition={fixedCommission}>
        <div className="withdraw__info__card__item commission-fixed">
          <span className="label">{t("Fixed commission amount")}</span>
          <span className="amount">
            <span className="value">{fixedCommission}</span>
            <span className="currency">{currency}</span>
          </span>
        </div>
      </ShowContainer>
    </div>
  );
}

import { TEST_MODE } from "../../store/enums";
import { useInterval } from "usehooks-ts";
import { testBalance } from "../../store/testConst";
import { useDispatch } from "react-redux";
import { setBalance, setCurrency } from "../../store/userSlice";
import { useEffect } from "react";
import { dispatchCusEvent } from "../../utils/utils";
import { ENUM_COMMON_EVENT } from "./useListener";

export default function useBalanceUpdate(isRunningGameRef) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (TEST_MODE) {
      dispatch(setBalance(testBalance));
      for (const currency in testBalance) {
        const info = testBalance[currency]?.info;
        if (info?.current) {
          dispatch(setCurrency(currency));
          break;
        }
      }
      return;
    }

    dispatchCusEvent(ENUM_COMMON_EVENT.getBalance);
  }, []);

  useInterval(() => {
    if (TEST_MODE) return;
    if (isRunningGameRef?.current) return;

    dispatchCusEvent(ENUM_COMMON_EVENT.getBalance);
  }, 30 * 1000);
}

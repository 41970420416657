import React from "react";
import TermsEn from "../components/terms/TermsEn";
import TermsRu from "../components/terms/TermsRu";
import "./terms.scss";
import Header from "../components/landing/header/Header";
import Footer from "../components/landing/footer/Footer";
import { useSelector } from "react-redux";
import useScrollUp from "../hooks/useScrollUp";
import ButtonUp from "../components/mui/button_up/ButtonUp";
import PageWitchMenu from "../components/mui/page_witch_menu/PageWitchMenu";

export default function Terms() {
  useScrollUp();
  const lang = useSelector((state) => state.common.lang);

  return (
    <PageWitchMenu className={"terms"}>
      <Header />
      <div className="terms__content">{lang === "ru" ? <TermsRu /> : <TermsEn />}</div> <ButtonUp />
      <Footer />
    </PageWitchMenu>
  );
}
